import React from "react";
import moment from 'moment';
import {Row, Col, Modal} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrello } from "@fortawesome/free-brands-svg-icons";

import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { OrderType } from "../../types";
import styles from "./index.scss";
import InPlaceForm from "../InPlaceForm";
import { connect } from "react-redux";
import InlineDatePicker from "../InlineDatePicker";
import useCommercials from "../../utils/providers/useCommercials";

const ModalPreview = ({preview, visible, closeModal}) => (
  <Modal
    title="Prévisualisation du PDF"
    visible={visible}
    onOk={closeModal}
    onCancel={closeModal}
    width={800}
  >
    <div className={styles.alignPreview}>
      <img src={preview.url} alt="preview_pdf" style={{width: '750px', maxHeight: '1000px'}} />
    </div>
  </Modal>
);

type DetailsProps = {
  order: OrderType;
}

const Details: React.FC<DetailsProps> = props => {
  const { order, onSubmit, loading, readOnly } = props;
  const [t] = useTranslation();
  const {
    trelloUrl,
    clientCommercialName,
    commercialContact,
    commercialContactEmail,
    commercialContactPhone,
    description,
    client,
    document,
    startingOn,
    endingOn,
    userName
  } = order;

  const { commercials } = useCommercials();

  return (
    <div>
      <Row gutter={[16, 16]} className={styles.dataRow}>
        <Col xs={24} md={4}>
          <span className={styles.inputLabel}>
            {t("words.client")}
          </span>
          {client && (
            <span>{client.title}</span>
          )}
        </Col>
        <Col xs={24} md={16}>
          <InPlaceForm
            readOnly={readOnly}
            onChange={onSubmit}
            loading={loading}
            object={{ commercialContact, commercialContactEmail, clientCommercialName, commercialContactPhone, userName }}
            schema={[[
              {
                label: t("order.attributes.clientCommercialName"),
                name: 'clientCommercialName',
                input: {
                  type: 'string'
                },
                colProps: {
                  xs: 24,
                  md: 10,
                  lg: 6,
                }
              },
              {
                label: t("order.attributes.commercialContact"),
                name: 'commercialContact',
                input: {
                  type: 'text'
                },
                colProps: {
                  xs: 24,
                  md: 5
                }
              },
              {
                label: t("order.attributes.commercialContactEmail"),
                name: 'commercialContactEmail',
                input: {
                  type: 'string'
                },
                colProps: {
                  xs: 24,
                  md: 5
                }
              },
              {
                label: t("order.attributes.commercialContactPhone"),
                name: 'commercialContactPhone',
                input: {
                  type: 'string'
                },
                colProps: {
                  xs: 24,
                  md: 5
                }
              },
              {
                label: t("order.user.label"),
                name: 'userName',
                input: {
                  type: 'select',
                  options: commercials.map(c => ({
                    value: c,
                    label: c
                  }))
                },
                colProps: {
                  xs: 24,
                  md: 3
                }
              }
            ]]}
          />
        </Col>
        <Col xs={24} md={4}>
            <span className={styles.inputLabel}>
              {t("words.notifications")}
            </span>
            <a>
              Voir le détail
            </a>
        </Col>
        <Col xs={24} className={styles.dataRow}>
          <InPlaceForm
            readOnly={readOnly}
            onChange={onSubmit}
            loading={loading}
            object={{ trelloUrl }}
            schema={[[
              <Col xs={24} md={8}>
                <span className={`${styles.inputLabel} ${(!startingOn || !endingOn) ? styles.hasError : '' }`}>
                  {t("words.dates")}*
                </span>
                <div className={(!startingOn || !endingOn) ? styles.hasError : ''}>
                  <InlineDatePicker readOnly={readOnly} value={startingOn} onChange={onSubmit} name="startingOn" disabledDate={(date: moment.Moment) => {
                    return date.isAfter(moment(endingOn)) || date.format('L') !== date.clone().startOf('isoWeek').format("L")
                  }} />
                  &nbsp;-&nbsp;
                  <InlineDatePicker readOnly={readOnly} value={endingOn} onChange={onSubmit} name="endingOn" disabledDate={date => {
                    return date.isBefore(moment(startingOn)) || date.format('L') !== date.clone().endOf('isoWeek').format("L")
                  }} />
                </div>
              </Col>,
              {
                label: `${t("order.trelloUrl.label")}`,
                name: 'trelloUrl',
                input: {
                  type: 'string'
                },
                render: () => {
                  if (trelloUrl) {
                    return (
                      <a href={trelloUrl} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTrello} />
                        &nbsp;
                        {t("orderShow.showTrelloCard")}
                      </a>
                    )
                  }
                  return null;
                },
                colProps: {
                  xs: 24,
                  md: 6
                }
              },
              {
                label: t("orderShow.pdfFile"),
                name: 'file',
                input: {
                  type: 'file'
                },
                render: () => {
                  if(document)
                    return (
                      <span>
                        <span className={styles.pdfWrapper}>
                          <a href={document.url} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFilePdf} />
                            &nbsp;
                            {document.filename}
                          </a>
                        </span>
                      </span>  
                    );

                  return (
                    <span>Non défini</span>
                  );
                },
                colProps: {
                  xs: 24,
                  md: 6
                }
              }
            ]]}
          />
        </Col>
        <Col xs={16}>
          <InPlaceForm
            readOnly={readOnly}
            onChange={onSubmit}
            loading={loading}
            object={{ description }}
            schema={[[
              {
                label: t("orderShow.otherInformations"),
                name: 'description',
                input: {
                  type: 'text'
                },
                colProps: {
                  xs: 24,
                  md: 24
                }
              }
            ]]}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.orderShow.submitting
})

export default connect(mapStateToProps)(React.memo(Details));
