import React from "react";
import { useTranslation } from "react-i18next";
import {
	Form,
	Col,
	Row,
	Input,
	DatePicker,
	Select,
	Button
} from "antd/lib/index";
import styles from "./index.scss";
import { AntForm, AntSchema, useAntForm } from "@9troisquarts/ant-form.ant-form";
import * as moment from "moment";


const PaymentForm = props => {
	const {
		onSubmit,
		onCancel,
		payment: { receiptCanBeModified },
		payment
	} = props;
	
	const { 
		set,
		onChange,
		object
	} = useAntForm({
		...payment,
		receipt: payment.receipt || `${moment().format('YYYY')}-`
	});
	const [t] = useTranslation();

	const states = [
		...(receiptCanBeModified ? ["pending_receipt"] : []),
		...["pending_payment", "paid"]
	];

	const handleSubmit = () => onSubmit(object);

	const schema: AntSchema = [
		[{
			name: 'receipt',
			label: t("payment.attributes.receipt"),
			rules: [{
				pattern: /^[0-9]{4}-[a-zA-Z0-9]{4}$/,
				message: t("errors.codePattern")
			}],
			input: {
				type: 'string',
				inputProps: {
					disabled: !receiptCanBeModified,
				}
			},
			colProps: {
				md: 10
			}
		}],
		[
			{
				name: 'state',
				label: t("payment.attributes.state"),
				input: {
					type: 'select',
					options: states.map(s => ({
						value: s,
						label: t(`payment.states.${s}`)
					}))
				},
				colProps: {
					xs: 24,
					md: 12
				}
			}
		],
		object.state === "paid" ? ([
			{
				name: 'paidOn',
				required: true,
				label: t("payment.attributes.paidOn"),
				input: {
					type: 'date',
					inputProps: {
						format: 'L'
					}
				},
				colProps: {
					xs: 24,
					md: 12
				}
			}
		]) : null,
		{
			name: 'notes',
			label: t("payment.attributes.notes"),
			input: {
				type: 'text',
				inputProps: {
					autoSize: true
				}
			}
		}
	];

	return (
		<AntForm
			object={object}
			onChange={onChange}
			onSubmit={handleSubmit}
			layout="vertical"
			schema={schema}
		/>
	);
};

export default PaymentForm
