import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DownloadOutlined } from '@ant-design/icons';
import { Card, Divider, DatePicker, Col, Radio, Button, Select } from "antd";
import moment from "moment/moment";

import { createSelector } from "reselect";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import styles from "./index.sass";
import css from "./reporting.sass";
import { orm as Product } from "../models/products";
import CategorySelector from "../components/CategorySelector";
import withProducts from "../utils/providers/withProducts";
import {
  exportReporting,
  fetchReporting,
  updateDatesRangeAndFetch,
  updateGroupByAndFetch,
  updateCommercialAndFetch,
  updatePriceTypeAndFetch,
  updateCategoriesAndFetch,
  updateTableAndFetch
} from "../models/actions/ui/reporting";
import ReportingTable from "../components/ReportingTable";
import useCommercials from "../utils/providers/useCommercials";

const { RangePicker } = DatePicker;

const Reporting = props => {
  const {
    t,
    onCategoryChange,
    onTableChange,
    onDateChange,
    onCommercialChange,
    onGroupChange,
    onPriceTypeChange,
    onExport,
    categories,
    view,
    pagination,
    ui,
    data,
    productFilters
  } = props;
  const { startingOn, endingOn, loading, filters, groupBy, priceType, commercial } = ui;
  const { commercials } = useCommercials();
  useEffect(() => props.loadData(), []);
  return (
    <Card>
      <div className={`${styles.header} ${styles.headerWithSubtitle}`}>
        <h1>{t(`reporting.title.${view}`)}</h1>
        <Link to={`/reporting/${view === "month" ? "week" : "month"}`}>
          {t(`reporting.linkTo${view === "month" ? "Week" : "Month"}`)}
        </Link>
      </div>
      <Divider style={{ marginTop: 10 }} />
      <div className={styles.filtersContainer}>
        <Col xs={24} md={15}>
          <div className={css.groupByFilter}>
            <span className={css.groupByFilterLabel}>{t("groupBy")}</span>
            <Radio.Group value={groupBy} onChange={onGroupChange}>
              <Radio value="client">{t("byClient")}</Radio>
              <Radio value="product">{t("byProduct")}</Radio>
            </Radio.Group>
          </div>
          <div className={css.groupByFilter}>
            <span className={css.groupByFilterLabel}>{t("priceType")}</span>
            <Radio.Group value={priceType} onChange={onPriceTypeChange}>
              <Radio.Button value="price">{t("words.turnoverAbbr")}</Radio.Button>
              <Radio.Button value="cost">
                {t("words.cost")}
              </Radio.Button>
              <Radio.Button value="margin">
                {t("words.margin")}
              </Radio.Button>
            </Radio.Group>
          </div>
          <div className={css.groupByFilter}>
            <span className={css.groupByFilterLabel}>Commercial</span>
            <Select placeholder="Sélectionner un commercial" allowClear value={commercial} onChange={onCommercialChange}>
              {(commercials || []).map(commercial => (
                <Select.Option key={commercial} value={commercial}>
                  {commercial}
                </Select.Option>
              ))}
            </Select>
          </div>
          <CategorySelector
            title={t("categorySelector.label")}
            options={categories}
            values={ui.categories}
            onChange={onCategoryChange}
          />
        </Col>
        <Col xs={24} md={9} align="right">
          <div className={styles.rangePicker}>
            <span>{t("filterByDates")}</span>
            <RangePicker
              onChange={onDateChange}
              defaultValue={[moment(startingOn), moment(endingOn)]}
              format="DD/MM/YYYY"
            />
          </div>
        </Col>
      </div>
      <ReportingTable
        onChange={onTableChange}
        pagination={pagination}
        data={data}
        view={view}
        groupBy={groupBy}
        loading={loading}
        productFilters={productFilters}
        filters={filters}
        startingOn={startingOn}
        endingOn={endingOn}
      />
      {view === "week" && (
        <Button type="default" icon={<DownloadOutlined />} onClick={onExport}>
          {t("exportBroadcasts")}
        </Button>
      )}
    </Card>
  );
};

Reporting.defaultProps = {
  data: [],
  categories: [],
  ui: {},
  productFilters: [],
  onExport: () => undefined
};

Reporting.propTypes = {
  t: PropTypes.func.isRequired,
  view: PropTypes.oneOf(["week", "month"]).isRequired,
  onDateChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  onPriceTypeChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onCommercialChange: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  productFilters: PropTypes.arrayOf(PropTypes.shape({})),
  ui: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    startingOn: PropTypes.string.isRequired,
    priceType: PropTypes.string,
    endingOn: PropTypes.string.isRequired,
    filters: PropTypes.shape({})
  }),
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired
};

const composedReporting = withTranslation()(
  compose(
    withProducts,
    lifecycle({
      componentWillReceiveProps(nextProps) {
        const { view } = nextProps;
        if (view !== this.props.view) this.props.loadData();
      }
    })
  )(Reporting)
);

const propsSelector = createSelector(
  (state, { view }) => view,
  state => state.ui.reporting,
  state => Product.all(state),
  state => Product.categories(state),
  state => state.reporting.get("list"),
  state => state.reporting.get("total"),
  (view, ux, products, categories, data, footer) => {
    const ui = ux.toJS();
    const {
      currentPage,
      total,
      pageSize,
      startingOn,
      endingOn,
      ...otherUi
    } = ui;
    const categoryChoosed = otherUi.categories;
    return {
      view,
      ui: {
        ...otherUi,
        startingOn: moment(startingOn)
          .startOf(view)
          .format("YYYY-MM-DD"),
        endingOn: moment(endingOn)
          .endOf(view)
          .format("YYYY-MM-DD")
      },
      productFilters: products
        .filter(
          product =>
            categoryChoosed.length === 0 || categoryChoosed.includes(product.category)
        )
        .map(s => ({ value: s.id, text: s.title })),
      categories,
      data: [
        ...data.toJS(),
        {
          id: "footer",
          ...footer.toJS()
        }
      ],
      pagination: {
        current: currentPage,
        total,
        pageSize
      }
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const { view } = ownProps.match.params;

  return propsSelector(state, { view });
};

const mapDispatchToProps = dispatch => ({
  loadData: () => dispatch(fetchReporting()),
  onExport: () => dispatch(exportReporting()),
  onCategoryChange: categories => dispatch(updateCategoriesAndFetch(categories)),
  onCommercialChange: commercial => dispatch(updateCommercialAndFetch(commercial)),
  onTableChange: (pagination, filters) =>
    dispatch(updateTableAndFetch(pagination, filters)),
  onDateChange: momentDates => dispatch(updateDatesRangeAndFetch(momentDates)),
  onPriceTypeChange: ({ target: { value } }) =>
    dispatch(updatePriceTypeAndFetch(value)),
  onGroupChange: ({ target: { value } }) =>
    dispatch(updateGroupByAndFetch(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(composedReporting);
