import React, { useEffect, useState } from "react";
import { Table, Tooltip, Avatar } from "antd";
import moment from "moment";
import round from "lodash/round";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLazyQuery } from '@apollo/client'
import {
  faComment,
  faExclamationTriangle,
  faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import { useLocalStorageState } from '@umijs/hooks';

import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf";

import FilterDropdown from "../../../../components/FilterDropdown";
import orderStates from "../../../../common/orderStates";
import StateButton from "../../../../components/Order/StateButton";
import PaymentsState from "./PaymentsState";
import { searchOrdersQuery } from "../../../../services/orders";
import { push } from "connected-react-router";
import styles from "./index.sass";
import useCommercials from "../../../../utils/providers/useCommercials";

const onPdfDownload = (e, fileUrl) => {
  e.stopPropagation();
  window.open(fileUrl);
};

type TableStateType = {
  sort: {
    key: string,
    order: string
  },
  filters: any
};


const mapColumns = ({ tableState, t, commercials = [] }: { tableState: TableStateType }) => {
  const { sort, filters } = tableState;
  const stateFilters = orderStates.map(state => ({
    value: state,
    text: t(`order.states.${state}.title`)
  }));
  
  const productFilters = ['waze', 'studio', 'other', 'option', 'ads'].map(productType => ({
    value: productType,
    text: t(`productTypes.${productType}`)
  }))

  return [
    {
      sorter: true,
      sortOrder: sort.key === "userName" && sort.order,
      filteredValue: filters["userName"] || null,
      key: "userName",
      filters: commercials.map(commercial => ({
        value: commercial,
        text: commercial
      })),
      title: 'Commercial',
      filterMultiple: true,
      align: 'left',
      dataIndex: 'userName'
    },
    {
      title: "Numéro BdC",
      key: "code",
      sorter: true,
      sortOrder: sort.key === "code" && sort.order,
      filteredValue: filters.code || null,
      filterDropdown: FilterDropdown,
      render: (text, { code, file, errors, description, paymentsSumIsCoherent }) => (
        <span>
          {code}
          {file && (
            <a onClick={(e) => onPdfDownload(e, file.url)} target="_blank" rel="noopener noreferrer" className={styles.linkHover}>
              <FontAwesomeIcon
                icon={faFilePdf}
                className={styles.pdfIcon}
              />
            </a>
          )}
          {errors && (
            <Tooltip title={errors.join("\n")}>
              <FontAwesomeIcon
                className={styles.errorsIcon}
                icon={faExclamationTriangle}
              />
            </Tooltip>
          )}
          {!paymentsSumIsCoherent && (
            <Tooltip title={t('order.paymentsAmountAreNotCoherent')}>
              <FontAwesomeIcon
                className={styles.errorsIcon}
                icon={faFileInvoiceDollar}
              />
            </Tooltip>
          )}
          {description && (
            <Tooltip title={description}>
              <FontAwesomeIcon
                className={styles.commentIcon}
                icon={faComment}
              />
            </Tooltip>
          )}
        </span>
      )
    },
    {
      title: t("order.state"),
      key: "state",
      align: "center",
      render: (text, { state }) => (
        <StateButton
          className={styles.stateButton}
          tooltip={false}
          state={state}
        />
      ),
      sortOrder: sort.key === "state" && sort.order,
      sorter: true,
      filteredValue: filters.state || null,
      filters: stateFilters,
      filterMultiple: true
    },
    {
      title: "Réglement",
      key: "paymentsState",
      render: (text, { paymentsState }) => (
        <PaymentsState state={paymentsState} />
      ),
      //filteredValue: filters.paymentsState || null,
      //filters: paymentStateFilters,
      filterMultiple: true
    },
    {
      title: "Client",
      key: "clients.title",
      sorter: true,
      sortOrder: sort.key === "clients.title" && sort.order,
      render: (text, record) => record.client.title,
      filteredValue: filters["clients.title"] || null,
      filterDropdown: FilterDropdown
    },
    {
      title: "Type de produits",
      dataIndex: "productTypes",
      key: "productTypes",
      sorter: false,
      render: (productTypes) => productTypes?.map(productType => t(`productTypes.${productType}`))?.join(', '),
      filteredValue: filters.productTypes || null,
      filters: productFilters,
      filterMultiple: true,
    },
    {
      title: "CA",
      dataIndex: "totalBudget",
      key: "//totalBudget",
      align: "right",
      sorter: true,
      sortOrder: sort.key === "//totalBudget" && sort.order,
      render: text =>
        `${round(parseFloat(text), 2)
          .toFixed(2)
          .toLocaleString()} €`
    },
    {
      title: "Date de saisie",
      key: "createdAt",
      sorter: true,
      sortOrder: sort.key === "createdAt" && sort.order,
      render: (text, record) => moment(record.createdAt).format("L")
    }
  ];
};

const filtersToAttributes = filters => {
  const attributes = {};
  const { code, state } = filters;
  if (state && state.length > 0) attributes.states = state;
  if (filters.productTypes)
    attributes.productTypes = filters.productTypes;
  if (filters.paymentsState)
    attributes.paymentsState = filters.paymentsState;
  if (filters["userName"])
    attributes.userName = filters["userName"];
  if (code && code.length > 0) {
    const [codeValue] = code;
    attributes.code = codeValue;
  }
  if (filters["clients.title"] && filters["clients.title"].length > 0) {
    const [clientTitleValue] = filters["clients.title"];
    attributes.clientTitle = clientTitleValue;
  }
  return attributes;
};

export const OrdersList = props => {
  const { dispatch } = props;
  const [savedTableState, setStoredTableState] = useLocalStorageState('orders-list-state', {
    sort: {
      key: "createdAt",
      order: "descend"
    },
    filters: {}
  });
  const [tableState, setTableState] = useState<TableStateType>(savedTableState);
  const { commercials } = useCommercials();
  const [t] = useTranslation();
  const [getOrders, { loading, data = {} }] = useLazyQuery(searchOrdersQuery, { fetchPolicy: 'network-only', variables: {} });
  const {
    searchOrders: {
      orders = [],
      currentPage = 1,
      perPage = 50,
      total = 0,
    } = {}
  } = data

  const columns = mapColumns({ tableState, t, commercials });

  useEffect(() => getOrders({
      variables: {
        sort: tableState?.sort ? {
          key: tableState.sort.key,
          order: tableState.sort.order === "descend" ? 'descend' : 'asc'
        } : {},
        search: filtersToAttributes(tableState.filters)
      }
    })
  , []);

  const onTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorter;
    const nextTableState: TableStateType = {
      filters: filters,
      sort: {
        key: columnKey,
        order: order === "descend" ? 'descend' : 'ascend'
      },
    }
    setTableState(nextTableState)
    setStoredTableState(nextTableState)
    getOrders({ variables: { page: current, perPage: pageSize, sort: nextTableState.sort, search: filtersToAttributes(filters) } });
  }

  return (
    <div>
      <Table
        columns={columns}
        pagination={{
          pageSize: perPage,
          total: total,
          current: currentPage,
          showSizeChanger: true,
        }}
        dataSource={orders}
        loading={loading}
        rowKey={record => (record ? `order-${record.id}` : "order")}
        sticky
        rowClassName={styles.linkHover}
        onRow={({ id }) => ({
          onClick: () => {
            dispatch(push(`/orders/${id}`));
          }
        })}
        onChange={onTableChange}
      />
    </div>
  );
};

export default connect(null, null)(OrdersList);