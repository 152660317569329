import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import SpotCheckbox from "./Checkbox";
import styles from "./index.sass";

const MonthColumns = ({ month, year, onChange, values, enabledOptions }) => {
  const checkboxes = [];
  const currentDate = moment(`${year}-${month}-01`).startOf("week");
  const endOfMonth = moment(`${year}-${month}-01`).endOf("month");
  while (currentDate.isSameOrBefore(endOfMonth)) {
    const formattedDate = currentDate.format("YYYY-MM-DD");
    if (currentDate.month() === endOfMonth.month()) {
      checkboxes.push(
        <SpotCheckbox
          value={formattedDate}
          key={formattedDate}
          onChange={onChange}
          checked={values.includes(formattedDate)}
          disabled={!enabledOptions.includes(formattedDate)}
        />
      );
    }
    currentDate.add(7, "days");
  }

  return (
    <div key={`${year}-${month}`}>
      <span className={styles.monthName}>
        {moment(`2018-${month}-01`).format("MMMM")}
      </span>
      {checkboxes}
    </div>
  );
};

MonthColumns.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  enabledOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

export default MonthColumns;
