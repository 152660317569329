import { notification } from 'antd';
import nl2br from '../../components/InPlaceForm/utils/nl2br';

type FlashType = {
  type: 'open' | 'success' | 'warning' | 'error';
  description: string;
};

const showNotification = (flash: FlashType) => {
  notification[flash.type || 'open']({
    message: flash.message,
    description: nl2br(flash.description),
    placement: 'bottomRight',
    duration: 2,
  });
};

const handleNotifications = response => {
  const { data } = response;
  if (data) {
    const queryKeys = Object.keys(data);
    queryKeys.forEach(key => {
      (data[key]?.flashMessages || []).forEach((flashMessage: any) => {
        showNotification(flashMessage);
      });
    });
  }
  return response;
};

export default handleNotifications;
