import gql from "graphql-tag";

const flashMessagesFragment = gql`
  fragment FlashMessageInfo on FlashMessage {
    type
    message
    description
  }
`;

export default flashMessagesFragment;
