import React, { useEffect, useMemo } from "react";
import { Table, Button } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import yearsBetween from "./yearsBetween";
import tableColumns from "./columns";
import broadcastSelectorFooter from "./footer";
import styles from "./styles.scss";
import YearSelector from "../YearSelector";
import { useTranslation } from "react-i18next";
import memoOnlyForKeys from "../InPlaceForm/utils/memoOnlyForKeys";

const BroadcastSelector = props => {
  const {
    className,
    products,
    year,
    onYearChange,
    startingOn,
    endingOn,
    loading,
  } = props;
  const [t] = useTranslation();
  useEffect(() => props.onInit(), []);
  const years = yearsBetween(startingOn, endingOn);
  const footer = useMemo(() => broadcastSelectorFooter({...props, years}, t), [props, years]);
  const {
    columns,
    numberOfWeek
  } = tableColumns(props, t);
  const width = 500 + numberOfWeek * 100;
  return (
    <div className={className}>
      <div style={{ display: 'flex' }}>
        <YearSelector options={years} value={year} onChange={onYearChange} />
      </div>
      <Table
        loading={loading}
        className={styles.table}
        rowKey="id"
        columns={columns}
        dataSource={products.concat([footer])}
        pagination={false}
        scroll={{ x: width > 1600 ? width : 1600 }}
      />
    </div>
  );
};

BroadcastSelector.defaultProps = {
  className: "",
  products: [],
  year: moment().year(),
  years: [],
  loading: false,
  footer: {},
  onYearChange: () => {},
  mode: "show"
};

BroadcastSelector.propTypes = {
  className: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  year: PropTypes.number,
  onYearChange: PropTypes.func,
  years: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  numberOfWeek: PropTypes.number.isRequired,
  footer: PropTypes.shape({}),
  mode: PropTypes.oneOf(["edit", "show"]),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default React.memo(BroadcastSelector, memoOnlyForKeys([
  "year",
  "loading",
  "endingOn",
  "startingOn",
  "products",
  "totalBroadcasts",
  "broadcastsCount",
  "mode",
  "orderProductSubmitting"
]));
