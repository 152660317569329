import fetch from "isomorphic-fetch";
import checkStatus from "./request/checkStatus";
import errorHandler from "./request/errorHandler";
import successHandler from "./request/successHandler";

let config = null;
if (process.env.NODE_ENV === "development") {
  config = require("../config.dev");
} else {
  config = require("../config");
}

const { rootUrl } = config;

const ROOT_URL = `${rootUrl}`;
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  const defaultOptions = {};
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === "POST" || newOptions.method === "PUT") {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        ...newOptions.headers
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: "application/json",
        ...newOptions.headers
      };
    }
  }
  return fetch(`${ROOT_URL}${url}`, newOptions)
    .then(checkStatus)
    .then(successHandler)
    .catch(errorHandler);
}
