import React, { useEffect, useState } from "react";
import { List } from "immutable";
import { connect } from "react-redux";
import { compose, onlyUpdateForKeys } from "recompose";
import moment from "moment";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Card } from "antd";

import OccupancyRatesTable from "../OccupancyRates/Table";
import withProducts from "../../utils/providers/withProducts";
import { orm as Product } from "../../models/products";
import { getProductData } from "../../routes/OccupancyRates";
import CategorySelector from "../CategorySelector";
import styles from "./index.sass";
import { useTranslation } from "react-i18next";
import { fetchProductsOccupancy } from "../../models/actions/products";

const mStartingOn = moment().startOf("week");
const mEndingOn = moment()
  .add(5, "weeks")
  .endOf("week");
const numberOfWeek = mEndingOn.diff(mStartingOn, "weeks") + 1;

const ProgramWidget = props => {
  const {
    categories,
    products
  } = props;

  const [t] = useTranslation();
  const [tableState, setTableState] = useState({
    filters: {},
  });
  const [filteredCategories, setFilteredCategories] = useState([]);

  const productFilters = products.filter(
    product =>
      filteredCategories.length === 0 || filteredCategories.includes(product.category)
  ).map(s => ({ value: s.id, text: s.title }));

  let data = products;
  if ((tableState?.filters?.title || []).size > 0)
    data = data.filter(s => occupancyRate.filters.title.includes(s.id));

  if (filteredCategories.length > 0)
    data = data.filter(s => filteredCategories.includes(s.category));


  data = data.map(product =>
    getProductData(product, {
      startingOn: mStartingOn.format("YYYY-MM-DD"),
      endingOn: mEndingOn.format("YYYY-MM-DD"),
      numberOfWeek
    })
  );

  useEffect(() => {
    props.fetchData();
  }, []);

  const onCategoryChange = (categories) => {
    setTableState({ filters: {} });
    setFilteredCategories(categories);
  }

  return (
    <Card>
      <Link
        to={{
          pathname: "/occupancy_rates",
          state: { categories: filteredCategories }
        }}
        className={styles.externalLink}
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </Link>
      <h2>{t("occupancyRate")}</h2>
      <div className={styles.filtersContainer}>
        <CategorySelector
          values={filteredCategories}
          options={categories}
          onChange={onCategoryChange}
        />
      </div>
      <OccupancyRatesTable
        showTotal={false}
        size="small"
        data={data}
        productFilters={productFilters}
        filters={tableState.filters}
        onChange={(pagination, filters) => setTableState({ filters })}
        startingOn={moment().startOf("week")}
        endingOn={moment()
          .add(5, "weeks")
          .endOf("week")}
      />
    </Card>
  );
};

const mapStateToProps = createSelector(
  state => Product.all(state),
  state => Product.categories(state),
  (products, categories) => {
    return {
      categories,
      products: products
    };
  }
);

const params = {
  startingOn: moment().startOf("week").format("YYYY-MM-DD"),
  endingOn: moment().add(5, "weeks").endOf("week").format("YYYY-MM-DD")
}

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch(fetchProductsOccupancy(params)),
});

export default compose(
  withProducts,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onlyUpdateForKeys([
    "categories",
    "categories"
  ])
)(ProgramWidget);
