import React from "react";
import PropTypes from "prop-types";

import { Table } from "antd";
import columns from "./columns";
import Legend from "./OccupancyRatesLegend";
import { useTranslation } from "react-i18next";

export const OccupancyRatesTable = props => {
  const { data, loading, onChange, size, sticky = false } = props;
  const [t] = useTranslation();
  const {
    columns: cls,
  } = columns(props, t);
  return (
    <div>
      <Table
        dataSource={data}
        columns={cls}
        rowKey="id"
        loading={loading}
        className=""
        pagination={false}
        onChange={onChange}
        sticky={sticky}
        scroll={{
          x: true,
          ...(!sticky ? {
            y: 550
          } : {})
        }}
      />
      <Legend />
    </div>
  );
};

OccupancyRatesTable.defaultProps = {
  data: [],
  loading: false,
  size: "normal",
  onChange: () => {}
};

OccupancyRatesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  size: PropTypes.oneOf(["small", "normal"]),
  loading: PropTypes.bool,
  onChange: PropTypes.func
};

export default OccupancyRatesTable;
