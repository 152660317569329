import React, { useState } from "react";
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Input, Radio, Row, Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StateFilter } from "./Payments";
import queryString from "query-string";
import { exportPayments } from "../models/uis/payments";
import styles from "./index.sass";
import FilterDropdown from "../components/FilterDropdown";
import { updateListStateFilter } from "../models/actions/ui/payments/updateStateFilter";
import { changeDatesAndFetchPayments } from "../models/actions/ui/payments/changeDatesAndFetch";
import { updateTableAndFetchList } from "../models/actions/ui/payments/updateTableAndFetch";
import {setAndFetchCurrent} from "../models/actions/ui/payments/setCurrentPayment";
import Payment from "../components/Payment";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";

const { RangePicker } = DatePicker;

export const StateDot = ({state, isLate} ) => {
  const colorForState = {
    pending_receipt: '#000000',
    paid: '#4CAF59',
    pending_payment: isLate ? '#ff7675' : '#FFFFFF'
  };

  const hide = (state === 'pending_payment' && !isLate)

  const style = {
    position: 'absolute',
    backgroundColor: colorForState[state],
    display: hide ? 'none' : 'block',
    top: '3px',
    left: '-20px',
  };

  return (
    <div className={styles.stateDot} style={style} />
  )
};

const roundAmount = (amount) => amount.toFixed(2).toLocaleString();

const columns = (t, sort) => [
  {
    title: t("words.client"),
    key: "client",
    width: 300,
    sorter: true,
    sortOrder: sort?.key === "client" && sort?.order,
    render: (text, record) =>
      record.id === "footer" ? (
        {
          props: {
            colSpan: 4
          },
          children: <strong>Total</strong>
        }
      ) : (
        <div>
          <strong>{record.order.client.title}</strong>
          <i style={{ display: 'block', fontSize: 12 }}>{record.order.clientCommercialName}</i>
        </div>
      )
  },
  {
    title: <span>{t("orderShow.title")}</span>,
    key: "ordersCode",
    width: 200,
    dataIndex: "ordersCode",
    sorter: true,
    sortOrder: sort?.key === "ordersCode" && sort?.order,
    render: (text, record) =>
      record.id === "footer" ? (
        {
          props: {
            colSpan: 0
          }
        }
      ) : (
        <strong>
          <a href={`/orders/${record.order.id}`} target="_blank">
            {record.order && record.order.code ? record.order.code : ""}
          </a>
        </strong>
      )
  },
  {
    title: <span>{t("payment.attributes.receipt")}</span>,
    key: "receiptCode",
    width: 200,
    render: (text, record) =>
      record.id === "footer" ? (
        {
          props: {
            colSpan: 0
          }
        }
      ) : (
        <strong>
          {record.receipt && record.receipt ? record.receipt : ""}
        </strong>
      )
  },
  {
    title: <span>{t("payment.attributes.dueOn")}</span>,
    width: 200,
    render: (text, record) =>
      record.id === "footer" ? (
        {
          props: {
            colSpan: 0
          }
        }
      ) : (
        <div className={styles.stateWrapper}>
          <div style={{position: 'relative'}}>
            <StateDot state={record.state} isLate={record.isLate} offSet />
            <strong>
              {record.dueOn && record.dueOn
                ? moment(record.dueOn).format("MM/YYYY")
                : ""}
            </strong>
          </div>
        </div>
      )
  },
  {
    title: <span>{t("payment.attributes.amount")}</span>,
    width: 200,
    render: (text, record) => record.id === "footer" ? (
        {
          props: {
            colSpan: 1
          },
          children: (
            <div style={{textAlign: "right"}}>
              <span>
                {record.payments > 0
                  ? `${roundAmount(record.payments)} €`
                  : ""}
              </span>
            </div>
          )
        }
      ) : (
        <div style={{textAlign: "right"}}>
          <span>
            {record.amount > 0 ? `${roundAmount(record.amount)} €` : ""}
          </span>
        </div>
      )
  },
  {
    title: <span>{t("payment.attributes.paidOn")}</span>,
    width: 200,
    render: (text, record) =>
      record.id === "footer" ? (
        {
          props: {
            colSpan: 1
          }
        }
      ) : (
        <div style={{ textAlign: "center" }}>
          <span>
            {record.paidOn && record.paidOn
              ? moment(record.dueOn).format("MM/YYYY")
              : ""}
          </span>
        </div>
      )
  }
];

const GET_PAYMENT_LIST = gql`
  query listPayments(
    $startingOn: String!
    $endingOn: String!
    $perPage: Int
    $page: Int
    $search: SearchProductsProgramsAttributes
    $sort: SortAttributes
  ) {
    listPayments(
      startingOn: $startingOn
      endingOn: $endingOn
      perPage: $perPage
      page: $page
      sort: $sort 
      search: $search
    ) {
      currentPage
      perPage
      total
      payments {
        id
        receipt
        amount
        state
        dueOn
        paidOn
        isLate
        receiptCanBeModified
        order {
          id
          code
          clientCommercialName
          client {
            id
            title
          }
        }
      }
    }
  }
`;


const usePaymentListUi = () => {
  const [internalPagination, setInternalPagination] = useState<{pageSize: number; page: number;}>({ pageSize: 25, page: 1 });
  const [dates, setDates] = useState([moment().startOf('month').format("YYYY-MM-DD"), moment().startOf('week').endOf('month').format('YYYY-MM-DD')]);
  const [stateFilter, setStateFilter] = useState("all");
  const [sort, setSort] = useState(undefined);
  const [search, setSearch] = useState(undefined);
  console.log(sort)
  const {
    data: {
      listPayments: {
        currentPage,
        perPage,
        total,
        payments
      } = {}
    } = {},
    loading,
  } = useQuery(GET_PAYMENT_LIST, { variables: {
    page: internalPagination.page,
    perPage: internalPagination.pageSize,
    startingOn: dates[0],
    endingOn: dates[1],
    search: {
      state: stateFilter,
      search,
    },
    ...(sort?.order ? sort : {})
  }});

  const onExport = () => {
    const stringifiedParams = queryString.stringify({
      starting_on: dates[0],
      ending_on: dates[1]
    });
    window.open(
      `/payments/export.csv?${stringifiedParams}&token=182e927be0ba8dfb959fd86f92f478633dc8a7d357`
    );
  }

  const onDatesChange = (mDates) => setDates(mDates.map(d => d.format('YYYY-MM-DD')))

  const onPageChange = (page: number, pageSize: number) => {
    setInternalPagination({ page, pageSize });
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    setSort({ key: sorter.columnKey, order: sorter.order })
  }
  

  
  return {
    startingOn: dates[0],
    filters: {},
    endingOn: dates[1],
    payments,
    loading,
    total,
    pagination: {
      current: currentPage,
      pageSize: perPage,
      total,
      showSizeChanger: true,
      onChange: onPageChange
    },
    stateFilter,
    search,
    sorter: sort,
    onTableChange,
    onExport,
    onSearch: setSearch,
    onStateChange: setStateFilter,
    onDatesChange
  }
};

const PaymentsList = props => {
  const {
    onRowClick,
  } = props;
  const [t] = useTranslation();
  const {
    payments,
    pagination,
    startingOn,
    endingOn,
    stateFilter,
    search,
    sorter,
    loading,
    onTableChange,
    onExport,
    onSearch,
    onStateChange,
    onDatesChange,
  } = usePaymentListUi();

  return (
    <div>
      <Payment />
      <Card key="payment-list">
        <div className={styles.header}>
          <h1>{t("words.dueDate")}</h1>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.filtersContainer}>
          <StateFilter
            onChange={({ key }) => onStateChange(key)}
            t={t}
            value={stateFilter}
          />
          <div className={styles.rangePicker}>
            <span>{t("filterByDates")}</span>
            <RangePicker
              onChange={onDatesChange}
              defaultValue={[moment(startingOn), moment(endingOn)]}
              format="DD/MM/YYYY"
            />
          </div>
        </div>
        <div className={styles.groupByFilter}>
          <span className={styles.groupByFilterLabel}>
            {`${t("program.view")}:`}
          </span>
          <Radio.Group value="list">
            <Link to="/payments">
              <Radio.Button className={styles.monthView} value="table">
                {t("program.viewType.table")}
              </Radio.Button>
            </Link>
            <Link to="/payments/list">
              <Radio.Button className={styles.paymentView} value="list">
                {t("program.viewType.list")}
              </Radio.Button>
            </Link>
          </Radio.Group>
        </div>
        <Row style={{ marginBottom: 16 }}>
          <Col span={14}>
            <Input.Search
              placeholder="Rechercher par client, bon de commande, numéro de facture..."
              onSearch={onSearch}
              defaultValue={search}
            />
          </Col>
        </Row>
        <Table
          sticky
          size="small"
          width={2000}
          bordered
          onChange={onTableChange}
          pagination={pagination}
          loading={loading}
          rowClassName={styles.linkHover}
          onRow={onRowClick}
          columns={columns(t, sorter)}
          scroll={{ x: 1300 }}
          rowKey={record =>
            record.id === "footer" ? "footer" : `${record.id}`
          }
          dataSource={payments}
        />
        <Button type="default" icon={<DownloadOutlined />} onClick={onExport}>
          {t("exportBroadcasts")}
        </Button>
      </Card>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onRowClick: (record) => ({
    onClick: ()  => {
      dispatch(setAndFetchCurrent(record.id))
    }
  }),
  onTableChange: (pagination, filters) => {
    dispatch(updateTableAndFetchList(pagination, filters))
  },
});

export default connect(undefined, mapDispatchToProps)(PaymentsList);
