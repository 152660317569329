import { Map, Set, fromJS } from "immutable";
import moment from "moment";
import createReducer from "../../utils/createReducer";
import {
  setLoading,
  setFilters,
  setCategories,
  setDates,
  setPagination,
  setCurrentPage
} from "./utils";

export const initialState = Map({
  startingOn: moment()
    .startOf("week")
    .format("YYYY-MM-DD"),
  endingOn: moment()
    .startOf("week")
    .add(8, "weeks")
    .format("YYYY-MM-DD"),
  categories: Set([]),
  loading: false,
  filters: Map({}),
  viewType: "spot",
  weekFilter: null,
  total: 0,
  currentPage: 1
});

const setWeekFilter = (state, { payload }) => {
  if (state.get("weekFilter") === payload) return state.set("weekFilter", null);
  return state.set("weekFilter", payload);
};

const reset = () => initialState;

const setState = (state, { payload }) => state.mergeDeep(fromJS(payload));

export const UI_PROGRAM_SET_STATE = "ui/programs/setState";
export const UI_PROGRAM_SET_LOADING = "ui/programs/setLoading";
export const UI_PROGRAM_SET_CATEGORIES = "ui/programs/setCategories";
export const UI_PROGRAM_SET_WEEK_FILTER = "ui/programs/setWeekFilter";
export const UI_PROGRAM_SET_DATES = "ui/programs/setDates";
export const SET_FILTERS = "ui/programs/setFilters";
export const SET_PAGINATION = "ui/programs/setPagination";
export const SET_CURRENT_PAGE = "ui/programs/setCurrentPage";
export const UI_PROGRAM_RESET_STATE = "ui/programs/resetState";

const uiProgramReducer = createReducer(initialState, {
  [UI_PROGRAM_RESET_STATE]: reset,
  [UI_PROGRAM_SET_STATE]: setState,
  [UI_PROGRAM_SET_LOADING]: setLoading,
  [UI_PROGRAM_SET_CATEGORIES]: setCategories,
  [UI_PROGRAM_SET_DATES]: setDates,
  [SET_FILTERS]: setFilters,
  [SET_PAGINATION]: setPagination,
  [SET_CURRENT_PAGE]: setCurrentPage,
  [UI_PROGRAM_SET_WEEK_FILTER]: setWeekFilter
});

export default uiProgramReducer;
