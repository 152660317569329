import { normalize } from "normalizr";
import isNil from "lodash/isNil";
import omit from 'lodash/omit';
import Schemas from "../../schema";

import { requestQuery, requestMutation } from "../../utils/graphql";
import * as types from "../constants/clients";
import {
  clientsQuery,
  createClientQuery,
  deleteClientQuery,
  updateClientQuery
} from "../../services/clients";
import { SET_ENTITIES } from "../constants/entities";

const fetchRequest = () => ({
  type: types.FETCH_REQUEST
});

export const deleteClient = id => dispatch => {
  requestMutation(deleteClientQuery, { input: { id }})
    .then(data => (data.deleteClient ? data.deleteClient : {}))
    .then(data => {
      if (data.deleted) {
        dispatch({
          type: types.DELETE_SUCCESS,
          payload: id
        });
      }
    });
};

export const saveClient = (attributes) => (dispatch) => {
  dispatch({ type: "clientForm/submit" });
  const query = isNil(attributes.id)
    ? createClientQuery
    : updateClientQuery;
  
  requestMutation(query, { input: { attributes: omit(attributes, ['id']), id: attributes.id } })
    .then(data => {
      if (data.updateClient) return data.updateClient;
      if (data.createClient) return data.createClient;
      return {};
    })
    .then(data => {
      const { errors, client } = data;
      if (client) {
        if (errors) {
          dispatch({
            type: "clientForm/setCurrent",
            payload: client
          });
        } else {
          const normalizedData = normalize(client, Schemas.CLIENT);
          dispatch({
            type: SET_ENTITIES,
            entities: normalizedData.entities
          });
          if (isNil(attributes.id)) {
            dispatch({
              type: types.INSERT,
              payload: normalizedData.result
            });
          }
          dispatch({ type: "clientForm/reset" });
        }
      }
    }).catch(e => {
      if(e.errors) {
        dispatch({
          type: "clientForm/setCurrent",
          payload: attributes
        });
        dispatch({ type: 'clientForm/setErrors', payload: e.errors });
        dispatch({  
          type: "clientForm/setLoading",
          payload: false
        });
      }
    });
};

export const fetchClients = () => dispatch => {
  dispatch(fetchRequest());
  requestQuery(clientsQuery)
    .then(data => (data.clients ? data.clients : []))
    .then(clients => {
      const normalizedData = normalize(clients, Schemas.CLIENTS);
      dispatch({
        type: SET_ENTITIES,
        entities: normalizedData.entities
      });
      dispatch({
        type: types.FETCH_SUCCESS,
        payload: normalizedData.result
      });
    });
};

export const fetchIfNeeded = () => (dispatch, getState) => {
  if (getState().clients.get("list").size === 0) {
    dispatch(fetchClients());
  }
};
