import * as actionTypes from "./constants/user";
import createReducer from "../utils/createReducer";

const set = (state, { payload }) => ({
  ...payload
});

const userReducer = createReducer(
  {},
  {
    [actionTypes.SET]: set
  }
);

export default userReducer;
