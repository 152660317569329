import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Select, DatePicker, Button } from "antd";
import styles from "../index.scss";
import fieldErrorsToItemProps from "../../../utils/forms/fieldsErrorsToItemProps";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { MonthPicker } = DatePicker;

const paymentFields = ["amount", "receipt", "state", "dueOn", "paidOn"];

const formConfig = {
  mapPropsToFields(props) {
    const { payment } = props;
    const fields = {};
    paymentFields.forEach(fieldName => {
      switch(fieldName) {
        case 'receipt':
          fields.receipt = Form.createFormField({ value: payment.receipt?.value || `${moment().format('YYYY')}-` })
          break;
        default: 
          fields[fieldName] = Form.createFormField(payment[fieldName] || {});
      }
    });
    return fields;
  },
  onFieldsChange({ onChange, index, payment }, fields) {
    const f = {
      ...fields,
      ...(payment.state &&
      payment.state.value === "pending_receipt" &&
      fields.receipt &&
      fields.receipt.value
        ? {
            state: {
              value: "pending_payment"
            }
          }
        : {})
    };
    onChange(f, index);
  }
};

const PaymentForm = props => {
  const {
    showLabels,
    index,
    form,
    disabledDate,
    showReceiptField,
    onDelete,
    payment
  } = props;
  const [t] = useTranslation();
  const { getFieldDecorator, getFieldError, getFieldValue } = form;
  if (payment._destroy) {
    return (
      <Row
        gutter={16}
        type="flex"
        className={styles.desactivatedPayment}
        style={{ padding: "10px 0px" }}
      >
        <Col>
          <Button onClick={() => onDelete(index, false)}>
            {t("words.reactivate")}
          </Button>
        </Col>
      </Row>
    );
  }
  const canModifyAmount = getFieldValue("state") === "pending_receipt";
  const isPaid = getFieldValue("state") === "paid";
  const states = [
    ...(showReceiptField || canModifyAmount ? ["pending_receipt"] : []),
    ...["pending_payment", "paid"]
  ];
  return (
    <Form layout="vertical">
      <Row
        gutter={16}
        type="flex"
        className={payment._destroy ? styles.desactivatedPayment : ""}
      >
        <Col md={3} style={{ display: 'flex', marginBottom: index === 0 ? 30 : 0, alignItems: 'flex-end' }}>
          <Form.Item className={showLabels ? styles.submit : ""}>
            {`${t("payment.title")} ${index + 1}`}
          </Form.Item>
        </Col>
        <Col md={5}>
          <Form.Item
            colon={false}
            label={showLabels ? t("payment.attributes.dueOn") : null}
            {...fieldErrorsToItemProps(getFieldError("dueOn"))}
          >
            {getFieldDecorator("dueOn", {
              disabled: !canModifyAmount,
              rules: [{ required: true, message: t("errors.required") }]
            })(
              <MonthPicker
                format="MMM YYYY"
                style={{ width: "100%" }}
                disabled={!canModifyAmount}
              />
            )}
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            colon={false}
            label={showLabels ? t("payment.attributes.amount") : null}
            {...fieldErrorsToItemProps(getFieldError("amount"))}
          >
            {getFieldDecorator("amount", {
              rules: [{ required: true, message: t("errors.required") }]
            })(<Input disabled={!canModifyAmount} />)}
          </Form.Item>
        </Col>
        <Col md={4}>
          {showReceiptField ? (
            <Form.Item
              colon={false}
              label={showLabels ? t("payment.attributes.receipt") : null}
              {...fieldErrorsToItemProps(getFieldError("receipt"))}
            >
              {getFieldDecorator("receipt", {
                rules: [
                  {
                    required: getFieldValue("state") !== "pending_receipt",
                    message: t("errors.required")
                  },
                  {
                    pattern: /^[0-9]{4}-[a-zA-Z0-9]{4}$/,
                    message: t("errors.codePattern")
                  }
                ]
              })(<Input />)}
            </Form.Item>
          ) : (
            <div style={{ width: "171px" }} />
          )}
        </Col>
        <Col md={4}>
          <Form.Item
            colon={false}
            label={showLabels ? t("payment.attributes.state") : null}
            {...fieldErrorsToItemProps(getFieldError("state"))}
          >
            {getFieldDecorator("state", {
              rules: [{ required: true, message: t("errors.required") }]
            })(
              <Select>
                {states.map(s => (
                  <Select.Option value={s} key={s}>
                    {t(`payment.states.${s}`)}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {isPaid && (
          <Col>
            <Form.Item
              colon={false}
              label={showLabels ? t("payment.attributes.paidOn") : null}
              {...fieldErrorsToItemProps(getFieldError("paidOn"))}
            >
              {getFieldDecorator("paidOn", {
                rules: [{ required: true, message: t("errors.required") }]
              })(<DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>
        )}
        {getFieldValue("state") !== "paid" && (
          <Col md={4} style={{ display: 'flex', marginBottom: index === 0 ? 24 : 0, alignItems: 'flex-end' }}>
            <Form.Item className={showLabels ? styles.submit : ""}>
              <Button type="default" danger onClick={() => onDelete(index, true)}>
                {t("delete")}
              </Button>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

PaymentForm.defaultProps = {
  showLabels: true,
  showReceiptField: true,
  payment: {},
  disabledDate: () => false
};

PaymentForm.propTypes = {
  t: PropTypes.func.isRequired,
  showLabels: PropTypes.bool,
  payment: PropTypes.shape({}),
  form: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  disabledDate: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  showReceiptField: PropTypes.bool
};

export default Form.create(formConfig)(PaymentForm);
