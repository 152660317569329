import React from "react";
import PropTypes from "prop-types";
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Avatar, Space } from "antd";
import { pure } from "recompose";
import { withRouter, Link } from "react-router-dom";

import styles from "./index.scss";

export const MenuContainer = ({ children }) => (
  <div className={styles.header}>{children}</div>
);

MenuContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const GlobalHeader = props => {
  const { onMenuClick, currentUser, onCollapse, collapsed } = props;
  const menu = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined />
        &nbsp; Déconnexion
      </Menu.Item>
    </Menu>
  );

  return (
    <MenuContainer>
      <div
        onClick={onCollapse}
        className={styles.trigger}
      >
        {collapsed ? (
          <MenuUnfoldOutlined />
        ) : (
          <MenuFoldOutlined />
        )}
      </div>    
      <div className={styles.right}>
        {currentUser ? (
          <Dropdown overlay={menu}>
            <Space>
              <Avatar
                className={styles.avatar}
                src={currentUser.image}
                alt="avatar"
              />
              {currentUser.name}
            </Space>
          </Dropdown>
        ) : (
          <Link to="/user/login">Connexion</Link>
        )}
      </div>
    </MenuContainer>
  );
};

GlobalHeader.defaultProps = {
  currentUser: null,
  collapsed: false,
  onCollapse: () => {}
};

GlobalHeader.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}),
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func
};

export default withRouter(pure(GlobalHeader));
