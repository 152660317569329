import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
/**
 * It transform an AntD formState into a valid parameters object
 * @param formState - Object with attribute and value
 * @param except - Array of attribute to ignore
 * @returns {Object} - Get an object with value and key of all attribute
 */
const mapFormStateToAttributes = (formState, except = []) =>
  mapValues(omit(formState, except), a => a.value);

export default mapFormStateToAttributes;
