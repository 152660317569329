import { createSelector } from "reselect";
import { orm as Product } from '../products'
import createDeepEqualSelector from "../../utils/createDeepEqualSelector";

export const orderDefinition = {
  description: "String",
  trelloUrl: "String",
  totalBroadcasts: "Integer",
  startingOn: 'Date',
  endingOn: 'Date',
  commercialContact: 'String',
  commercialContactEmail: 'String'
};


const current = createSelector(
  state => state.orderShow.current,
  (order) => order
);

export const productsWithBroadcasts = createDeepEqualSelector(
  state => current(state),
  state => Product.all(state),
  (order, products) => {
    if(!products || !order) return {}
    const {
      broadcastsByProduct,
    } = order;

    const productsById = order.orderProducts.reduce((acc, value) => {
      acc[value.productId] = value;
      return acc;
    }, {});

    const byProductType = {};

    const ss = products.filter(s => Object.keys(productsById).map(id => parseInt(id, 10)).includes(parseInt(s.id, 10)))
      .map(product => {
        const { id, productType } = product;
        if (broadcastsByProduct && broadcastsByProduct[id]) {
          const broadcasts = broadcastsByProduct[parseInt(product.id, 10)];
          if (!byProductType[productType]) byProductType[productType] = 0;

          byProductType[productType] += broadcasts.length || 0;
          return {
            ...productsById[product.id.toString()],
            ...product,
            broadcasts
          };
        }
        return {
          comment: productsById[product.id.toString()].comment,
          ...product
        };
      });
    return {
      byProduct: ss,
      byProductType
    };
  }
)


export const orm = {
  productsWithBroadcasts,
};
