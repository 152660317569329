import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
}) => (
  <div className="ant-table-filter-dropdown">
    <ul className="ant-dropdown-menu ant-dropdown-menu-without-submenu ant-dropdown-menu-root ant-dropdown-menu-vertical">
      <li className="ant-dropdown-menu-item">
        <Input
          placeholder="Rechercher..."
          value={selectedKeys ? selectedKeys[0] : null}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
        />
      </li>
    </ul>
    <div className="ant-table-filter-dropdown-btns" style={{ paddingLeft: 8 }}>
      <a
        className="ant-table-filter-dropdown-link confirm"
        onClick={() => handleSearch(selectedKeys, confirm)}
      >
        OK
      </a>
      <a
        className="ant-table-filter-dropdown-link clear"
        onClick={clearFilters}
      >
        Réinitialiser
      </a>
    </div>
  </div>
);

FilterDropdown.defaultProps = {
  selectedKeys: []
};

FilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired
};

export default FilterDropdown;
