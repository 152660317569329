import { gql } from "@apollo/client";
const reportQuery = gql`
  query broadcastsReport(
    $startingOn: String!
    $endingOn: String!
    $interval: String
    $groupBy: String
    $priceType: String
    $pagination: Boolean
  ) {
    broadcastsReport(
      startingOn: $startingOn
      endingOn: $endingOn
      interval: $interval
      groupBy: $groupBy
      priceType: $priceType
      pagination: $pagination
    ) {
      data {
        total
        product {
          category
          title
          productType
        }
        intervals {
          startingOn
          price
        }
      }
    }
  }
`;

export default reportQuery;