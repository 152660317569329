import moment from "moment/moment";
import {
  UI_OCCUPANCY_RATES_SET_DATES,
  UI_OCCUPANCY_RATES_SET_FILTERS,
  UI_OCCUPANCY_RATES_SET_LOADING,
  UI_OCCUPANCY_RATES_SET_CATEGORIES
} from "../../uis/occupancyRates";
import { fetchProductsOccupancy } from "../products";

export const setLoading = payload => dispatch =>
  dispatch({
    type: UI_OCCUPANCY_RATES_SET_LOADING,
    payload
  });

export const setFilters = payload => ({
  type: UI_OCCUPANCY_RATES_SET_FILTERS,
  payload
});

export const setCategories = categories => ({
  type: UI_OCCUPANCY_RATES_SET_CATEGORIES,
  payload: categories
});

export const changeDatesAndFetch = momentDates => (dispatch, getState) => {
  const state = getState().ui.occupancyRates.toJS();

  const previousStartingOn = moment(state.startingOn);
  const previousEndingOn = moment(state.endingOn);

  const [mStartingOn, mEndingOn] = momentDates;

  const startingOn = mStartingOn.format("YYYY-MM-DD");
  const endingOn = mEndingOn.format("YYYY-MM-DD");
  dispatch({
    type: UI_OCCUPANCY_RATES_SET_DATES,
    payload: {
      startingOn,
      endingOn
    }
  });
  if (
    previousEndingOn.isBefore(mEndingOn) ||
    previousStartingOn.isAfter(mStartingOn)
  ) {
    dispatch(setLoading(true));
    dispatch(
      fetchProductsOccupancy({
        productIds: null,
        startingOn,
        endingOn
      })
    ).then(() => {
      dispatch(setLoading(false));
    });
  }
};
