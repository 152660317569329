import { Map, Set } from "immutable";
import { denormalize } from "normalizr";
import { createSelector } from "reselect";
import { uniq, filter, sortBy, reduce } from "lodash";
import { sagafetchProductsOccupancy, sagaUpdateProductOccupancyRate } from './actions/products';
import Schemas from "../schema";
import * as actionTypes from "./constants/products";
import createReducer from "../utils/createReducer";
import { all as sAll, takeLatest } from 'redux-saga/effects'

const initialState = Map({
  list: Set([]),
  loading: false
});

const setLoading = state => state.set("loading", true);

const setList = (state, { payload }) =>
  state.set("loading", false).set("list", Set(payload));

const insertToList = (state, { payload }) =>
  state.set("loading", false).updateIn(["list"], list => list.add(payload));

const removeFromList = (state, { payload }) =>
  state.updateIn(["list"], list => list.delete(payload));

const productsReducer = createReducer(initialState, {
  [actionTypes.FETCH_REQUEST]: setLoading,
  [actionTypes.FETCH_SUCCESS]: setList,
  [actionTypes.INSERT]: insertToList,
  [actionTypes.DELETE_SUCCESS]: removeFromList
});

const all = createSelector(
  state => state.entities,
  state => state.products.get("list"),
  (entities, ids) => {
    return sortBy(denormalize(ids.toJS(), Schemas.PRODUCTS, entities.toJS()), ["title"])
});

const categories = createSelector(all, products =>
  uniq(products.map(s => s.category))
);

const allByProductTypes = createSelector(all, categories, (products, rgs) => {
  const byProductTypes = {};
  products.forEach(product => {
    if (!byProductTypes[product.productType]) byProductTypes[product.productType] = {};
    if (!byProductTypes[product.productType][product.category]) byProductTypes[product.productType][product.category] = [];
    byProductTypes[product.productType][product.category].push(product)
  });
  return byProductTypes;
});

const allByIds = createSelector(all, products =>
  reduce(
    products,
    (result, value) => {
      // eslint-disable-next-line no-param-reassign
      result[value.id] = value;
      return result;
    },
    {}
  )
);


export function* productSaga() {
  yield sAll([
    takeLatest("products/fetchOccupancy", sagafetchProductsOccupancy),
    takeLatest("products/updateProductOccupancy", sagaUpdateProductOccupancyRate),
  ])
}

const find = createSelector(
  state => state.entities,
  (state, { id }) => id,
  (entities, id) => denormalize(id, Schemas.PRODUCT, entities.toJS())
);

export const orm = {
  all,
  allByIds,
  categories,
  find,
  allByProductTypes
};

export default productsReducer;
