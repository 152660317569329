import { useQuery, gql } from "@apollo/client";

const QUERY = gql`
  query users {
    users {
      id
      name
      email
    }
  }
`;

const useUsers = () => {
  const { data, loading } = useQuery(QUERY);
  return {
    users: (data?.users || []).filter(user => !user.email.includes('9troisquarts')),
    loading
  };
}

export default useUsers;