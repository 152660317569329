import { connect } from "react-redux";
import getAuthFromLS from "../../../utils/getAuthFromLS";

import isAuthenticated from "./isAuthenticated";

const PrivateComponent = ({ children, isAuthenticated }) => {
  if (isAuthenticated) return children;
  return null;
};

const mapStateToProps = ({ auth }, ownProps) => ({
  isAuthenticated: isAuthenticated(getAuthFromLS()),
  ...ownProps
});

export default connect(mapStateToProps)(PrivateComponent);
