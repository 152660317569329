import React, { useEffect, useState } from 'react';
import { PopoverProps } from 'antd/es/popover';
import { Popover, Divider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import styles from './index.sass';
import xor from 'lodash/xor';

type OptionType = {
  value: any;
  label: string;
};

export type PopoverSelectProps = {
  name: string;
  children: React.ReactChildren;
  value: any[];
  searchNoResult: string;
  options: Array<OptionType>;
  readOnly?: boolean;
  title?: string;
  onChange: (value) => void;
  renderOption: (option: OptionType) => any;
} & PopoverProps;

const OptionsContainer = props => {
  const { options, onClick, value, renderOption } = props;

  return options.map(option => {
    const selected = value?.includes(option.value);
    const containerClassNames: string[] = [styles.option];
    if (selected) containerClassNames.push(styles.selected);
    return (
      <div
        key={option.value}
        onClick={() => onClick(option.value)}
        className={containerClassNames.join(' ')}
      >
        <span className={styles.label}>
          {renderOption ? renderOption(option) : option.label}
        </span>
        {selected && <CheckOutlined />}
      </div>
    );
  });
};

const MultipleDropdownSelect: React.FC<PopoverSelectProps> = props => {
  const {
    onChange,
    children,
    title,
    name,
    readOnly = false,
    renderOption,
    searchNoResult,
    ...popoverProps
  } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState(props.value);
  const handleChange = value => onChange({ name, value });

  const [{ options, selectedOptions }, setOptions] = useState<{
    options: OptionType[];
    selectedOptions: OptionType[];
  }>({ options: [], selectedOptions: [] });

  const onClick = v => {
    const nextValue = xor(value || [], [v]);
    setValue(nextValue);
    handleChange(nextValue);
  };

  useEffect(() => {
    if (visible) {
      const nextOpts: OptionType[] = [];
      const selectedOpts: OptionType[] = [];
      props.options.forEach(option => {
        if (value?.includes(option.value)) selectedOpts.push(option);
        else nextOpts.push(option);
      });
      setOptions({ options: nextOpts, selectedOptions: selectedOpts });
    }
  }, [visible]);
  useEffect(() => setValue(props.value), [visible]);

  let opts = options;

  const content = (
    <>
      {selectedOptions.length > 0 && (
        <>
          <OptionsContainer
            value={value}
            options={selectedOptions}
            onClick={onClick}
            renderOption={renderOption}
          />
          <Divider style={{ margin: '7px 0' }} />
        </>
      )}
      {opts && opts.length > 0 && (
        <div className={styles.optionsWrapper}>
          <OptionsContainer
            value={value}
            options={opts}
            onClick={onClick}
            renderOption={renderOption}
          />
        </div>
      )}
    </>
  );

  return (
    <Popover
      title={title}
      {...(popoverProps || {})}
      content={content}
      overlayClassName={styles.popover}
      trigger="click"
      visible={visible}
      {...(readOnly ? { visible: false } : {})}
      onVisibleChange={setVisible}
    >
      {children}
    </Popover>
  );
};

export default React.memo(MultipleDropdownSelect);
