import React, { useEffect } from 'react';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Spin } from 'antd';
import { isNil, isArray, get } from 'lodash';
import { EditableFormSchema, FieldSchema } from './types';
import styles from './index.sass';
import EditableInput from './EditableInput';
import InPlaceDropdown from './InPlaceDropdown';
import InPlaceUpload from './InPlaceUpload';
import MultipleDropdownSelect from './MultipleDropdownSelect';
import Label from './formItem/Label';
import useEditable from './formItem/useEditable';
import displayedValue from './formItem/displayedValue';

export const indicator = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type SharedProps = {
  submitting?: string;
  editing?: string;
  object: any;
  errors?: any;
  onChange: (value: { name: string; value: any }) => void;
  onEditChange?: (value: { name: string; value: any }) => void;
};

type EditableFormProps = {
  readOnly?: boolean;
  schema: EditableFormSchema;
  style?: React.CSSProperties;
} & SharedProps;

type RenderFormItemProps = {
  field: FieldSchema;
  loading?: boolean;
} & SharedProps;

const RenderFormItem: React.FC<RenderFormItemProps> = props => {
  const {
    field,
    onChange,
    errors,
    onEditChange,
    object,
    submitting,
    editing,
    loading,
    ...rest
  } = props;
  const onlyRead = !!(props.readOnly || field.readOnly)
  const {
    internalEditing,
    handleEditChange,
    readOnly,
    setReadOnly
  } = useEditable({ loading, editing, readOnly: onlyRead, onEditChange})
  useEffect(() => {
    setReadOnly(onlyRead)
  }, [onlyRead])

  if (!field.props && !isNil(field.enabled) && !field.enabled) return null;

  const error = get(errors, field.name, null);
  const value = get(object, field.name, null);
  
  const label = <Label help={field.help} label={field.label} className={field.labelClassName} />;

  if (field.input?.type === 'select') {
    if (field.input.multiple) {
      const valueTitle = (field.input.options || [])
        .filter(option => value && value.includes(option.value))
        .map(o => o.label)
        .join(', ');
      return (
        <div>
          {label}
          <MultipleDropdownSelect
            name={field.name}
            options={field.input?.options}
            value={value}
            readOnly={readOnly}
            onChange={onChange}
          >
            <div>
              {valueTitle || '-'}
              &nbsp;
              {submitting && submitting === field.name ? (
                <Spin indicator={indicator} />
              ) : (
                <>{!readOnly && <DownOutlined />}</>
              )}
            </div>
          </MultipleDropdownSelect>
        </div>
      );
    } else {
      const valueTitle = (field.input.options || []).find(
        option => option.value === value,
      )?.label;
      return (
        <div>
          {label}
          {field.layout === 'inline' && <span>&nbsp;</span>}
          <InPlaceDropdown
            readOnly={readOnly}
            name={field.name} 
            options={field.input?.options}
            value={value}
            layout={field.layout}
            onChange={onChange}
          >
            <div>
              {valueTitle || '-'}
              &nbsp;
              {submitting && submitting === field.name ? (
                <Spin indicator={indicator} />
              ) : (
                <>{!readOnly && <DownOutlined />}</>
              )}
            </div>
          </InPlaceDropdown>
        </div>
      );
    }
  }

  const ValueComponent = displayedValue({ field, value });
  const showUnit = field.input?.options?.unit && !field.render;
  const suffix = ValueComponent && showUnit ? ` ${field.input?.options?.unit}` : '';

  if (field.readonly) {
    return (
      <div>
        {label}
        <div>
          {isNil(ValueComponent) ? '-' : ValueComponent}
          {suffix}
        </div>
      </div>
    );
  }

  const fieldName = isArray(field.name) ? field.name.join('.') : field.name;
  const editingField =
    internalEditing && isArray(internalEditing)
      ? internalEditing.join('.')
      : internalEditing;
  if (field.input?.type === 'file') {
    return (
      <div>
        {label}
        <div>
          <InPlaceUpload
            name={field.name}
            onChange={onChange}
            readOnly={readOnly}
            onSubmit={onChange}
            loading={submitting && submitting === field.name}
            edit={internalEditing && editingField === fieldName}
            onEditChange={handleEditChange}
          >
            {field.render ? field.render() : 'Fichier'}
          </InPlaceUpload>
        </div>
      </div>
    )
  }

  return (
    <div className={`${field.required && isNil(ValueComponent) ? styles.hasError : ''}`}>
      {label}
      <EditableInput
        {...rest}
        layout={field.layout || 'vertical'}
        error={error}
        editable={field.editable}
        readOnly={readOnly}
        name={field.name}
        value={value}
        edit={internalEditing && editingField === fieldName}
        type={field.input?.type}
        options={field.input?.options}
        {...(field.input?.inputProps || {})}
        loading={submitting && submitting === field.name}
        inline={field.input?.type !== 'text'}
        onEditChange={handleEditChange}
        onSubmit={onChange}
      >
        {isNil(ValueComponent) ? '-' : ValueComponent}
        {suffix}
      </EditableInput>
    </div>
  );
};

const RenderFieldsRow = props => {
  const { fields, ...rest } = props;
  const fls = fields
    .filter(e => e)
    .filter(f => f.props || f.enabled || isNil(f.enabled));


  if (!fls || fls.length === 0) return null;
  return (
    <Row gutter={16} justify="start" className={styles.fieldsRow}>
      {fls.map(f => {
        if (f.props) return f;
        return (
          <Col {...f.colProps} key={`col${f.key || f.name}`}>
            <RenderFormItem field={f} {...rest} />
          </Col>
        );
      })}
    </Row>
  );
};

const InPlaceForm: React.FC<EditableFormProps> = props => {
  const { style, schema, className="", ...rest } = props;
  return (
    <div className={className} style={style || {}}>
      {(schema || [])
        .filter(e => e)
        .map((field: any, i: number) => {
          if (field.props) return field;
          if (isArray(field))
            return (
              <RenderFieldsRow
                key={`fields-row-${i}`}
                fields={field}
                {...rest}
              />
            );
          return <RenderFormItem key={field.name} field={field} {...rest} />;
        })}
    </div>
  );
};

export default InPlaceForm;
