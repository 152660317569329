import gql from "graphql-tag";

export const toggleBroadcastVerified = gql`
  mutation toggleBroadcastVerified($input: ToggleBroadcastVerifiedInput!) {
    toggleBroadcastVerified(input: $input) {
      broadcast {
        id
        verified
        product {
          title
          id
        }
        order {
          id
          code
          state
          client {
            id
            title
          }
        }
      }
    }
  }
`;

export const getBroadcasts = gql`
  query broadcasts($search: BroadcastsSearchAttributes) {
    broadcasts(search: $search) {
      id
      verified
      completeWeek
      weekOccupancyPercent
      orderProduct {
        id
        comment
      }
      product {
        title
        id
      }
      order {
        id
        title
        code
        state
        trelloUrl
        client {
          id
          title
        }
      }
    }
  }
`;

export default {
  getBroadcasts,
  toggleBroadcastVerified
};
