import { OrderedSet, Map, fromJS } from "immutable";
import createReducer from "../utils/createReducer";

const initialState = Map({
  list: OrderedSet([]),
  stats: Map({})
});

const set = (state, { payload }) =>
  state
    .set("list", OrderedSet(payload.list))
    .set("stats", fromJS(payload.stats));

export const PROGRAMS_SET = "programs/set";
const programsReducer = createReducer(initialState, {
  [PROGRAMS_SET]: set
});

export default programsReducer;
