import React from 'react';
import styles from '../index.sass';
import { Upload, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload/interface';
import { useClickAway } from '@umijs/hooks';

type IProps = {
	className?: string;
	edit?: boolean;
	type?: string;
	name: string;
	children: React.ReactNode;
	readOnly?: boolean;
	editable?: boolean;
	onSubmit: (values: any) => void;
	onEditChange: (values: any) => void;
}

const InPlaceUpload: React.FC<IProps> = props => {
	const {
		name,
		edit,
		children,
		readOnly = false,
		className,
		editable = true,
		onSubmit,
		onEditChange,
	} = props;

	const ref = useClickAway(() => {
		if(edit) {
			onEditChange({ name, value: false });
		}
	});

	const onClick = ({ target: { tagName } }) => {
		if (editable && !edit && tagName.toLowerCase() !== 'a') {
			onEditChange({ name, value: true });
		}
	};

	const beforeUpload = (file: RcFile) => {
		onSubmit({ [name]: file });
		return false;
	};

	
	const containerProps = edit
		? { className: `${className} ${styles.editing}` }
		: {
			onClick,
			role: 'button',
			tabIndex: '0',
			className: `${className} ${editable && !readOnly ? styles.hoverrable : ''
				} ${styles.input}`,
		};

	return (
		<div ref={ref} {...containerProps}>
			{!edit ? children : (
				<Upload
					beforeUpload={beforeUpload}
				>
					<Button icon={<PlusOutlined />} style={{ padding: 0 }} type="link">
						Ajouter le devis signé
					</Button>
				</Upload>

			)}
		</div>
	)
};

export default InPlaceUpload;