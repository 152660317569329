import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Button, Popconfirm } from "antd";
import { orm as Order } from "../../models/orms/ordersOrm";
import { fetchCurrentOrderOccupancyRate } from '../../models/actions/products'
import withProducts from "../../utils/providers/withProducts";
import styles from "./index.scss";
import BroadcastSelector from "../BroadcastSelector/BroadcastSelector";
import createDeepEqualSelector from "../../utils/createDeepEqualSelector";
import Legend from '../OccupancyRates/OccupancyRatesLegend';
import { useTranslation } from "react-i18next";

type BroadcastsProps = {
  editing: boolean;
  orderState: string[];
  onDeleteAllBroadcast: () => void;
  beforeSave: () => void;
  broadcastsCount: number;
  totalBroadcasts: number;
  selectedProductTypes?: string[];
  broadcastsByProductType?: {
    [k: string]: number;
  }
}

const TotalDooh = props => {
  const {
    broadcastsCount,
    total
  } = props;
  const [t] = useTranslation();
  return (
    <Col xs={24} style={{ marginTop: 16 }}>
      <strong>DOOH</strong>
      <div>
        <strong>
          Total :
        </strong>
        &nbsp;
        <span className={broadcastsCount === total ? 'text-success' : 'text-alert'}>
          {broadcastsCount}  / {total} {t('words.broadcast', { count: broadcastsCount })}
        </span>
      </div>
    </Col>
  )
}

const Broadcasts: React.FC<BroadcastsProps> = props => {
  const {
    editing = false,
    orderState,
    totalBroadcasts = 0,
    onProductOrderSave,
    onDeleteAllBroadcast,
    orderProductSubmitting,
    selectedProductTypes,
    broadcastsByProductType,
    onBroadcastsSave,
    beforeSave,
  } = props;

  const mode = orderState === "editing" || editing ? "edit" : "show";
  const [t] = useTranslation();
  return (
    <Row key="broadcastSelector" className={styles.spotsPart}>
      <Col xs={24}>
        <BroadcastSelector
          onSubmit={onProductOrderSave}
          loading={orderProductSubmitting}
          mode={mode}
          {...props}
        />
        {mode === "edit" && <Legend />}
      </Col>
      {selectedProductTypes.includes("dooh") && (
        <TotalDooh
          broadcastsCount={broadcastsByProductType.dooh || 0}
          total={totalBroadcasts}
        />
      )}
      <Col xs={24}>
        {editing && (
          <Row type="flex" className={styles.broadcastSelectorFooter}>
            <div>
              <Popconfirm
                onConfirm={onDeleteAllBroadcast}
                title={t("orderShow.areYouSureToforceBroadcastsDelete")}
                okText={t("words.yes")}
                cancelText={t("words.no")}
              >
                <Button type="danger">
                  {t("orderShow.forceBroadcastsDelete")}
                </Button>
              </Popconfirm>
            </div>
            {orderState === "validated" && (
              <div>
                <Button
                  type="primary"
                  onClick={beforeSave ? () => beforeSave(onBroadcastsSave) : onBroadcastsSave}
                >
                  {t("words.refreshNow")}
                </Button>
              </div>
            )}
          </Row>
        )}
      </Col>
    </Row>
  );
};

const propsSelector = createDeepEqualSelector(
  state => state.orderShow.current,
  Order.productsWithBroadcasts,
  state => state.orderShow,
  (order, broadcasts, orderShow) => {
    const {
      startingOn,
      endingOn,
      totalBroadcasts,
      broadcastsCount
    } = order;

    const startingOnYear = moment(startingOn).year();
    const endingOnYear = moment(endingOn).year();
    const year = orderShow.broadcastSelector.year;
    const selectedProductTypes = (order?.turnovers || []).filter(t => t.turnover && t.turnover >= 0).map(t => t.productType);
    return {
      orderState: order.state,
      startingOn: startingOn,
      endingOn: endingOn,
      totalBroadcasts,
      selectedProductTypes,
      broadcastsCount,
      products: broadcasts.byProduct,
      broadcastsByProductType: broadcasts.byProductType,
      ...orderShow.broadcastSelector,
      editing: orderShow.editing,
      year: startingOnYear <= year && year <= endingOnYear ? year : endingOnYear
    };
  }
);

const mapDispatchToProps = dispatch => ({
  onInit: () => {
    dispatch(fetchCurrentOrderOccupancyRate());
    dispatch({ type: 'orderShow/getOrderBroadcasts' });
  },
  onYearChange: year =>
    dispatch({
      type: 'orderShow/broadcastSelector/setYear',
      payload: year
    }),
  onProductOrderSave: (orderProduct) => dispatch({ type: 'orderShow/saveOrderProduct', payload: orderProduct }),
  onChange: (productId, date, weekOccupancyPercent) => {
    dispatch({ type: 'orderShow/toggleOrderBroadcast', payload: { productId, date, weekOccupancyPercent } });
  },
  onBroadcastsSave: () => {
    dispatch({ type: 'orderShow/saveBroadcasts' });
    return;
  },
  onProductSelectAll: productId => dispatch({ type: 'orderShow/selectAllProduct', payload: productId }),
  onProductPartialSelectAll: (productId, weekOccupancyPercent = 50) => dispatch({ type: 'orderShow/selectAllProduct', payload: productId, weekOccupancyPercent }),
  onProductUnselectAll: productId => dispatch({ type: 'orderShow/unselectAllProduct', payload: productId }),
  onDateSelectAll: startingOn => dispatch({ type: 'orderShow/enableWeek', payload: startingOn }),
  onDatePartialSelectAll: (startingOn, weekOccupancyPercent = 50) => dispatch({ type: 'orderShow/enableWeek', payload: startingOn, weekOccupancyPercent }),
  onDateUnselectAll: startingOn => dispatch({ type: 'orderShow/disableWeek', payload: startingOn }),
  onDeleteAllBroadcast: () => dispatch({ type: 'orderShow/disableAllBroadcasts' }),
});

export default withProducts(
  connect(
    propsSelector,
    mapDispatchToProps
  )(Broadcasts)
);
