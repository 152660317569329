import isNil from "lodash/isNil";
import * as actionTypes from "./constants/global";
import createReducer from "../utils/createReducer";

const set = (state, { payload }) => ({
  ...payload
});

const toggleCollapsed = state => {
  if (state.collapsed) localStorage.removeItem("collapsed");
  else localStorage.setItem("collapsed", true);
  return {
    ...state,
    collapsed: !state.collapsed
  };
};

const initialCollapse = localStorage.getItem("collapsed");

const globalReducer = createReducer(
  {
    collapsed: !isNil(initialCollapse)
  },
  {
    [actionTypes.SET]: set,
    "global/toggleCollapsed": toggleCollapsed
  }
);

export default globalReducer;
