import findIndex from "lodash/findIndex";

// TODO

export const toggleSpot = (state, { payload, orderId }) => {
  const index = findIndex(
    state.getIn(["orders", orderId.toString(), "orderSpots"]).toJS(),
    e => e.startingOn === payload
  );
  if (index !== -1)
    return state.deleteIn(["orders", orderId.toString(), "orderSpots", index]);
  return state.updateIn(["orders", orderId.toString(), "orderSpots"], list =>
    list.push({ startingOn: payload })
  );
};

export const setPayments = (state, { payload, orderId }) =>
  state.setIn(["orders", orderId.toString(), "payments"], payload);
