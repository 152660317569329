import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import isEmpty from 'lodash/isEmpty';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import pick from 'lodash/pick';
import { camelizeKeys } from 'humps';
import { setAuthInLS, authenticationHeaders } from './getAuthFromLS';

import handleNotifications from "./request/handleNotifications";
import errorHandler from "./request/errorHandler";
import { store } from '../App';
import { updateAuth } from '../components/authentication/actions/auth';

const config = require(`../config${
  process.env.NODE_ENV === 'development' ? '.dev' : ''
}`).default;

export const extractAuthHeaders = (headers) => {
  const headersKey = ['uid', 'client', 'access-token', 'expiry'];
  return pick(headers, headersKey);
};

const updateAuthentication = new ApolloLink((operation, forward) =>
  forward(operation).map(data => {
    const { dispatch } = store;
    const context = operation.getContext();
    const { headers } = context;
    const authHeaders = extractAuthHeaders(headers);
    if (!isEmpty(authHeaders)) {
      dispatch(updateAuth(authHeaders));
      setAuthInLS(authHeaders)
    }
    return data;
  }),
);

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink({ uri: config.url }),
  createHttpLink({ uri: config.url }),
);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        ...authenticationHeaders(),
      },
    };
  });
  return forward(operation);
});

const errorLink = onError(data => {
  const { graphQLErrors, response, networkError } = data;
  const recordErrors = graphQLErrors?.find(f => f.message === 'Invalid record');
  if (networkError?.statusCode) {
    errorHandler(networkError?.statusCode)
  }
  if (recordErrors) {
    response.recordErrors = camelizeKeys(recordErrors.errors);
    response.errors = null;
  }
});

export const client = new ApolloClient({
  uri: config.url,
  link: authLink
    .concat(updateAuthentication)
    .concat(errorLink)
    .concat(httpLink),
  cache: new InMemoryCache({}),
});

export const requestQuery = (query, variables = {}, options = {}) => {
  return client.query({ query, variables, ...options }).then(result => {
    if (result?.data) return result.data;
    return result;
  }).catch(e => console.log(e));
}
export const requestMutation = (query, variables = {}, context = {}) =>
  client
    .mutate({ mutation: query, variables, context })
    .then(data => handleNotifications(data))
    .then(result => {
      if (result?.recordErrors) {
        throw { ...(result.data || {}), errors: result.recordErrors };
      }
      if (result?.data) return result.data;
      return result;
    })

export default requestQuery;
