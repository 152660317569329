import mapValues from "lodash/mapValues";
import omit from "lodash/omit";

/**
 * Transform an object record into his equivalent formState
 *
 * @param record - Object to transform to antd formState
 * @param except - Array of Attributes to ignore
 * @returns {Object} -
 */
const mapAttributesToFormState = (record, except = []) => {
  const clonedRecord = { ...record };
  const formState = mapValues(omit(clonedRecord, except), a => ({ value: a }));
  if (except.length > 0) {
    except.forEach(e => {
      if (clonedRecord[e]) formState[e] = clonedRecord[e];
    });
  }
  return formState;
};

export default mapAttributesToFormState;
