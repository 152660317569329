import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Popconfirm, Modal } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./index.scss";

import { fetchProducts, deleteProduct } from "../../models/actions/products";
import { orm as Products } from "../../models/products";
import ProductForm from "../ProductForm";

const columns = ({ editing, onEdit, onDelete }, t) => [
  {
    title: t("products.title"),
    dataIndex: "title",
    key: "title"
  },
  {
    title: t("products.productType"),
    dataIndex: "productType",
    key: "productType",
    render: productType => t(`productTypes.${productType}`)
  },
  {
    title: t("products.category"),
    dataIndex: "category",
    key: "category"
  },
  {
    title: t("products.city"),
    dataIndex: "city",
    key: "city"
  },
  {
    title: t("products.sides"),
    dataIndex: "sides",
    key: "sides",
    align: "center"
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        {editing === record.id ? (
          <span className={styles.action}>{t("edit")}</span>
        ) : (
          <a className={styles.action} onClick={() => onEdit(record)}>
            {t("edit")}
          </a>
        )}
        &nbsp; - &nbsp;
        {record.deletable ? (
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer le produit ?"
            onConfirm={() => onDelete(record.id)}
          >
            <a className={styles.action}>{t("delete")}</a>
          </Popconfirm>
        ) : (
          <span>{t("delete")}</span>
        )}
      </span>
    )
  }
];

export const ProductList = props => {
  const { products, editing, loading, onEditVisibilityChange } = props;
  useEffect(() => {
    props.fetch();
  }, []);
  const [t] = useTranslation();
  const onCancel = () => onEditVisibilityChange(false)
  return [
    <Table
      key="productTable"
      pagination={false}
      rowKey="id"
      dataSource={products}
      columns={columns(props, t)}
      loading={loading}
    />,
    <div key="productModal">
      {editing && editing && (
        <Modal visible onCancel={onCancel} footer={null} title={t(`products.${editing.id ? 'edit' : 'new'}`)}>
          <ProductForm onCancel={onCancel} />
        </Modal>
      )}
    </div>
  ];
};

ProductList.defaultProps = {
  loading: false,
  editing: null,
  products: [],
  onEditVisibilityChange: () => undefined
};

ProductList.propTypes = {
  editing: PropTypes.shape({}),
  loading: PropTypes.bool,
  onEditVisibilityChange: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({}))
};

const mapStateToProps = state => {
  const { products, ui } = state;
  return {
    loading: products.loading,
    editing: ui.productForm.current,
    products: Products.all(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetch() {
    dispatch(fetchProducts());
  },
  onDelete(id) {
    dispatch(deleteProduct(id));
  },
  onEdit(record) {
    dispatch({
      type: "productForm/setCurrent",
      payload: record
    });
  },
  onEditVisibilityChange(visible) {
    if (!visible) dispatch({ type: "productForm/reset" });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
