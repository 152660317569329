import mapValues from "lodash/mapValues";

/**
 * Transform JSON string errors to the correct AntD FormState structure
 * @param errors - the json string to transform
 * @returns {Object} - Attribute key paired with his errors
 */
const mapErrorsToFormState = errors => {
  const parsedErrors = JSON.parse(errors);
  return mapValues(parsedErrors, o => ({ errors: o }));
};

export default mapErrorsToFormState;
