import { paymentDefinition } from "../../../../services/orders";
import Payment from "../../../../services/payments";
import { castAttributesFromModel } from "../../../../utils/functions";
import { SAVE_ENTITY } from "../../../constants/entities";
import { unsetCurrentPayment } from "./setCurrentPayment";

/**
 * Update the current payment
 * @param {object} values - Next attributes of current payment
 */
const updatePayment = values => (dispatch, getState) => {
  const id = getState().ui.payments.getIn(["current", "id"], null);
  const p = new Payment({
    id,
    ...castAttributesFromModel(paymentDefinition, values)
  });
  p.save().then(({ payment, errors }) => {
    if (!errors) {
      dispatch({
        type: SAVE_ENTITY,
        entityType: "payments",
        id,
        payload: payment
      });
      dispatch(unsetCurrentPayment());
    }
  });
};

export default updatePayment;
