import moment from "moment/moment";
import qs from "query-string";
import fetchPaymentsReporting, { fetchListPaymentsReporting } from "./fetchPaymentsReporting";
import { UI_PAYMENTS_SET_STATE } from "../../../uis/payments";

/**
 * Init the payments page
 */
const init = () => (dispatch, getState) => {
  const {
    router: { location }
  } = getState();
  if (location.search && location.search.length > 0) {
    const searchParams = qs.parse(location.search);
    const nextState = {
      ...(searchParams.startingOn && {
        startingOn: moment(searchParams.startingOn)
          .startOf("week")
          .format("YYYY-MM-DD")
      }),
      ...(searchParams.endingOn && {
        endingOn: moment(searchParams.endingOn)
          .endOf("week")
          .format("YYYY-MM-DD")
      })
    };
    nextState.filters = {
      ...(searchParams.client && { client: [searchParams.client] }),
      ...(searchParams.orderCode && { orderCode: [searchParams.orderCode] })
    };
    dispatch({
      type: UI_PAYMENTS_SET_STATE,
      payload: nextState
    });
  }
  if (location.pathname === '/payments')
    dispatch(fetchPaymentsReporting());
  if (location.pathname === '/payments/list')
    dispatch(fetchListPaymentsReporting());
};

export default init;
