import _ from "lodash";
import queryString from "query-string";

export const getFromHeader = headers => {
  const keys = ["uid", "client", "access-token", "expiry"];
  const credentials = {};
  _.forEach(keys, key => {
    if (headers[key]) credentials[key] = headers[key];
  });
  return credentials;
};

export const getFromObject = object => {
  const keys = ["uid", "client_id", "auth_token", "expiry"];
  const credentials = {};
  _.forEach(keys, key => {
    if (object[key]) credentials[key] = object[key];
  });
  return credentials;
};

export const getFromLocation = () => {
  const object = queryString.parse(window.location.search);
  const keys = ["uid", "client_id", "auth_token", "expiry"];
  const nextKeys = ["uid", "client", "access-token", "expiry"];
  const credentials = {};
  _.forEach(keys, (key, i) => {
    if (object[key]) credentials[nextKeys[i]] = object[key];
  });
  return credentials;
};

export const getCredentialHeaders = object => ({
  "access-token": object["access-token"],
  client: object.client,
  uid: object.uid
});
