/* eslint-disable */
import React from "react";
import moment from "moment";
import { sumBy, every, some } from 'lodash';
import round from "lodash/round";
import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

import { Dropdown, Menu, Tooltip } from "antd";

import styles from "./index.sass";

import FilterDropdown from "../FilterDropdown";

const cx = classNames.bind(styles);

const Cell = (date, onCellClick) => (text, record) => {
  const { id, payments } = record;
  if (id === "footer") {
    if (record.payments[date]) {
      const amount = round(record.payments[date], 0);
      return (
        <strong>{amount > 0 ? `${amount.toLocaleString()} €` : ""}</strong>
      );
    }
    return null;
  }
  const pts = payments.filter(p => p.dueOn === date);
  if (!pts || pts.length === 0) return <span />;

  const className = cx({
    cell: true,
    pendingReceipt: some(pts, (p) => p.state === "pending_receipt"),
    paid: every(pts, (p) => p.state === "paid"),
    veryLate: some(pts, (p) => p.state === "pending_payment" && p.isLate)
  });

  const content = (
    <div className={styles.content}>
      {pts.filter(p => p.notes).length > 0 && (
        <span style={{ marginRight: "10px" }}>
          <Tooltip title={(
            <>
              {pts.map(payment => (
                <div>
                  {payment.receipt}&nbsp;:&nbsp;{payment.notes}
                </div>
              ))}
            </>
          )}>
            <FontAwesomeIcon icon={faComment} />
          </Tooltip>
        </span>
      )}
      {`${round(sumBy(pts, 'amount'), 2).toLocaleString()} €`}
    </div>
  );

  if (pts.length === 1) {
    return {
      props: {
        className,
        key: `payment-${pts.map(p => p.id).join('-')}`,
        onClick: () => onCellClick(pts[0].id)
      },
      children: content
    };
  }

  const menu = (
    <Menu 
    >
      {pts.map(payment => (
        <Menu.Item key={payment.id} onClick={() => onCellClick(payment.id)}>
          <div className={cx('bullet', { veryLate: payment.state === "pending_payment" && payment.isLate, paid: payment.state === "paid", pendingReceipt: payment.state === "pending_receipt" })}></div>
          {`${payment.receipt || "À facturer"} - ${payment.amount.toLocaleString()} €`}
        </Menu.Item>

      ))}
    </Menu>
  )

  return {
    props: {
      className,
      key: `payment-${pts.map(p => p.id).join('-')}`,
    },
    children: (
      <Dropdown trigger={['click', 'hover']} overlay={menu}>
        {content}
      </Dropdown>
    )
  };
};

const columns = props => {
  const { startingOn, endingOn, t, onCellClick, filters } = props;
  const mEndingOn = moment(endingOn).endOf("month");
  const mStartingOn = moment(startingOn);
  let numberOfColumn = 0;

  const totalNumberOfColumn = mEndingOn.diff(mStartingOn, "month") + 1;

  const columns = [
    {
      title: t("words.client"),
      key: "client",
      ...(totalNumberOfColumn > 5 && {
        fixed: "left"
      }),
      width: 200,
      filteredValue: filters.client || null,
      filterDropdown: FilterDropdown,
      render: (text, { id, client, order }) =>
        id === "footer" ? (
          {
            props: {
              colSpan: 2
            },
            children: <strong>Total</strong>
          }
        ) : (
          <div>
            <strong>{client.title}</strong>
            {order.clientCommercialName && (
              <i style={{ fontSize: 12, display: 'block' }}>{order.clientCommercialName}</i>
            )}
          </div>

        )
    },
    {
      title: <span>{t("orderShow.title")}</span>,
      dataIndex: "orderCode",
      ...(totalNumberOfColumn > 5 && {
        fixed: "left"
      }),
      width: 200,
      filteredValue: filters.orderCode || null,
      filterDropdown: FilterDropdown,
      render: (text, { id, order }) =>
        id === "footer" ? (
          {
            props: {
              colSpan: 0
            }
          }
        ) : (
          <strong>
            <a href={`/orders/${order.id}`} target="_blank">
              {order && order.code ? order.code : ""}
            </a>
          </strong>
        )
    }
  ];

  const currentDate = mStartingOn.endOf("month");

  while (currentDate.isSameOrBefore(mEndingOn)) {
    numberOfColumn += 1;
    const formattedDate = currentDate.format("YYYY-MM-DD");
    columns.push({
      align: "center",
      width: 150,
      title: <strong>{currentDate.format("MM/YYYY")}</strong>,
      render: Cell(formattedDate, onCellClick)
    });
    currentDate.add(1, "month").endOf("month");
  }

  return {
    columns,
    numberOfColumn
  };
};

export default columns;
