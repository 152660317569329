import { fromJS } from "immutable";

export const setFormState = (state, { payload }) =>
  state.set("loading", false).set("formState", fromJS(payload));

export const setSubmitting = state => state.set("submitting", true);

export const saveFormState = (state, { formState }) =>
  state.set("submitting", false).mergeDeepIn(["formState"], fromJS(formState));

export const resetFormState = state =>
  state.set("submitting", false).set("formState", fromJS({}));

export const setLoading = state => state.set("loading", true);
