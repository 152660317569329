import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { isAuthenticated } from "./isAuthenticated";

const UnAuthenticatedRoute = props => {
  const {
    component: Component,
    redirectUrl,
    isAuthenticated,
    location,
    ...rest
  } = props;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

UnAuthenticatedRoute.defaultProps = {
  isAuthenticated: false,
  redirectUrl: "/"
};

UnAuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  redirectUrl: PropTypes.string,
  location: PropTypes.shape({}).isRequired
};

function mapStateToProps({ auth }, ownProps) {
  return {
    isAuthenticated: isAuthenticated(auth),
    ...ownProps
  };
}

export default withRouter(connect(mapStateToProps)(UnAuthenticatedRoute));
