import React from "react";

import { Card, Row, Col } from "antd";
import styles from "./Login.sass";
import GoogleLoginButton from "../components/GoogleLoginButton";

let config = null;
if (process.env.NODE_ENV === "development") {
  config = require("../config.dev");
} else {
  config = require("../config");
}

const { rootUrl } = config;

const Login = () => (
  <Row
    type="flex"
    justify="center"
    align="middle"
    className={styles.loginContainer}
  >
    <Col xs={24} sm={12} lg={6}>
      <Card className={styles.card}>
        <h1>Bizzy</h1>
        <GoogleLoginButton
          redirectUri={`${rootUrl}/user/callback`}
        />
      </Card>
    </Col>
  </Row>
);

export default Login;
