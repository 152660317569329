import fetch from "isomorphic-fetch";
import queryString from "query-string";

import * as actionTypes from "../constants/auth";

import { setUser } from "../../../models/actions/user";

import { getCredentialHeaders } from "../credentialsUtils";

import axios from 'axios'
import successHandler from '../../../utils/request/successHandler';

export const updateAuth = credentials => ({
  type: actionTypes.AUTH_UPDATE,
  credentials
});

export const resetAuth = () => ({
  type: actionTypes.AUTH_RESET
});

export const signOut = () => (dispatch, getState) => {
  const state = getState();
  const { auth } = state;
  fetch("/auth/sign_out", {
    method: "DELETE",
    headers: getCredentialHeaders(auth)
  }).then(() => {
    dispatch(resetAuth());
  });
};

export const validateCredentials = credentials => dispatch => {
  axios.get(`/auth/validate_token?${queryString.stringify(credentials)}`, {
    method: "GET"
  }).then(successHandler).then((response) => {
    dispatch(setUser(response.data?.data));
  });
};
