import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styles from '../index.sass';


const renderHelpTooltip = (title: string) => {
  if (typeof title === 'undefined' || title === null) return null;
  const arr = title.split('\n');
  return arr.map((str, i) => <div key={i}>{str}</div>);
};

const Help = ({ help }) => (
  <Tooltip title={renderHelpTooltip(help)}>
    <InfoCircleOutlined style={{ marginLeft: 5 }} />
  </Tooltip>
)

const Label = ({ help, label, className = "" }) => {
  if (!label) return null;

  return (
    <span className={`${styles.label} ${className}`}>
      {label}
      {help && (<Help help={help} />)}
    </span>
  );
}

export default Label;