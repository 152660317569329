import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Divider, Spin, Radio } from "antd";
import { withTranslation } from "react-i18next";
import { compose, lifecycle } from "recompose";
import { createSelector } from "reselect";
import styles from "./index.sass";
import withProducts from "../utils/providers/withProducts";
import {
  fetchBroadcasts,
  updateCategoriesAndFetch,
  updateWeekAndFetch,
  updateActiveKey,
  redirectToFilteredPrograms,
  toggleBroadcast,
  updateView
} from "../models/actions/ui/broadcastsChecklist";
import { orm as Product } from "../models/products";
import { orm as Broadcast } from "../models/uis/broadcastsChecklist";
import CategorySelector from "../components/CategorySelector";
import WeekNavigation from "../components/WeekNavigation";
import BroadcastsCheckList from "../components/BroadcastsChecklist";

const BroadcastsChecklistPage = props => {
  const {
    t,
    onCategoryChange,
    onWeekChange,
    onViewChange,
    onTabChange,
    onCheck,
    categories,
    ui,
    group,
    onGoToProgram,
    broadcasts
  } = props;

  useEffect(() => {
    props.init();
  }, [])
  return (
    <Card>
      <div className={styles.header}>
        <h1>{t("broadcastsChecklist.title")}</h1>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.checklistContainer}>
        <WeekNavigation date={ui.week} onChange={onWeekChange} />
        <CategorySelector
          title={t("categorySelector.label")}
          options={categories}
          values={ui.categories}
          onChange={onCategoryChange}
        />
        <div className={styles.groupByFilter}>
          <span className={styles.groupByFilterLabel}>Regrouper par :</span>
          <Radio.Group value={ui.view} onChange={onViewChange}>
            <Radio value="product">{t("byProduct")}</Radio>
            <Radio value="order.client">{t("byClient")}</Radio>
          </Radio.Group>
        </div>
        {ui.loading ? (
          <div className={styles.loadingContainer}>
            <Spin />
          </div>
        ) : (
          <BroadcastsCheckList
            group={group}
            broadcasts={broadcasts}
            onTabChange={onTabChange}
            onGoToProgram={onGoToProgram}
            onCheck={onCheck}
            view={ui.view}
            activeKey={ui.activeKey}
          />
        )}
      </div>
    </Card>
  );
};

BroadcastsChecklistPage.defaultProps = {
  onCategoryChange: () => undefined,
  onWeekChange: () => undefined,
  onGoToProgram: () => undefined,
  t: () => undefined,
  categories: [],
  ui: {},
  broadcasts: {},
  group: []
};

BroadcastsChecklistPage.propTypes = {
  onWeekChange: PropTypes.func,
  onGoToProgram: PropTypes.func,
  onCategoryChange: PropTypes.func,
  onTabChange: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  broadcasts: PropTypes.shape({}),
  group: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  t: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.string),
  ui: PropTypes.shape({
    week: PropTypes.string,
    ui: PropTypes.oneOf(["product", "order.client"])
  })
};

const composedBroadcastsChecklist = withTranslation()(
  compose(
    withProducts,
  )(BroadcastsChecklistPage)
);

const propsSelector = createSelector(
  state => state.ui.broadcastsChecklist,
  state => Product.categories(state),
  state => Broadcast.groupByView(state),
  state => Broadcast.groups(state),
  (ui, categories, broadcasts, group) => ({
    ui: ui.toJS(),
    categories,
    broadcasts,
    group
  })
);

const mapStateToProps = state => propsSelector(state);

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(fetchBroadcasts()),
  onCategoryChange: categories => dispatch(updateCategoriesAndFetch(categories)),
  onViewChange: ({ target: { value } }) => dispatch(updateView(value)),
  onWeekChange: mDate =>
    dispatch(updateWeekAndFetch(mDate.format("YYYY-MM-DD"))),
  onTabChange: key => dispatch(updateActiveKey(key)),
  onGoToProgram: broadcast => dispatch(redirectToFilteredPrograms(broadcast)),
  onCheck: (id, checked, nextProductId) =>
    dispatch(toggleBroadcast(id, checked, nextProductId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(composedBroadcastsChecklist);
