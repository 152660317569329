import React from 'react';
import styles from './index.sass';
import FormInput from './FormInput';
import memoOnlyForKeys from './utils/memoOnlyForKeys';

type IProps = {
  className?: string;
  edit?: boolean;
  type?: string;
  name: string;
  children: React.ReactNode;
  readOnly?: boolean;
  editable?: boolean;
  onSubmit: (values: any) => void;
  onEditChange: (values: any) => void;
};

const EditableInput: React.FC<IProps> = props => {
  const {
    children,
    edit = false,
    name,
    readOnly = false,
    type = 'string',
    editable = true,
    onSubmit,
    className,
    onEditChange,
    ...rest
  } = props;

  const handleSubmit = ({ input }) => onSubmit({ [name]: input });

  const onKeyDown = e => {
    if (!edit && e.keyCode === 13) onEditChange({ name, value: true });
  };

  const onClick = ({ target: { tagName } }) => {
    if (editable && !edit && tagName.toLowerCase() !== 'a')
      onEditChange({ name, value: true });
  };
  const onCancel = () => onEditChange({ name, value: false });
  const containerProps = edit
    ? { className: `${className} ${styles.editing}` }
    : {
        onClick,
        role: 'button',
        tabIndex: '0',
        onKeyDown,
        className: `${className} ${
          editable && !readOnly ? styles.hoverrable : ''
        } ${styles.input}`,
      };

  return (
    <div {...containerProps}>
      {!edit ? (
        children
      ) : (
        <FormInput
          name={name}
          type={type}
          {...rest}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export default React.memo(
  EditableInput,
  memoOnlyForKeys(['edit', 'readOnly', 'loading', 'value', 'children']),
);
