import { FieldSchema } from "../types";
import moment, { isMoment } from 'moment';
import nl2br from '../utils/nl2br';
import isNil from 'lodash/isNil';

type ValueProps = {
  field: FieldSchema;
  value: any;
}

const castValueFromType = {
  'date': (value) => isMoment(value) ? value : moment(value).format('L'),
  'text': (value) => {
    if(value.length === 0) return null;
    return nl2br(value)
  },
  'float': (value) => value?.toLocaleString()
}

const displayedValue: React.FC<ValueProps> = (props) => {
  const {
    field,
    value,
  } = props;

  if (field.render) return field.render();  
  if (field.children) return field.children;
  if(isNil(value)) return null;
  if(value.length === 0) return null;

  if (castValueFromType[field.input?.type]) return castValueFromType[field.input?.type](value);
  return value;
};

export default displayedValue;