import qs from "query-string";
import moment from "moment";
import isArray from "lodash/isArray";

import {
  SET_FILTERS,
  SET_PAGINATION,
  UI_PROGRAM_SET_DATES,
  UI_PROGRAM_SET_LOADING,
  UI_PROGRAM_SET_CATEGORIES,
  UI_PROGRAM_SET_STATE,
  UI_PROGRAM_SET_WEEK_FILTER,
  UI_PROGRAM_RESET_STATE
} from "../../uis/program";
import requestQuery from "../../../utils/graphql";
import { searchProgramsQuery } from "../../../services/programs";
import { PROGRAMS_SET } from "../../programs";

export const setLoading = payload => ({
  type: UI_PROGRAM_SET_LOADING,
  payload
});

export const updateDates = payload => ({
  type: UI_PROGRAM_SET_DATES,
  payload
});

export const updateCategories = payload => ({
  type: UI_PROGRAM_SET_CATEGORIES,
  payload
});

export const setWeekFilter = payload => ({
  type: UI_PROGRAM_SET_WEEK_FILTER,
  payload
});

export const setPagination = payload => ({
  type: SET_PAGINATION,
  payload
});

export const setFilters = payload => ({
  type: SET_FILTERS,
  payload
});

export const reset = () => ({
  type: UI_PROGRAM_RESET_STATE
});

const filtersToAttributes = filters => {
  const { search, productIds } = filters;
  const attributes = {};
  if (productIds) attributes.productIds = productIds;
  if (search && search.length > 0) {
    const [searchedValue] = search;
    attributes.search = searchedValue;
  }
  return attributes;
};

export const fetchProgram = () => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { program }
  } = state;
  dispatch(setLoading(true));
  const filtersAttributes = filtersToAttributes(program.get("filters").toJS());
  return requestQuery(searchProgramsQuery, {
    startingOn: program.get("startingOn"),
    endingOn: program.get("endingOn"),
    page: program.get("currentPage"),
    perPage: program.get("pageSize"),
    search: {
      ...filtersAttributes,
      categories: program.get("categories"),
      period: program.get("weekFilter")
    }
  })
    .then(data => data.searchProductsPrograms || [])
    .then(({ currentPage, total, perPage: pageSize, productPrograms, stats }) => {
      dispatch(
        setPagination({
          total,
          currentPage,
          pageSize
        })
      );
      dispatch({
        type: PROGRAMS_SET,
        payload: {
          list: productPrograms,
          stats
        }
      });
      dispatch(setLoading(false));
    });
};

export const updateCategoriesAndFetch = categories => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { program }
  } = state;
  dispatch(updateCategories(categories));
  dispatch(
    setFilters({
      ...program.get("filters").toJS(),
      productIds: []
    })
  );
  dispatch(
    setPagination({
      currentPage: 1
    })
  );
  return dispatch(fetchProgram());
};

export const updateWeekFilterAndFetch = value => dispatch => {
  dispatch(setWeekFilter(value));
  dispatch(
    setPagination({
      currentPage: 1
    })
  );
  return dispatch(fetchProgram());
};

export const updateTableAndFetch = (
  { current, total, pageSize },
  filters
) => dispatch => {
  dispatch(
    setPagination({
      total,
      currentPage: current,
      pageSize
    })
  );
  dispatch(setFilters(filters));
  return dispatch(fetchProgram());
};

export const updateDatesRangeAndFetch = dates => dispatch => {
  const [mStartingOn, mEndingOn] = dates;
  const startingOn = mStartingOn.format("YYYY-MM-DD");
  const endingOn = mEndingOn.format("YYYY-MM-DD");
  dispatch(
    updateDates({
      startingOn,
      endingOn
    })
  );
  dispatch(
    setPagination({
      currentPage: 1
    })
  );
  return dispatch(fetchProgram());
};

export const init = () => (dispatch, getState) => {
  const {
    router: { location }
  } = getState();
  if (location.search && location.search.length > 0) {
    const searchParams = qs.parse(location.search);
    const nextState = {
      ...(searchParams.startingOn && {
        startingOn: moment(searchParams.startingOn)
          .startOf("week")
          .format("YYYY-MM-DD")
      }),
      ...(searchParams.endingOn && {
        endingOn: moment(searchParams.endingOn)
          .endOf("week")
          .format("YYYY-MM-DD")
      })
    };
    nextState.filters = {
      ...(searchParams.client && { client: [searchParams.client] }),
      ...(searchParams.productIds && {
        productIds: isArray(searchParams.productIds)
          ? searchParams.productIds
          : [searchParams.productIds]
      })
    };
    dispatch({
      type: UI_PROGRAM_SET_STATE,
      payload: nextState
    });
  }
  dispatch(fetchProgram());
};
