export const SET_ENTITIES = "SET_ENTITIES";
export const SAVE_ENTITY = "SAVE_ENTITY";
export const SET_ENTITY = "SET_ENTITY";
export const MERGE_ENTITIES = "mergeEntities";
export const UPDATE_ORDER_PRODUCT = "ORDERS_UPDATE_ORDER_PRODUCT";
export const ORDERS_SET_BROADCASTS_TO_PRODUCT =
  "ORDERS_SET_BROADCASTS_TO_PRODUCT";
export const ORDERS_PUSH_BROADCAST_TO_PRODUCT =
  "ORDERS_PUSH_BROADCAST_TO_PRODUCT";
export const ORDERS_REMOVE_BROADCAST_TO_PRODUCT =
  "ORDERS_REMOVE_BROADCAST_TO_PRODUCT";
export const ORDERS_CONCAT_BROADCAST_TO_PRODUCT =
  "ORDERS_CONCAT_BROADCAST_TO_PRODUCT";

export const UPDATE_ORDER_TURNOVER = "ORDERS_UPDATE_ORDER_TURNOVER";

export const ORDERS_REMOVE_BROADCASTS_TO_PRODUCT_AFTER_DATE =
  "ORDERS_REMOVE_BROADCASTS_TO_PRODUCT_AFTER_DATE";

export const ORDERS_TOGGLE_SPOT = "ORDERS_TOGGLE_SPOT";

export const ORDERS_SET_PAYMENTS = "entities/orders/setPayments";

export default {
  SET_ENTITIES,
  SAVE_ENTITY,
  SET_ENTITY,
  ORDERS_SET_PAYMENTS,
  ORDERS_SET_BROADCASTS_TO_PRODUCT,
  ORDERS_REMOVE_BROADCASTS_TO_PRODUCT_AFTER_DATE,
  ORDERS_REMOVE_BROADCAST_TO_PRODUCT,
  ORDERS_PUSH_BROADCAST_TO_PRODUCT,
  ORDERS_CONCAT_BROADCAST_TO_PRODUCT,
  ORDERS_TOGGLE_SPOT,
  UPDATE_ORDER_TURNOVER
};
