import React from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Card, Divider, Button } from "antd";
import styles from "../index.sass";

import OrdersList from "./components/OrdersList";
import { requestMutation } from "../../utils/graphql";
import { syncOrdersMutation } from "../../services/orders";

const onSyncAllOrders = () => requestMutation(syncOrdersMutation, { input: {} });

export const OrdersIndex = () => {
  
  return (
    <Card>
      <div className={styles.header}>
        <h1>Bons de commande</h1>
        <Button type="primary" icon={<UploadOutlined />} onClick={onSyncAllOrders}>
          Synchroniser les nouveaux devis
        </Button>
      </div>
      <Divider className={styles.divider} />
      <OrdersList />
    </Card>
  )
};

export default OrdersIndex
