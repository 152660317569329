import React from "react";
import PropTypes from "prop-types";

import { Table } from "antd";

import styles from "../index.sass";

const Report = props => {
  const { columns, data } = props;
  return (
    <Table
      onRow={record => ({
        className: `${styles.tableRow} ${
          record.id === "total" ? styles.totalRow : ""
        }`
      })}
      className={styles.table}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey='title'
      bordered={false}
    />
  );
};

Report.defaultProps = {
  columns: [],
  data: []
};

Report.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({}))
};

export default Report;
