import React from "react";
import PropTypes from "prop-types";
import intersection from "lodash/intersection";
import every from "lodash/every";
import { compose, mapProps, onlyUpdateForKeys } from "recompose";
import { Tabs, Checkbox, Tooltip, Typography, Tag, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import reduce from "lodash/reduce";
import styles from "./index.sass";
import StateButton from "../Order/StateButton";
import { faTrello } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const BroadcastsCheckList = props => {
  const {
    group,
    broadcasts,
    onTabChange,
    onCheck,
    groupIds,
    view,
    activeKey,
    productsByOrder
  } = props;
  const [t] = useTranslation();
  const broadcastsGroupIds = Object.keys(broadcasts);
  const ids = intersection(groupIds, broadcastsGroupIds);
  if (!ids.includes(activeKey)) onTabChange(ids[0]);
  return (
    <div>
      <Tabs
        onChange={onTabChange}
        tabPosition="left"
        activeKey={activeKey}
        className={styles.tabs}
        animated={false}
        tabBarStyle={{
          minWidth: "175px",
          maxHeight: 500,
          overflowY: 'scroll'
        }}
        moreIcon={null}
      >
        {ids.map((id, i) => {
          const nextGroupId = i + 1 < ids.length ? ids[i + 1] : null;
          return (
            <TabPane
              tab={
                <div className={styles.tab}>
                  <span className={styles.groupTitle}>{group[id].title}</span>
                  {every(broadcasts[id], { verified: true }) && (
                    <FontAwesomeIcon icon={faCheck} className={styles.check} />
                  )}
                </div>
              }
              key={id}
            >
              <h2>{group[id].title}</h2>
              {broadcasts[id] && (
                <div>
                  {broadcasts[id].map(broadcast => (
                    <div
                      key={broadcast.id}
                      className={styles.checkboxContainer}
                    >
                      <Checkbox
                        checked={broadcast.verified}
                        className={styles.checkbox}
                        onChange={e =>
                          onCheck(broadcast.id, e.target.checked, nextGroupId)
                        }
                      >
                        <div className={styles.title}>
                          {view === "order.client" && broadcast.product.title}
                          {view === "product" && broadcast.order.client.title}
                        </div>
                        <div className={styles.orderTitle}>
                            {!broadcast.completeWeek && (
                              <Tag color="orange">
                                Semaine partielle - {broadcast.weekOccupancyPercent}%
                              </Tag>
                            )}

                          {broadcast.order.code}
                          &nbsp;
                          {broadcast.order.title}
                          <a target="_blank" style={{ marginLeft: 5 }} href={`/orders/${broadcast.order.id}`}>
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </a>
                        </div>
                        {broadcast?.orderProduct?.comment && (
                          <div className={styles.orderDescription}>
                            <Tooltip title={broadcast.orderProduct.comment}>
                              <span className={styles.orderDescription}>
                                {broadcast.orderProduct.comment.substring(0, 60)}
                                {broadcast.orderProduct.comment.length > 60 ? '...' : ''}
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        {view === "product" && productsByOrder[broadcast.order.id] && (
                          <Space style={{ marginTop: 10, marginBottom: 10 }} wrap>
                            {(productsByOrder[broadcast.order.id]).filter(b => b.product.id !== broadcast.product.id).map(broadcast => (
                              <Tag onClick={() => onTabChange(broadcast.product.id)} key={broadcast.product.id}>
                                {broadcast.product.title}
                              </Tag>
                            ))}
                          </Space>
                        )}
                        {broadcast?.order?.trelloUrl && (
                          <div>
                            <a href={broadcast?.order.trelloUrl} target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon icon={faTrello} />
                              &nbsp;
                              {t("orderShow.showTrelloCard")}
                            </a>
                          </div>
                        )}
                        {["editing"].includes(broadcast.order.state) && (
                          <StateButton
                            state={broadcast.order.state}
                            tooltip={false}
                          />
                        )}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              )}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

BroadcastsCheckList.defaultProps = {
  group: {},
  broadcasts: {},
  onTabChange: () => undefined,
  onCheck: () => undefined,
  activeKey: null,
  view: "product",
  groupIds: []
};

BroadcastsCheckList.propTypes = {
  group: PropTypes.shape({}),
  groupIds: PropTypes.arrayOf(PropTypes.string),
  broadcasts: PropTypes.shape({}),
  onTabChange: PropTypes.func,
  onCheck: PropTypes.func,
  activeKey: PropTypes.string,
  view: PropTypes.oneOf(["product", "order.client"])
};

const reduceGroupById = (result, value) => {
  // eslint-disable-next-line no-param-reassign
  result[value.id] = value;
  return result;
};

const reduceProductsByOrder = (result, value) => {
  value.map(product => {
    if (!result[product.order.id]) result[product.order.id] = []
    result[product.order.id].push(product)
  })
  return result;
}

const transformProps = ({ group, ...rest }) => ({
  ...rest,
  group: reduce(group, reduceGroupById, {}),
  groupIds: group.map(({ id }) => id),
  productsByOrder: reduce(rest.broadcasts, reduceProductsByOrder, {})
});

const composedBroadcastsList = compose(
  mapProps(transformProps),
  onlyUpdateForKeys([
    "t",
    "tReady",
    "activeKey",
    "broadcasts",
    "group",
    "groupIds"
  ])
)(BroadcastsCheckList);

export default composedBroadcastsList;
