import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import { Card, Tabs, Button } from "antd";

import { fetchClients } from "../models/actions/clients";
import ProductList from "../components/ProductList";
import { fetchProducts } from "../models/actions/products";

const { TabPane } = Tabs;

const Administration = props => {
  const {
    onTabsChange,
    onObjectEdit
  } = props;
  const [activeKey, setActiveKey] = useState('product');
  return (
    <Card>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key)
          onTabsChange(key);
        }}
        tabBarExtraContent={
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => onObjectEdit('product')}
          >
            Ajouter
          </Button>
        }
      >
        {/* <TabPane tab="Clients" key="client">
          <ClientList />
        </TabPane> */}
        <TabPane tab="Produits" key="product">
          <ProductList />
        </TabPane>
      </Tabs>
    </Card>
  );
};

Administration.propTypes = {
  onTabsChange: PropTypes.func.isRequired,
  onObjectEdit: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  onTabsChange: key => {
    switch (key) {
      case "clients":
        dispatch(fetchClients());
        break;
      case "products":
        dispatch(fetchProducts());
        break;
      default:
        break;
    }
  },
  onObjectEdit: objectType => {
    dispatch({
      type: `${objectType}Form/setCurrent`,
      payload: {}
    });
  }
});

export default connect(
  null,
  mapDispatchToProps
)(Administration);
