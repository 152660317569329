export function replaceSpecialChars(s) {
  let str = s.replace(/[ÀÁÂÃÄÅ]/, 'A');
  str = str.replace(/[àáâãäå]/, 'a');
  str = str.replace(/[ÈÉÊË]/, 'E');
  str = str.replace(/[éèêëĘėē]/, 'e');
  str = str.replace(/[Ç]/, 'C');
  str = str.replace(/[ç]/, 'c');
  str = str.replace(/[^a-z0-9]/gi, '');
  return str;
}
