import notFoundImg from "./404.svg";
import internalError from "./500.svg";

const config = {
  403: {
    img: "https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg",
    title: "403",
    desc: "Authorization"
  },
  404: {
    img: notFoundImg,
    title: "404",
    desc: "Page non trouvé"
  },
  500: {
    img: internalError,
    title: "500",
    desc: "Une erreur s'est produite lors du traitement de la page"
  }
};

export default config;
