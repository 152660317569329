import React, { useEffect, useState } from "react";
import { Button, Checkbox, Row, Col, Modal } from "antd";
import { connect } from "react-redux";
import { uniq, remove, forEach } from "lodash";

import styles from "./index.scss";
import withProducts from "../../utils/providers/withProducts";
import { orm } from "../../models/products";
import { useTranslation } from "react-i18next";

const ProductSelectorColumn = React.memo(props => {
  const { products, title, onChange, value, disabledProducts } = props;
  return (
    <div className={styles.column}>
      <strong>{title}</strong>
      <div className={styles.checkboxGroup}>
        {products.map(({ id, title }) => (
          <Checkbox
          key={`product-${id}`}
          checked={value.includes(id)}
          disabled={disabledProducts.includes(id)}
          onChange={({ target: { checked } }) => onChange(id, checked)}
          >
            {title}
          </Checkbox>
        ))}
      </div>
    </div>
  );
});

const ProductSelector = props => {
  const {
    products,
    submitting,
    onSubmit,
    productTypes = [],
    disabledProducts,
    visible,
    onVisibleChange,
  } = props;
  const [t] = useTranslation();
  const values = props.values.map(v => v.toString());
  const [value, setValue] = useState(values);
  const handleChange = (id, checked) => {
    const newValues = [...value];
    if (checked) newValues.push(id);
    else remove(newValues, e => e === id);
    setValue(uniq(newValues));
  };
  const onOpen = () => onVisibleChange(true);
  const onClose = () => onVisibleChange(false);
  const handleSubmit = () => onSubmit(value);
  if (!productTypes) return null;
  return (
    <>
      <Modal
        width={1000}
        title={t("orderShow.selectProduct")}
        visible={visible}
        onCancel={onClose}
        onOk={handleSubmit}
        okButtonProps={{
          loading: submitting,
          type: 'primary'
        }}
        okText={t("save")}
      >
        <Row gutter={[16, 16]} type="flex" style={{ minWidth: 500 }}>
          {productTypes.flatMap(productType => {
            return Object.keys((products[productType] || {})).map(category => (
              <Col xs={24} className={styles.content} key={`${productType}-${category}`}>
                <ProductSelectorColumn
                  products={products[productType][category]}
                  title={`${t(`productTypes.${productType}`)} / ${category}`}
                  disabledProducts={disabledProducts}
                  onChange={handleChange}
                  value={value}
                />
              </Col>
            ))
          })}
        </Row>
      </Modal>
      <Button
        onClick={onOpen}
        type={value && value.length >= 0 ? "primary" : "default"}
      >
        {t("orderShow.selectProduct")}
        {value && value.length > 0 && (
          <>
            &nbsp;
            ({value.length})
          </>
        )}
      </Button>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const disabledProducts = [];
  forEach(
    state.orderShow.current.broadcastsByProduct || {},
    (value, key) => {
      if (value && value.length > 0) {
        disabledProducts.push(key);
      }
    }
  );
  const order = state.orderShow.current;
  const productTypes = (order?.turnovers || []).filter(t => t.turnover && t.turnover >= 0).map(t => t.productType);
  const products = orm.allByProductTypes(state);
  return {
    ...ownProps,
    values: uniq(order.productIds || []),
    disabledProducts,
    productTypes,
    visible: state.orderShow?.productSelector?.visible || false,
    products
  };
};

const mapDispatchToProps = dispatch => ({
  onVisibleChange: visible => dispatch({ type: 'orderShow/productSelector/setVisibility', payload: visible }),
  onSubmit: values => dispatch({type: 'orderShow/saveProducts', payload: values })
})

export default withProducts(connect(mapStateToProps, mapDispatchToProps)(React.memo(ProductSelector)));
