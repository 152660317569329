import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/authentication/Private/PrivateRoute";
import PublicRoute from "./components/authentication/Private/UnauthenticatedRoute";
import AuthenticationRoutes from "./components/authentication/router";

import AppLayout from "./components/AppLayout";
import Dashboard from "./routes/Dashboard";
import OrdersIndex from "./routes/Order/Index";
import OrderShow from "./routes/Order/Show";
import PageNotFound from "./routes/Exception/404";
import OccupancyRates from "./routes/OccupancyRates";
import Planning from "./routes/Planning";
import Administration from "./routes/Administration";
import InternalError from "./routes/Exception/500";
import Reporting from "./routes/ReportingPage";
import BroadcastsChecklist from "./routes/BroadcastsChecklist";
import Payments from "./routes/Payments";
import PaymentsList from "./routes/PaymentsList";

const ApplicationRoutes = () => (
  <Fragment>
    <AppLayout>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/administration" component={Administration} />
        <Route exact path="/orders" component={OrdersIndex} />
        <Route exact path="/orders/:id" component={OrderShow} />
        <Route exact path="/occupancy_rates" component={OccupancyRates} />
        <Route exact path="/payments" component={Payments} />
        <Route exact path="/payments/list" component={PaymentsList} />
        <Route
          exact
          path="/broadcasts_checklist"
          component={BroadcastsChecklist}
        />
        <Route exact path="/planning" component={Planning} />
        <Route exact path="/404" component={PageNotFound} />
        <Route exact path="/500" component={InternalError} />
        <Route exact path="/reporting/:view" component={Reporting} />
      </Switch>
    </AppLayout>
  </Fragment>
);

const Routes = () => (
  <Fragment>
    <Switch>
      <PublicRoute path="/user" component={AuthenticationRoutes} />
      <PrivateRoute path="/" component={ApplicationRoutes} />
    </Switch>
  </Fragment>
);

export default Routes;
