/**
 * Call submit function stored in store when all fields is valid
 * @param props - Props with the form object
 * @returns {function(*)}
 */
const handleSubmit = ({ form, submit }) => e => {
  e.preventDefault();
  form.validateFields({ force: true }, (err, values) => {
    if (!err) submit(values);
  });
};

export default handleSubmit;
