import gql from "graphql-tag";
import flashMessagesFragment from "./flashMessagesFragment";

export const clientInfo = gql`
  fragment ClientInfo on Client {
    id
    title
  }
`;

export const clientsQuery = gql`
  query clients {
    clients {
      ...ClientInfo
    }
  }
  ${clientInfo}
`;

export const createClientQuery = gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      client {
        ...ClientInfo
      }
      flashMessages {
        ...FlashMessageInfo
      }
      errors
    }
  }
  ${clientInfo}
  ${flashMessagesFragment}
`;

export const updateClientQuery = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      client {
        ...ClientInfo
      }
      flashMessages {
        ...FlashMessageInfo
      }
      errors
    }
  }
  ${clientInfo}
  ${flashMessagesFragment}
`;

export const deleteClientQuery = gql`
  mutation deleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      client {
        id
      }
      flashMessages {
        ...FlashMessageInfo
      }
      deleted
    }
  }
  ${flashMessagesFragment}
`;
