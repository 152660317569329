import React from 'react';
import { Row, Col, Space } from 'antd';
import { round, multiply, divide, isNil, subtract, sum } from 'lodash';
import { OrderTurnoverType, OrderType } from '../../types';
import InPlaceForm from '../InPlaceForm';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

type BudgetFormProps = {
  budget: OrderTurnoverType;
  readOnly: boolean;
}

const BudgetForm: React.FC<BudgetFormProps> = props => {
  const {
    budget,
    readOnly,
    user,
    loading,
  } = props;
  const [t] = useTranslation();
  const onChange = (values) => {
    props.onChange({
      ...budget,
      ...values,
    })
  }
  return (
    <InPlaceForm
      readOnly={readOnly}
      object={budget}
      onChange={onChange}
      loading={loading}
      schema={[[
        <Col xs={4}>
          {t(`productTypes.${budget.productType}`)}
        </Col>,
        {
          name: 'turnover',
          readOnly: user.role !== "admin",
          colProps: {
            xs: 4,
            align: 'right'
          },
          input: {
            type: 'float',
            options: {
              unit: '€'
            },
            inputProps: {
              addonAfter: "€"
            }
          }
        },
        {
          name: 'cost',
          colProps: {
            xs: 4,
            align: 'right'
          },
          input: {
            type: 'float',
            options: {
              unit: '€'
            },
            inputProps: {
              addonAfter: "€"
            }
          }
        },
        <Col xs={4} align="right">
          <strong>
            {(budget && (budget.cost || budget.turnover)) && (
              <span>
                {subtract(budget.turnover || 0, budget.cost || 0).toLocaleString()} €
              </span>
            )}
          </strong>
        </Col>
      ]]}
    />
  )
}

const TotalRow = props => {
  const {
    turnovers
  } = props;
  
  const turnoverSum = sum(turnovers.map(t => t.turnover || 0));
  const costSum = sum(turnovers.map(t => t.cost || 0));
  const margin = subtract(turnoverSum, costSum)
  return (
    <Row gutter={16}>
      <Col xs={4}>
        <strong className="uppercase">Total HT</strong>
      </Col>
      <Col align="right" xs={4}>
        <strong>{!isNil(turnoverSum) ? `${turnoverSum.toLocaleString()} €` : ''}</strong>
      </Col>
      <Col align="right" xs={4}>
        <strong>{!isNil(costSum) ? `${costSum.toLocaleString()} €` : ''}</strong>
      </Col>
      <Col xs={4} align="right" className="uppercase">
        {(!isNil(turnoverSum) || !isNil(costSum)) && (
          <strong>{!isNil(margin) ? round(margin, 2) : ''} €</strong>
        )}        
      </Col>
      <Col xs={2} align="right">
        {(!isNil(turnoverSum) || !isNil(costSum)) && (
          <strong>({round(multiply(divide(margin || turnoverSum || 1, turnoverSum || margin || 1), 100), 2)}%)</strong>
        )}
      </Col>
    </Row>
  )
}

type BudgetsFormProps = {
  turnovers: OrderTurnoverType[];
  totalBroadcasts: number;
  onSubmit: (orderTurnover: OrderTurnoverType) => void;
  onOrderSave: (order: OrderType) => void;
  loading?: boolean;
  readOnly?: boolean;
  budgetLoading?: boolean;
}

const BudgetsForm: React.FC<BudgetsFormProps> = props => {
  const {
    loading = false,
    budgetLoading = false,
    totalBroadcasts,
    turnovers,
    readOnly = false,
    onOrderSave,
    onSubmit,
    user,
  } = props;

  const turnoversByProductType = turnovers.reduce((acc, value) => {
    acc[value.productType] = value;
    return acc;
  }, {})

  let availableBudgets = ['waze', 'studio', 'other', 'ads', 'option'];
  if(user?.role !== 'admin')
    availableBudgets = availableBudgets.filter(productType => turnoversByProductType[productType] && turnoversByProductType[productType].turnover > 0)

  const onChange = (values) => onSubmit(values)
  return (
    <div style={{ marginBottom: 30 }}>
      <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <Col xs={4}>
            <strong className="uppercase">Catégorie</strong>
          </Col>
          <Col xs={4} align="right" className="uppercase">
            <strong>CA</strong>
          </Col>
          <Col xs={4} align="right" className="uppercase">
            <strong>Achats</strong>
          </Col>
          <Col xs={4} align="right" className="uppercase">
            <strong>Marge</strong>
          </Col>
        </Row>
        <BudgetForm user={user} readOnly={readOnly} loading={budgetLoading} budget={turnoversByProductType.dooh || { productType: 'dooh' }} onChange={onChange} />
        <InPlaceForm
          readOnly={readOnly}
          object={{ totalBroadcasts }}
          onChange={onOrderSave}
          loading={loading}
          schema={[[
            <Col xs={4} align="right">
              Nombre de diffusions (DOOH)
            </Col>,
            {
              name: 'totalBroadcasts',
              colProps: {
                xs: 3,
                align: 'right'
              }
            }
          ]]}
        />
        {availableBudgets.map(productType => (
          <BudgetForm user={user} readOnly={readOnly} loading={budgetLoading} onChange={onChange} budget={turnoversByProductType[productType] || { productType }} />
        ))}
        <TotalRow turnovers={turnovers} />
      </Space>
    </div>
  );
};

const mapStateToProps = (state) => {
  const orderShow = state.orderShow;
  return {
    budgetLoading: orderShow.turnoverLoading,
    loading: orderShow.submitting,
    user: state.user,
  }
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (orderTurnover) => dispatch({ type: 'orderShow/saveTurnover', payload: orderTurnover }),
  onOrderSave: (order) => dispatch({ type: 'orderShow/save', payload: order })
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetsForm);