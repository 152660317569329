import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

import { ConfigProvider } from 'antd';
import frFr from "antd/lib/locale-provider/fr_FR";

import AntForm from '@9troisquarts/ant-form.ant-form';
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import moment from "moment";

// eslint-disable-next-line no-unused-vars

import Routes from "./router";
import configureStore, { sagaMiddleware } from "./store";
import verifyCredentials from "./components/authentication/verifyCredentials";
import i18n from "./i18n";
import { ApolloProvider } from "@apollo/client";
import { client } from "./utils/graphql";

AntForm.configure({
  submitText: 'Enregistrer',
})

moment.locale("fr");

const history = createBrowserHistory();

export const store = configureStore(history);

verifyCredentials(store);

const App = props => {  
  return (
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ConfigProvider locale={frFr}>
            <ConnectedRouter history={history}>
              <Suspense fallback={(<div></div>)}>
                <Routes />
              </Suspense>
            </ConnectedRouter>
          </ConfigProvider>
        </Provider>
      </I18nextProvider>
    </ApolloProvider>
  )
};

export default App;
