// @ts-nocheck
import gql from "graphql-tag";
import flashMessagesFragment from "./flashMessagesFragment";
import { paymentFragment } from "./payments";
import { requestMutation, requestQuery } from "../utils/graphql";
import { productInfo } from './products';
import {
  getOrderBroadcastsQuery,
  deleteBroadcastQuery,
  createBroadcastQuery,
  deleteMultipleBroadcastsQuery,
  createMultipleBroadcasts
} from './broadcasts';
import castAttributesFromModel from "../utils/castAttributesFromModel";
import mapFormStateToAttributes from "../utils/forms/mapFormStateToAttributes";

export const paymentDefinition = {
  amount: "Float",
  dueOn: "Date",
  paidOn: "Date",
  receipt: "String",
  notes: "String",
  uniqueReceipt: "Boolean",
  _destroy: "Boolean",
  state: "String",
  id: "Int",
  receipt: "String"
};

const orderDefinition = {
  description: "String",
  trelloUrl: "String",
  totalBroadcasts: "Integer",
  startingOn: 'Date',
  endingOn: 'Date',
  clientCommercialName: 'String',
  commercialContact: 'String',
  commercialContactEmail: 'String',
  commercialContactPhone: 'String',
  userName: 'String',
  creaLastname: 'String',
  creaFirstname: 'String',
  creaEmail: 'String',
  creaPhone: 'String',
  siteCount: 'String',
  siteCities: 'String',
  siteActivities: 'String',
  uniqueSalePlace: 'String',
  briefDescription: 'Text',
  attachments: 'Files',
  file: 'File'
};

const orderTurnoverDefinition = {
  productType: 'String',
  cost: 'Float',
  turnover: 'Float',
};

export const orderInfo = gql`
  fragment OrderInfo on Order {
    id
    code
    title
    errors
    client {
      id
      title
    }
    clientCommercialName
    userName
    creaLastname
    creaFirstname
    creaEmail
    creaPhone
    siteCount
    siteCities
    siteActivities
    uniqueSalePlace
    briefDescription
  }
`;

export const broadcastsInfo = gql`
  fragment BroadcastsInfo on Order {
    state
    productIds
    orderProducts {
      orderId
      comment
      productId
      product {
        ...ProductInfo
      }
    }
    totalBroadcasts
    canBeDeleted
    orderSpots {
      startingOn
    }
  }
  ${productInfo}
`

export const orderTurnover = gql`
  fragment OrderTurnover on OrderTurnover {
    id
    productType
    cost
    turnover
  }
`

export const advancedInfo = gql`
  fragment AdvancedInfo on Order {
    trelloUrl
    state
    advertsCount
    description
    startingOn
    endingOn
    uniqueReceipt
    commercialContact
    commercialContactEmail
    commercialContactPhone
    document {
      id
      url
      filename
      contentType
    }
    files {
      id
      url
      filename
      contentType
    }
    payments {
      ...PaymentInfo
    }
    turnovers {
      ...OrderTurnover
    }
  }
  ${orderTurnover}
  ${paymentFragment}
`;

export const updateOrderQuery = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      errors
      order {
        ...OrderInfo
        ...BroadcastsInfo
        ...AdvancedInfo
      }
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${orderInfo}
  ${broadcastsInfo}
  ${advancedInfo}
  ${flashMessagesFragment}
`;

export const createTrelloCardMutation = gql`
  mutation createTrelloCard($input: CreateTrelloCardInput!) {
    createTrelloCard(input: $input) {
      order {
        ...OrderInfo
        ...BroadcastsInfo
        ...AdvancedInfo
      }
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${orderInfo}
  ${broadcastsInfo}
  ${advancedInfo}
  ${flashMessagesFragment}
`;

export const updateOrderPaymentsQuery = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      errors
      order {
        id
        payments {
          ...PaymentInfo
        }
      }
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${paymentFragment}
  ${flashMessagesFragment}
`;

export const updateBroadcastDateQuery = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      errors
      order {
        startingOn
        endingOn
        totalBroadcasts
      }
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${flashMessagesFragment}
`;

export const updateOrderTurnover = gql`
  mutation updateOrderTurnover($input: UpdateOrderTurnoverInput!) {
    updateOrderTurnover(input: $input) {
      orderTurnover {
        ...OrderTurnover
      }
    }
  }
  ${orderTurnover}
`;

export const updateStateQuery = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      errors
      order {
        state
      }
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${flashMessagesFragment}
`;

export const updateOrdersProducts = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      errors
      order {
        productIds
        orderProducts {
          orderId
          productId
          comment
          product {
            ...ProductInfo
          }
        }
      }
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${productInfo}
  ${flashMessagesFragment}
`;

export const updateOrderProduct = gql`
  mutation updateOrderProduct($input: UpdateOrderProductInput!){
    updateOrderProduct(input: $input) {
      orderProduct {
        id
        comment
        productId
        orderId
        product {
          ...ProductInfo
        }
      }
    }
  }
  ${productInfo}
`;

export const editOrderQuery = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      code
      state
      clientId
      userId
      totalBroadcasts
      trelloUrl
      state
      advertsCount
      description
    }
  }
`;

export const reindexOrder = gql`
  query reindexOrder($id: ID!) {
    reindexOrder(id: $id) {
      id
    }
  }
`;

export const searchOrdersQuery = gql`
  query searchOrders(
    $page: Int
    $perPage: Int
    $search: OrdersSearchAttributes
    $sort: SortAttributes
  ) {
    searchOrders(page: $page, perPage: $perPage, search: $search, sort: $sort) {
      total
      currentPage
      perPage
      orders {
        ...OrderInfo
        productTypes
        paymentsSumIsCoherent
        paymentsState
        errors
        totalBudget
        description
        state
        createdAt
        file {
          url
        }
      }
    }
  }
  ${orderInfo}
`;

export const orderQuery = gql`
  query order($id: ID!) {
    order(id: $id) {
      ...BroadcastsInfo
      ...OrderInfo
      ...AdvancedInfo
    }
  }
  ${orderInfo}
  ${broadcastsInfo}
  ${advancedInfo}
`;

export const deleteOrderQuery = gql`
  mutation deleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      order {
        id
      }
      flashMessages {
        ...FlashMessageInfo
      }
      deleted
    }
  }
  ${flashMessagesFragment}
`;

export const syncOrdersMutation = gql`
  mutation syncOrders($input: SyncOrdersInput!) {
    syncOrders(input: $input) {
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${flashMessagesFragment}
`


export const syncOrderMutation = gql`
  mutation syncOrder($input: SyncOrderInput!) {
    syncOrder(input: $input) {
      order {
        ...OrderInfo
        ...BroadcastsInfo
        ...AdvancedInfo
      }
      flashMessages {
          ...FlashMessageInfo
      }
    }
  }
  ${flashMessagesFragment}
  ${orderInfo}
  ${broadcastsInfo}
  ${advancedInfo}
`


export const downloadPdf = (id) =>
  window.open(`/orders/${id}id/download`);


const get = async function(id) {
  const response = await requestQuery(orderQuery, { id })
  return response.order;
}

const destroy = async function(id) {
  const response = await requestMutation(deleteOrderQuery, {
    input: {
      id
    }
  });
  return response.deleteOrder
}

const sync = async function(id) {
  const response = await requestMutation(syncOrderMutation, { input: { orderId: id } });
  return response.syncOrder;
}

const save = async function(order) {
  const attributes = castAttributesFromModel(orderDefinition, order);
  const response = await requestMutation(updateOrderQuery, { input: { id: order.id, attributes } }, { hasUpload: true });
  return response.updateOrder;
}

const saveTurnover = async function(orderId, turnover) {
  const attributes = castAttributesFromModel(orderTurnoverDefinition, turnover);
  const response = await requestMutation(updateOrderTurnover, {
    input: {
      orderId,
      attributes,
    }
  })
  return response.updateOrderTurnover;
}

const savePayments = async function (orderId, uniqueReceipt, payments) {
  const paymentsAttributes = payments.map(payment => ({
    id: payment.id,
    ...(payment._destroy ? { _destroy: true } : {}),
    uniqueReceipt,
    ...castAttributesFromModel(
      paymentDefinition,
      mapFormStateToAttributes(payment, ["id", "_destroy", "__typename"])
    )
  }));
  const response = await requestMutation(updateOrderPaymentsQuery, {
    input: {
      id: orderId,
      attributes: {
        paymentsAttributes,
        uniqueReceipt
      }
    }
  })
  return response.updateOrder;
}

const saveProducts = async function (orderId, productIds) {
  const response = await requestMutation(updateOrdersProducts, {
    input: {
      id: orderId,
      attributes: {
        productIds
      }
    }
  });
  return response.updateOrder;
}

const getBroadcasts = async function (order) {
  const response = await requestQuery(getOrderBroadcastsQuery, {
    search: {
      orderId: order.id,
      startingOn: order.startingOn,
      endingOn: order.endingOn
    }
  });
  return response.broadcasts || [];
}

const saveProduct = async function(orderId, product) {
  const response = await requestMutation(updateOrderProduct, {
    input: {
      orderId,
      attributes: product,
    }
  })
  return response.updateOrderProduct;
}

const deleteOrderBroadcast = async function(orderId, productId, startingOn) {
  const response = await requestMutation(deleteBroadcastQuery, {
    input: {
      orderId,
      productId,
      startingOn
    }
  });
  return response.deleteBroadcast || {};
}

const createOrderBroadcast = async function(orderId, productId, startingOn, weekOccupancyPercent) {
  const response = await requestMutation(createBroadcastQuery, {
    input: {
      attributes: {
        orderId,
        productId,
        startingOn,
        weekOccupancyPercent
      }
    }
  })
  return response.createBroadcast || {};
}

const reindex = async function(id) {
  const response = await requestQuery(reindexOrder, { id });
  return response;
}

const enableProduct = async function (orderId, productIds, startingOn, endingOn, weekOccupancyPercent = 100) {
  const response = await requestMutation(createMultipleBroadcasts, {
    input: {
      productIds,
      orderId,
      startingOn,
      endingOn,
      weekOccupancyPercent
    }
  });
  return response.createMultipleBroadcasts || {};
}

const disableProduct = async function (orderId, productIds, startingOn, endingOn) {
  const response = await requestMutation(deleteMultipleBroadcastsQuery, {
    input: {
      search : {
        productIds,
        orderId,
        startingOn,
        endingOn
      }
    }
  });
  return response.deleteMultipleBroadcasts || {};
}

const enableWeek = async function (orderId, productIds, startingOn, weekOccupancyPercent = 100) {
  const response = await requestMutation(createMultipleBroadcasts, {
    input: {
      productIds,
      orderId,
      startingOn,
      endingOn: startingOn,
      weekOccupancyPercent,
    }
  })
  return response.createMultipleBroadcasts || {};
}

const disableWeek = async function (orderId, productIds, startingOn) {
  const response = await requestMutation(deleteMultipleBroadcastsQuery, {
    input: {
      search: {
        productIds,
        orderId,
        startingOn,
        endingOn: startingOn
      }
    }
  })
  return response.deleteMultipleBroadcasts || {};
}

const disableAllBroadcasts = async function (orderId, productIds, startingOn, endingOn) {
  const response = await requestMutation(deleteMultipleBroadcastsQuery, {
    input: {
      search: {
        productIds,
        orderId,
        startingOn,
        endingOn,
      }
    }
  })
  return response.deleteMultipleBroadcasts || {};
}

const updateState = async function(orderId, state) {
  const response = await requestMutation(updateStateQuery, {
    input: {
      id: orderId,
      attributes: {
        state
      }
    }
  });
  return response.updateOrder || {};
}

const createTrelloCard = async function (orderId) {
  const response = await requestMutation(createTrelloCardMutation, { input: { orderId } });
  return response.createTrelloCard;
}


export const OrderService = {
  get,
  destroy,
  sync,
  save,
  saveTurnover,
  savePayments,
  saveProducts,
  saveProduct,
  getBroadcasts,
  deleteOrderBroadcast,
  createOrderBroadcast,
  reindex,
  enableProduct,
  disableProduct,
  enableWeek,
  disableWeek,
  disableAllBroadcasts,
  updateState,
  createTrelloCard,
}

class Order {
  constructor(order) {
    this.order = order;
  }

  updatePayments(params) {
    return requestMutation(updateOrderPaymentsQuery, { input: {
      id: this.order.id,
      attributes: params
    }}).then(data => (data.updateOrder ? data.updateOrder : {}));
  }

}

export default Order;
