import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, Row, Col } from "antd";
import {StateDot} from "../../../routes/PaymentsList";
import { useTranslation } from "react-i18next";

const columns = t => [
  {
    title: t("payment.attributes.dueOn"),
    key: "dueOn",
    align: "center",
    render: (text, { dueOn }) => (
      <strong>{moment(dueOn).format("MMM YYYY")}</strong>
    )
  },
  {
    title: t("payment.attributes.amount"),
    key: "amount",
    align: "right",
    render: (text, { amount }) => (
      <span>{amount && `${amount.toLocaleString()}€`}</span>
    )
  },
  {
    title: t("payment.attributes.receipt"),
    key: "receipt",
    dataIndex: "receipt",
    align: "center"
  },
  {
    title: t("payment.attributes.state"),
    key: "state",
    align: "center",
    render: (text, { state, isLate }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{position: 'relative'}}>
          <StateDot state={state} isLate={isLate} offSet={false} />
          {t(`payment.states.${state}`)}
        </div>
      </div>
    )
  },
  {
    title: t("payment.attributes.paidOn"),
    key: "paidOn",
    align: "center",
    render: (text, { paidOn }) => (
      <span>{paidOn ? moment(paidOn).format("DD/MM/YYYY") : ""}</span>
    )
  }
];

const PaymentsList = props => {
  const { payments } = props;
  const [t] = useTranslation();
  return (
    <Row>
      <Col xs={24} md={14} lg={14}>
        <Table
          style={{ marginTop: 20 }}
          dataSource={payments}
          bordered={false}
          pagination={false}
          columns={columns(t)}
          rowKey="id"
        />
      </Col>
    </Row>
  );
};

PaymentsList.defaultProps = {
  payments: []
};

PaymentsList.propTypes = {
  t: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({}))
};

export default PaymentsList;
