import { combineReducers } from "redux";
import { createImmerReducer } from "../utils/createReducer";
import uiPaymentsReducer from "./uis/payments";
import uiOccupancyRatesReducer from "./uis/occupancyRates";
import uiProgramReducer from "./uis/program";
import uiReportingReducer from "./uis/reporting";
import uiBroadcastsChecklist from "./uis/broadcastsChecklist";

const initialState = {
  loading: false,
  submitting: false,
  current: undefined,
};

const setErrors = (state, { payload }) => {
  state.errors = payload;
  return state;
};

const setLoading = (state, { payload }) => {
  state.loading = payload;
  return state;
};

const setCurrent = (state, { payload }) => {
  state.loading = false;
  state.submitting = false;
  state.current = payload;
  return state;
};

const setSubmitting = state => {
  state.submitting = true;
  return state;
}

const uiClientFormReducer = createImmerReducer(initialState,
  {
    "clientForm/setErrors": setErrors,
    "clientForm/setLoading": setLoading,
    "clientForm/setCurrent": setCurrent,
    "clientForm/submit": setSubmitting,
    "clientForm/reset": () => initialState,
  }
);

const uiProductFormReducer = createImmerReducer(initialState,
  {
    "productForm/setErrors": setErrors,
    "clientForm/setLoading": setLoading,
    "productForm/setCurrent": setCurrent,
    "productForm/submit": setSubmitting,
    "productForm/reset": () => initialState,
  }
);

export default combineReducers({
  clientForm: uiClientFormReducer,
  productForm: uiProductFormReducer,
  payments: uiPaymentsReducer,
  occupancyRates: uiOccupancyRatesReducer,
  program: uiProgramReducer,
  reporting: uiReportingReducer,
  broadcastsChecklist: uiBroadcastsChecklist
});
