import { Map, Set } from "immutable";
import moment from "moment";
import createReducer from "../../utils/createReducer";
import { setLoading, setFilters, setDates, setCategories } from "./utils";

export const UI_OCCUPANCY_RATES_SET_LOADING = "ui/occupancyRates/setLoading";
export const UI_OCCUPANCY_RATES_SET_CATEGORIES = "ui/occupancyRates/setCategories";
export const UI_OCCUPANCY_RATES_SET_DATES = "ui/occupancyRates/setDates";
export const UI_OCCUPANCY_RATES_SET_FILTERS = "ui/occupancyRates/setFilters";

export const initialState = Map({
  startingOn: moment()
    .startOf("week")
    .format("YYYY-MM-DD"),
  endingOn: moment()
    .startOf("week")
    .add(8, "weeks")
    .format("YYYY-MM-DD"),
  categories: Set([]),
  loading: false,
  filters: Map({})
});

const uiOccupancyRatesReducer = createReducer(initialState, {
  [UI_OCCUPANCY_RATES_SET_LOADING]: setLoading,
  [UI_OCCUPANCY_RATES_SET_CATEGORIES]: setCategories,
  [UI_OCCUPANCY_RATES_SET_DATES]: setDates,
  [UI_OCCUPANCY_RATES_SET_FILTERS]: setFilters
});

export default uiOccupancyRatesReducer;
