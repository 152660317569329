import React from "react";
import moment from "moment";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from "antd";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { compose, withHandlers, onlyUpdateForKeys } from "recompose";

import styles from "./index.sass";

const startOfWeek = moment().startOf("week");

const WeekLabel = ({ startingOn, t }) => {
  let label = null;
  const startingOnWeek = startingOn.startOf("week");
  if (startOfWeek.isSame(startingOnWeek)) label = `${t("thisWeek")}`;
  else if (
    startOfWeek
      .clone()
      .subtract(1, "week")
      .isSame(startingOnWeek)
  )
    label = `${t("previousWeek")}`;
  else if (
    startOfWeek
      .clone()
      .add(1, "week")
      .isSame(startingOnWeek)
  )
    label = `${t("nextWeek")}`;
  if (label) {
    return (
      <strong>
        {label}
        : &nbsp;
      </strong>
    );
  }
  return label;
};

WeekLabel.propTypes = {
  startingOn: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};

const WeekNavigation = props => {
  const { className, onPrevious, onNext, t, date } = props;
  const mStartingOn = moment(date);
  return (
    <div className={`${className} ${styles.container}`}>
      <Button
        onClick={onPrevious}
        className={styles.prevButton}
        icon={<LeftOutlined />}
        shape="circle"
      />
      <WeekLabel startingOn={mStartingOn} t={t} />
      <span>
        {t("dateFrom")}
        &nbsp;
        {mStartingOn.format("L")}
        &nbsp;
        {t("dateTo")}
        &nbsp;
        {mStartingOn.endOf("week").format("L")}
      </span>
      <Button
        onClick={onNext}
        icon={<RightOutlined />}
        shape="circle"
        className={styles.nextButton}
      />
    </div>
  );
};

WeekNavigation.defaultProps = {
  onPrevious: () => undefined,
  onNext: () => undefined,
  className: ""
};

WeekNavigation.propTypes = {
  className: PropTypes.string,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  t: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired
};

const handlePrevious = ({ onChange, date }) => () => {
  onChange(
    moment(date)
      .subtract(1, "week")
      .startOf("week")
  );
};

const handleNext = ({ onChange, date }) => () => {
  onChange(
    moment(date)
      .add(1, "week")
      .startOf("week")
  );
};

export default compose(
  withTranslation(),
  onlyUpdateForKeys(["date", "tReady"]),
  withHandlers({
    onPrevious: handlePrevious,
    onNext: handleNext
  })
)(WeekNavigation);
