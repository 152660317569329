import { Map, Set, List } from "immutable";
import moment from "moment";
import reduce from "lodash/reduce";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import { denormalize } from "normalizr";
import { createSelector } from "reselect";
import createReducer from "../../utils/createReducer";

import { setLoading, setCategories } from "./utils";
import Schemas from "../../schema";

export const initialState = Map({
  view: "product",
  activeKey: null,
  list: List([]),
  week: moment()
    .startOf("week")
    .format("YYYY-MM-DD"),
  categories: Set([]),
  loading: false
});

export const UI_BROADCASTS_CHECKLIST_SET_LOADING =
  "ui/broadcastsChecklist/setLoading";
export const UI_BROADCASTS_CHECKLIST_SET_CATEGORIES =
  "ui/broadcastsChecklist/setCategories";
export const UI_BROADCASTS_CHECKLIST_SET_WEEK =
  "ui/broadcastsChecklist/setWeek";
export const UI_BROADCASTS_CHECKLIST_SET_ACTIVE_KEY =
  "ui/broadcastsChecklist/setActiveKey";
export const UI_BROADCASTS_CHECKLIST_SET_LIST =
  "ui/broadcastsChecklist/setList";
export const UI_BROADCASTS_CHECKLIST_SET_VIEW =
  "UI_BROADCASTS_CHECKLIST_SET_VIEW";

const setWeek = (state, { payload }) => state.set("week", payload);
const setList = (state, { payload }) => state.set("list", List(payload));
const setActiveKey = (state, { payload }) => state.set("activeKey", payload);
const setView = (state, { payload }) => state.set("view", payload);

const uiBroadcastsChecklist = createReducer(initialState, {
  [UI_BROADCASTS_CHECKLIST_SET_LOADING]: setLoading,
  [UI_BROADCASTS_CHECKLIST_SET_CATEGORIES]: setCategories,
  [UI_BROADCASTS_CHECKLIST_SET_WEEK]: setWeek,
  [UI_BROADCASTS_CHECKLIST_SET_LIST]: setList,
  [UI_BROADCASTS_CHECKLIST_SET_ACTIVE_KEY]: setActiveKey,
  [UI_BROADCASTS_CHECKLIST_SET_VIEW]: setView
});

const denormalized = createSelector(
  state => state.ui.broadcastsChecklist.get("list"),
  state => state.entities,
  (list, entities) =>
    denormalize(list.toJS(), Schemas.BROADCASTS, entities.toJS())
);

const groupByView = createSelector(
  denormalized,
  state => state.ui.broadcastsChecklist.get("view"),
  (broadcasts, view) =>
    reduce(
      broadcasts,
      (result, value) => {
        const r = { ...result };
        // eslint-disable-next-line no-param-reassign
        (r[get(value, view).id] || (r[get(value, view).id] = [])).push(value);
        return r;
      },
      {}
    )
);

const groups = createSelector(
  denormalized,
  state => state.ui.broadcastsChecklist.get("view"),
  (broadcasts, view) =>
    sortBy(
      uniqBy(
        broadcasts.map(s => ({
          id: get(s, view).id,
          title: get(s, view).title
        })),
        "id"
      ),
      "title"
    )
);
export const orm = {
  groupByView,
  groups
};

export default uiBroadcastsChecklist;
