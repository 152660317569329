import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Checkbox } from "antd";

import styles from "./index.sass";

const SpotCheckbox = ({ value, onChange, disabled, checked }) => {
  const date = moment(value);
  return (
    <div className={styles.checkbox}>
      <Checkbox
        onChange={
          disabled ? () => {} : ({ target }) => onChange(value, target.checked)
        }
        disabled={disabled}
        checked={checked && !disabled}
      >
        S
        {date.week()}
        &nbsp;
        <span className={styles.checkboxDate}>{date.format("DD/MM")}</span>
      </Checkbox>
    </div>
  );
};

SpotCheckbox.defaultProps = {
  checked: false,
  disabled: false
};

SpotCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SpotCheckbox;
