import sortBy from "lodash/sortBy";
import reduce from "lodash/reduce";
import isNil from "lodash/isNil";

const groupDataByCategory = data =>
  reduce(
    data,
    (result, value) => {
      if (!value?.product) return null;
      const {
        intervals,
        product: { category }
      } = value;
      const r = { [category]: {}, ...result };
      intervals.forEach(({ startingOn, price }) => {
        if (isNil(r[category][startingOn])) r[category][startingOn] = 0;
        r[category][startingOn] += price;
      });
      return r;
    },
    {}
  );

const getReportData = report => {
  const groupedData = groupDataByCategory(report.data);
  if (!groupedData) return [];
  let data = reduce(groupedData, (result, value, key) => {
    const product = report.data?.find(p => p.product?.category === key);
    result.push({
      title: key,
      productType: product?.product?.productType || "",
      intervals: value
    });
    return result;
  }, []);
  data = sortBy(data, "title");
  if (data && data.length > 0) {
    const intervals = Object.keys(data[0].intervals);
    if (intervals && intervals.length > 0) {
      data.push({
        id: "total",
        intervals: intervals.reduce((result, value) => {
          const r = { intervals: {}, ...result };
          let sum = 0;
          data.forEach(category => {
            if (category.intervals && category.intervals[value])
              sum += category.intervals[value];
          });
          r[value] = sum;
          return r;
        }, {})
      });
    }
  }
  return {
    data
  };
};

export default getReportData;
