import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import styles from "./index.sass";
import googleSvg from "../../assets/images/google.svg";

const authUrl = "/auth/google_oauth2";

const redirectToGoogle = ({ redirectUri }) => {
  window.location.href = `${authUrl}?origin=${redirectUri}`;
};

const GoogleLoginButton = props => {
  const { text } = props;
  return (
    <Button
      type="primary"
      onClick={() => redirectToGoogle(props)}
      className={styles.button}
    >
      <span className={styles.logoContainer}>
        <img src={googleSvg} alt="logo-google" />
      </span>
      {text}
    </Button>
  );
};

GoogleLoginButton.defaultProps = {
  text: "Se connecter avec Google"
};

GoogleLoginButton.propTypes = {
  text: PropTypes.string
};

export default GoogleLoginButton;
