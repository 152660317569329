import _ from "lodash";
import { store } from "../../App";
import { updateAuth } from "../../components/authentication/actions/auth";
import { setAuthInLS } from '../getAuthFromLS';

/**
 * Get the authentication variables in response headers
 * @param response - the ajax response
 * @returns {{}} - return the object with authentication variables
 */
const getAuthHeaders = response => {
  const headers = {};
  const headersKey = ["uid", "client", "access-token", "expiry"];
  _.forEach(headersKey, key => {
    if (response.headers[key]) headers[key] = response.headers[key];
  });
  return headers;
};

/**
 * Get the authentication informations in response headers and set them in localeStorage/Redux store
 * @param response - the ajax response
 */
export const updateAuthenticationData = response => {
  const { dispatch } = store;
  const authenticationData = getAuthHeaders(response);
  if (!_.isEmpty(authenticationData)) {
    setAuthInLS(authenticationData);
    dispatch(updateAuth(authenticationData));
  }
};

/**
 * Update the localeStorage/Redux store with authentication informations
 * @param response
 * @return return the json data of the response
 */
const successHandler = response => {
  updateAuthenticationData(response);
  return response;
};

export default successHandler;