import React from "react";
import { Card } from "antd";
import { sortBy } from 'lodash';
import Report from "./Report";
import moment from 'moment';
import getReportData from "./getReportData";
import { useQuery } from "@apollo/client";
import columns from "./columns";
import styles from "../index.sass";
import { useTranslation } from "react-i18next";
import getReportQuery from './reportQuery';

const params = {
  startingOn: moment().subtract(1, 'year')
    .startOf("year")
    .format("YYYY-MM-DD"),
  endingOn: moment()
    .add(1, "year")
    .endOf("year")
    .format("YYYY-MM-DD"),
  interval: "year",
  groupBy: "product",
  pagination: false,
  priceType: "margin"
};

const ReportingWidget = () => {
  const [t] = useTranslation();
  const { data, loading } = useQuery(getReportQuery, { variables: params })
  const report = data ? getReportData(data.broadcastsReport) : {};
  return (
    <Card loading={loading} className={styles.card}>
      <h2>{t("dashboard.yearlyReport")}</h2>
      <Report {...report} data={sortBy(report.data || [], 'productType')} columns={columns(t, "year", "YYYY", { showProductType: true })} />
    </Card>
  );
};

export default ReportingWidget;