import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";

import { isAuthenticated } from "./isAuthenticated";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  redirectRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectRoute,
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

PrivateRoute.defaultProps = {
  isAuthenticated: false,
  redirectRoute: "/user/login"
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  redirectRoute: PropTypes.string,
  location: PropTypes.shape({}).isRequired
};

const mapStateToProps = ({ auth }, ownProps) => ({
  isAuthenticated: isAuthenticated(auth),
  ...ownProps
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
