import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const { Option } = Select;

const userOptions = users =>
  users.map(user => (
    <Option key={`user-option-${user.id}`} value={user.id}>
      {user.name}
    </Option>
  ));

const filterOption = (input, option) =>
  option.props.children.toLowerCase().startsWith(input.toLowerCase());

// eslint-disable-next-line react/prefer-stateless-function
class UserSelect extends React.Component {
  render() {
    const { users, ...rest } = this.props;
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        {...rest}
      >
        {userOptions(users)}
      </Select>
    );
  }
}

UserSelect.defaultProps = {
  users: []
};

UserSelect.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}))
};

export default UserSelect;
