import { fromJS, Map, OrderedSet } from "immutable";
import moment from "moment";
import createReducer from "../../utils/createReducer";
import { setLoading, setFilters, setDates, setPagination, setSort } from "./utils";
import Payment from "../../services/payments";

export const UI_PAYMENTS_SET_LOADING = "ui/payments/setLoading";
export const UI_PAYMENTS_SET_DATES = "ui/payments/setDates";
export const UI_PAYMENTS_SET_FILTERS = "ui/payments/setFilters";
export const UI_PAYMENTS_SET_PAGINATION = "ui/payments/setPagination";
export const UI_PAYMENTS_SET_LIST = "ui/payments/setList";
export const UI_PAYMENTS_SET_STATE = "ui/payments/setState";
export const UI_PAYMENTS_SET_CURRENT = "ui/payments/setCurrent";
export const UI_PAYMENTS_RESET = "ui/payments/reset";
export const UI_PAYMENTS_SET_STATE_FILTER = "ui/payments/setStateFilter";
export const UI_PAYMENTS_SET_SORT = "ui/payments/setSort";

export const exportPayments = () => (dispatch, getState) => {
  const {
    ui: { payments }
  } = getState();
  Payment.export(payments.toJS());
};

export const exportPaymentsList = () => (dispatch, getState) => {
  const {
    ui: { payments }
  } = getState();
  Payment.export(payments.toJS());
};

export const initialState = Map({
  startingOn: moment()
    .startOf("month")
    .format("YYYY-MM-DD"),
  endingOn: moment()
    .startOf("week")
    // .add(6, "months")
    .endOf("month")
    .format("YYYY-MM-DD"),
  loading: false,
  filters: Map({}),
  stateFilter: "all",
  sort: {
    key: 'clients.title',
    order: 'asc',
  },
  pagination: Map({
    currentPage: 1,
    pageSize: 30,
    total: 0
  }),
  list: OrderedSet([]),
  payments: OrderedSet([]),
  current: Map({})
});

const setStateFilter = (state, { payload }) =>
  state.set("stateFilter", payload);
const setList = (state, { payload }) =>
  state.set("list", OrderedSet(payload)).set("loading", false);
const setState = (state, { payload }) => state.mergeDeep(fromJS(payload));
const setCurrent = (state, { payload }) => state.set("current", Map(payload));
const resetState = () => initialState;

const uiPaymentsReducer = createReducer(initialState, {
  [UI_PAYMENTS_SET_LOADING]: setLoading,
  [UI_PAYMENTS_SET_STATE_FILTER]: setStateFilter,
  [UI_PAYMENTS_SET_DATES]: setDates,
  [UI_PAYMENTS_SET_FILTERS]: setFilters,
  [UI_PAYMENTS_SET_PAGINATION]: setPagination,
  [UI_PAYMENTS_SET_STATE]: setState,
  [UI_PAYMENTS_SET_LIST]: setList,
  [UI_PAYMENTS_SET_CURRENT]: setCurrent,
  [UI_PAYMENTS_SET_SORT]: setSort,
  [UI_PAYMENTS_RESET]: resetState,
});

export default uiPaymentsReducer;
