import { Map, Set } from "immutable";
import moment from "moment";
import createReducer from "../../utils/createReducer";
import {
  setLoading,
  setFilters,
  setCategories,
  setDates,
  setPagination,
  setCurrentPage,
  setCommercial
} from "./utils";

export const initialState = Map({
  startingOn: moment()
    .startOf("week")
    .format("YYYY-MM-DD"),
  endingOn: moment()
    .add(8, "weeks")
    .startOf("week")
    .format("YYYY-MM-DD"),
  categories: Set([]),
  loading: false,
  filters: Map({}),
  groupBy: "client",
  priceType: "turnover",
  total: 0,
  currentPage: 1,
  pageSize: 30
});

export const UI_REPORTING_SET_LOADING = "ui/reporting/setLoading";
export const UI_REPORTING_SET_CATEGORIES = "ui/reporting/setCategories";
export const UI_REPORTING_SET_COMMERCIAL = "ui/reporting/setCommercial";
export const UI_REPORTING_SET_DATES = "ui/reporting/setDates";
export const UI_REPORTING_SET_GROUP_BY = "ui/reporting/setGroupBy";
export const UI_REPORTING_SET_PRICE_TYPE = "ui/reporting/setPriceType";
export const SET_FILTERS = "ui/reporting/setFilters";
export const SET_PAGINATION = "ui/reporting/setPagination";
export const SET_CURRENT_PAGE = "ui/reporting/setCurrentPage";

const setGroupBy = (state, { payload }) => state.set("groupBy", payload);
const setPriceType = (state, { payload }) => state.set("priceType", payload);

const uiReportingReducer = createReducer(initialState, {
  [UI_REPORTING_SET_LOADING]: setLoading,
  [UI_REPORTING_SET_CATEGORIES]: setCategories,
  [UI_REPORTING_SET_COMMERCIAL]: setCommercial,
  [UI_REPORTING_SET_DATES]: setDates,
  [SET_FILTERS]: setFilters,
  [SET_PAGINATION]: setPagination,
  [SET_CURRENT_PAGE]: setCurrentPage,
  [UI_REPORTING_SET_PRICE_TYPE]: setPriceType,
  [UI_REPORTING_SET_GROUP_BY]: setGroupBy
});

export default uiReportingReducer;
