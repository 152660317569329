import React from "react";
import PropTypes from "prop-types";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, DatePicker } from "antd";
import { compose, pure, withHandlers } from "recompose";
import moment from "moment";

import styles from "./index.scss";
import fieldErrorsToItemProps from "../../utils/forms/fieldsErrorsToItemProps";
import handleSubmit from "../../utils/forms/handleSubmit";
import { useTranslation } from "react-i18next";

const afterBeginningOfWeek = current =>
  false &&
  current <
  moment()
    .startOf("week")
    .subtract(1, "day");

const enableMonday = current =>
  current && (afterBeginningOfWeek(current) || current.day() !== 1);

const enableSunday = current =>
  current && (afterBeginningOfWeek(current) || current.day() !== 0);

const formConfig = {
  mapPropsToFields(props) {
    const { value } = props;
    const { startingOn, endingOn } = value;
    return {
      startingOn: Form.createFormField({
        value: startingOn ? moment(startingOn) : null
      }),
      endingOn: Form.createFormField({
        value: endingOn ? moment(endingOn) : null
      })
    };
  }
};

const BroadcastDateForm = props => {
  const { form, handleSubmit, submitting } = props;
  const [t] = useTranslation();
  const { getFieldDecorator, getFieldError, getFieldValue } = form;
  const endingOn = getFieldValue("endingOn");
  const startingOn = getFieldValue("startingOn");
  return (
    <Form onSubmit={handleSubmit} className={styles.broadcastFormContainer}>
      <Row gutter={16} type="flex">
        <Col xs={24} sm={6}>
          <Form.Item
            colon={false}
            label={t("order.startingOn.label")}
            className={styles.formItem}
            {...fieldErrorsToItemProps(getFieldError("startingOn"))}
          >
            {getFieldDecorator("startingOn", {
              rules: [
                { required: true, message: t("errors.required") },
                {
                  message: t("errors.cantBeAfterEnd"),
                  validator: (rule, value, cb) => {
                    const endOn = getFieldValue("endingOn");
                    if (value && endOn) {
                      return value.isBefore(endOn) ? cb() : cb(true);
                    }
                    return cb();
                  }
                }
              ]
            })(
              <DatePicker
                format="DD/MM/YYYY"
                disabledDate={enableMonday}
                style={{ width: "100%" }}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item
            colon={false}
            label={t("order.endingOn.label")}
            className={styles.formItem}
            {...fieldErrorsToItemProps(getFieldError("endingOn"))}
          >
            {getFieldDecorator("endingOn", {
              rules: [
                { required: true, message: t("errors.required") },
                {
                  message: t("errors.cantBeBeforeStart"),
                  validator: (rule, value, cb) => {
                    const startOn = getFieldValue("startingOn");
                    if (value && startOn) {
                      return value.isAfter(startOn) ? cb() : cb(true);
                    }
                    return cb();
                  }
                }
              ]
            })(
              <DatePicker
                format="DD/MM/YYYY"
                disabledDate={enableSunday}
                style={{ width: "100%" }}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} sm={5}>
          <Form.Item className={styles.submit}>
            <Button type="primary" htmlType="submit" loading={submitting}>
              {t("orderShow.submitBroadcastDate")}
            </Button>
          </Form.Item>
          {endingOn &&
            startingOn && (
              <strong>
                {t("orderShow.numberOfWeeks", {
                  count: moment(endingOn)
                    .add(1, "day")
                    .diff(startingOn, "weeks")
                })}
              </strong>
            )}
        </Col>
      </Row>
    </Form>
  );
};

BroadcastDateForm.defaultProps = {
  submitting: false
};

BroadcastDateForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

export default Form.create(formConfig)(
  compose(
    pure,
    withHandlers({
      handleSubmit
    })
  )(BroadcastDateForm)
);
