import gql from "graphql-tag";

export const searchProgramsQuery = gql`
  query searchProductsPrograms(
    $startingOn: String!
    $endingOn: String!
    $search: SearchProductsProgramsAttributes
    $page: Int
    $perPage: Int
  ) {
    searchProductsPrograms(
      startingOn: $startingOn
      endingOn: $endingOn
      search: $search
      page: $page
      perPage: $perPage
    ) {
      currentPage
      total
      perPage
      productPrograms {
        productId
        productTitle
        clientTitle
        orderId
        orderCode
        orderClientCommercialName
        orderState
        orderTitle
        comment
        price
        broadcastsStartingOn
        broadcastsCompleteWeek
      }
    }
  }
`;

export default {
  searchProgramsQuery
};
