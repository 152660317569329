// @ts-nocheck
import { createImmerReducer } from "../utils/createReducer";
import { OrderService } from "../services/orders";
import { PaymentService } from '../services/payments';
import { replace } from 'connected-react-router';
import { reduce, remove } from 'lodash';
import { call, put, all, takeLatest, select } from 'redux-saga/effects'
import { notification } from 'antd';
import moment from 'moment';
import AttachmentService from "../services/attachments";

export type OrderShowStateType = {
	current: any;
	loading: boolean;
	editing: boolean;
	turnoverLoading: boolean;
	paymentsEdit: boolean;
	productSelector: {
		visible: boolean,
		submitting: boolean,
	},
	broadcastSelector: {
		year: moment.Moment,
		loading: boolean,
		editing: boolean,
		orderProductSubmitting: boolean;
	},
}

const initialState: OrderShowStateType = {
	current: undefined,
	turnoverLoading: false,
	editing: false,
	loading: false,
	paymentsEdit: false,
	broadcastSelector: {
		year: moment().year(),
		loading: false,
		editing: false,
		orderProductSubmitting: false,
	},
	productSelector: {
		visible: false,
		submitting: false
	},
};

function* getOrderBroadcasts() {
	const order = yield select(state => state.orderShow.current);
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: true })

	const broadcasts = yield call(OrderService.getBroadcasts, order)
	yield put({ type: 'orderShow/setBroadcasts', payload: broadcasts });
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: false })
}

function* fetchOrder({ payload }) {
	yield put({ type: 'orderShow/setLoading', payload: true });
  yield put({ type: 'orderShow/setEditing', payload: false });
	try {
		const order = yield call(OrderService.get, payload );
		yield put({ type: "orderShow/setCurrent", payload: order });
		yield put({ type: "orderShow/getOrderBroadcasts" });
	} catch (e) {
		console.error(e)
		yield put(replace("/404"));
	}
	yield put({ type: 'orderShow/setLoading', payload: false })
}

function* deleteOrder({ payload }) {
	try {
		const response = yield  call(OrderService.destroy, payload);
		if (response.deleted) yield put(replace('/orders'))
	} catch(e) {
		console.error(e);
	}
}

function* syncOrder() {
	yield put({ type: 'orderShow/setLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(OrderService.sync, order.id);
		if (response.order) yield put({ type: "orderShow/setCurrent", payload: order });
	} catch {
		console.error(e)
	}
	yield put({ type: 'orderShow/setLoading', payload: false })
}

function* saveOrder({ payload: nextOrder }){
	yield put({ type: 'orderShow/setSubmitting', payload: true })
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(OrderService.save, { id: order.id, ...nextOrder });
		if (response.order) yield put({ type: "orderShow/setCurrent", payload: response.order });
	} catch(e) {
		console.error(e);
	}
	yield put({ type: 'orderShow/setSubmitting', payload: false })
}

function* saveOrderTurnover({ payload: orderTurnover }) {
	yield put({ type: 'orderShow/setTurnoverLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(OrderService.saveTurnover, order.id, orderTurnover)
		if(response.orderTurnover) yield put({ type: 'orderShow/changeTurnover', payload: response.orderTurnover })
	} catch(e) {
		console.error(e)
	}
	yield put({ type: 'orderShow/setTurnoverLoading', payload: false })
}

function* savePayments({payload: { payments, uniqueReceipt }}){
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(OrderService.savePayments, order.id, !!uniqueReceipt, payments);
		if (response?.order?.payments) {
			yield put({ type: 'orderShow/updatePayments', payload: response.order.payments});
		}
	} catch(e) {
		console.error(e)
	}
}

function* saveOrderProducts({ payload }) {
	yield put({ type: 'orderShow/productSelector/setSubmitting', payload: true });
	const order = yield select(state => state.orderShow.current);
	const response = yield call(OrderService.saveProducts, order.id, payload);
	const { errors } = response;
	if (!errors) {
		yield put({ type: 'orderShow/productSelector/setVisibility', payload: false });
		yield put({ type: "orderShow/setProducts", payload: response.order });
	}
	yield put({ type: 'orderShow/productSelector/setSubmitting', payload: false });
};

function* saveOrderProduct({ payload }) {
	yield put({ type: 'orderShow/broadcastSelector/setOrderProductSubmitting', payload: true })
	const order = yield select(state => state.orderShow.current);
	const response = yield call(OrderService.saveProduct, order.id, payload );
	if (!response.errors) {
		yield put({ type: 'orderShow/updateProduct', payload: response.orderProduct })
	}
	yield put({ type: 'orderShow/broadcastSelector/setOrderProductSubmitting', payload: false })
}

function* toggleOrderBroadcast({ payload }) {
	const { productId, date, weekOccupancyPercent } = payload;
	const order = yield select(state => state.orderShow.current);
	if (weekOccupancyPercent) {
		const response = yield call(OrderService.createOrderBroadcast, order.id, productId, date, weekOccupancyPercent);
		if (!response.errors) {
			yield put({ type: 'orderShow/addBroadcast', productId, payload: { startingOn: date, weekOccupancyPercent: weekOccupancyPercent, completeWeek: weekOccupancyPercent === 0 || weekOccupancyPercent === 100 } });
		}
		if (response.occupancyRate) {
			yield put({
				type: "products/updateProductOccupancy",
				payload: { productId, startingOn: date, occupancyRate: response.occupancyRate }
			});
		}
	} else {
		const response = yield call(OrderService.deleteOrderBroadcast, order.id, productId, date);
		if(response.deleted) {
			yield put({ type: 'orderShow/removeBroadcast', productId, payload })
		}
		if (response.occupancyRate) {
			yield put({
				type: "products/updateProductOccupancy",
				payload: { productId, startingOn: date, occupancyRate: response.occupancyRate }
			});
		}
	}
}

function* saveBroadcasts(){
	const order = yield select(state => state.orderShow.current);
	yield call(OrderService.reindex, order.id);
	notification['success']({
		message: 'Le planning a bien été mis à jour',
		placement: 'bottomRight',
		duration: 2,
	})
};

function* enableProduct({ payload: productId, weekOccupancyPercent = 100 }) {
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	const { endingOn, id } = order;
	try {
		const response = yield call(OrderService.enableProduct, id, [productId], moment().format("YYYY-MM-DD"), endingOn, weekOccupancyPercent);
		if (response.broadcasts?.length)
			yield put({ type: 'orderShow/concatBroadbasts', payload: { broadcasts: response.broadcasts, productId } });
		yield put({
			type: "products/fetchOccupancy",
			payload: {
				productIds: [productId],
				startingOn: moment().format("YYYY-MM-DD"),
				endingOn: endingOn
			}
		});
	} catch (e) {
		console.error(e);
	}
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: false })
};

function* disableProduct({ payload: productId }) {
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	const startingOn = moment().startOf("week").format("YYYY-MM-DD");

	try {
		const response = yield call(OrderService.disableProduct, order.id, [productId], startingOn, order.endingOn);
		yield put({ type: 'orderShow/removeBroadcastsAfterDate', payload: { startingOn, productId } });
		yield put({
			type: "products/fetchOccupancy",
			payload: {
				productIds: [productId],
				startingOn: moment().format("YYYY-MM-DD"),
				endingOn: order.endingOn
			}
		});
	} catch (e) {
		console.error(e);
	}

	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: false })
};

function* enableWeek({ payload: startingOn, weekOccupancyPercent = 100 }) {
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	const { id, productIds } = order;
	try {
		const response = yield call(OrderService.enableWeek, id, productIds, startingOn, weekOccupancyPercent);
		if(response.broadcasts) {
			const broadcastsByProducts = reduce(
				response.broadcasts,
				reduceBroadcastsByProductId,
				{}
			);
			const prdIds = Object.keys(broadcastsByProducts)
			for (let i = 0; i < prdIds.length; i++) {
				yield put({ type: 'orderShow/concatBroadbasts', payload: { broadcasts: broadcastsByProducts[prdIds[i]], productId: prdIds[i] } });
			}
			yield put({
				type: "products/fetchOccupancy",
				payload: {
					productIds: Object.keys(broadcastsByProducts),
					startingOn,
					endingOn: startingOn
				}
			});
		}
	} catch(e) {
		console.error(e)
	}
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: false })
}

function* disableWeek({ payload: startingOn }) {
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	const { id, productIds } = order;
	try {
		const response = yield call(OrderService.disableWeek, id, productIds, startingOn);
		if (response.broadcasts) {
			for (let i = 0; i < productIds.length; i++) {
				yield put({ type: 'orderShow/removeBroadcast', productId: productIds[i], payload: { date: startingOn } });
			}
			yield put({
				type: "products/fetchOccupancy",
				payload: {
					productIds: productIds,
					startingOn,
					endingOn: startingOn
				}
			});
		}
	} catch (e) {
		console.error(e)
	}
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: false })
}

function* disableAllBroadcasts() {
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: true })
	const order = yield select(state => state.orderShow.current);
	const { startingOn, endingOn, productIds, id } = order;
	try {
		yield call(OrderService.disableAllBroadcasts, id, productIds, startingOn, endingOn);
		for(let i = 0; i < productIds.length; i++) {
			yield put({ type: 'orderShow/removeBroadcastsAfterDate', payload: { startingOn, productId: productIds[i] } });
		}
	} catch(e) {
		console.error(e)
	}
	yield put({
		type: "products/fetchOccupancy",
		payload: {
			productIds,
			startingOn,
			endingOn
		}
	});
	yield put({ type: 'orderShow/broadcastSelector/setLoading', payload: false })
}

function* updateState({ payload }) {
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(OrderService.updateState, order.id, payload);
		if(!response.errors) {
			yield put({ type: 'orderShow/changeOrder', payload: response.order })
		}
	} catch(e) {
		console.error(e);
	}
}

function* createPayments({ payload: { number, beginningDate } }) {
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(PaymentService.createMultiple, order.id, number, beginningDate);
		if(response.order) {
			yield put({ type: 'orderShow/setPayments', payload: response.order.payments });
		}
	} catch(e) {
		console.error(e);
	}
}

function* createTrelloCard() {
	const order = yield select(state => state.orderShow.current);
	try {
		const response = yield call(OrderService.createTrelloCard, order.id);
		if(response.order)
			yield put({ type: "orderShow/setCurrent", payload: response.order });
	} catch(e) {
		console.error(e)
	}
}

function* deleteAttachment({ payload }) {
  try {
    const response = yield call(AttachmentService.destroy, payload);
    if(!response.persisted) yield put({ type: 'orderShow/removeAttachment', payload })
  } catch(e) {
    console.error(e);
  }
}

export function* orderSaga() {
	yield all([
		takeLatest("orderShow/fetchOrder", fetchOrder),
		takeLatest("orderShow/deleteOrder", deleteOrder),
		takeLatest("orderShow/sync", syncOrder),
		takeLatest('orderShow/save', saveOrder),
		takeLatest('orderShow/saveTurnover', saveOrderTurnover),
		takeLatest('orderShow/savePayments', savePayments),
		takeLatest('orderShow/saveProducts', saveOrderProducts),
		takeLatest('orderShow/getOrderBroadcasts', getOrderBroadcasts),
		takeLatest('orderShow/saveOrderProduct', saveOrderProduct),
		takeLatest('orderShow/toggleOrderBroadcast', toggleOrderBroadcast),
		takeLatest('orderShow/saveBroadcasts', saveBroadcasts),
		takeLatest('orderShow/selectAllProduct', enableProduct),
		takeLatest('orderShow/unselectAllProduct', disableProduct),
		takeLatest('orderShow/enableWeek', enableWeek),
		takeLatest('orderShow/disableWeek', disableWeek),
		takeLatest('orderShow/disableAllBroadcasts', disableAllBroadcasts),
		takeLatest('orderShow/updateState', updateState),
		takeLatest('orderShow/createPayments', createPayments),
    takeLatest('orderShow/deleteAttachment', deleteAttachment),
		takeLatest('orderShow/createTrelloCard', createTrelloCard),
	])
}

const reduceBroadcastsByProductId = (acc, { productId, startingOn, completeWeek, weekOccupancyPercent }) => {
	(acc[productId] || (acc[productId] = [])).push({ startingOn, completeWeek: completeWeek, weekOccupancyPercent  });
	return acc;
};

const setOrderBroadcasts = (state: OrderShowStateType, { payload }) => {
	const order = state.current;
	order.broadcastsByProduct = reduce(payload, reduceBroadcastsByProductId, {});
	state.order = order;
	return state;
}

export const changeTurnover = (state: OrderShowStateType, { payload }) => {
	const turnovers = state.current.turnovers;
	const turnoverIndex = turnovers.findIndex(turnover => turnover.productType === payload.productType);
	if (turnoverIndex >= 0) {
		turnovers[turnoverIndex] = payload;
	} else {
		turnovers.push(payload);
	}
	state.current.turnovers = turnovers
	return state;
}

const removeBroadcast = (state: OrderShowStateType, { payload, productId }) => {
	if (!state.current.broadcastsByProduct) state.current.broadcastsByProduct = {};
	const broadcasts = state.current.broadcastsByProduct[productId] || [];
	const index = broadcasts.findIndex(b => b.startingOn === payload.date);
	if (index !== -1) broadcasts.splice(index, 1)

	state.current.broadcastsByProduct[productId] = broadcasts;
	return state;
}

const addBroadcast = (state: OrderShowStateType, { payload, productId }) => {
	if (!state.current.broadcastsByProduct) state.current.broadcastsByProduct = {};
	const broadcasts = state.current.broadcastsByProduct[productId] || [];
	const index = broadcasts.findIndex(b => b.startingOn === payload.startingOn);
	if (index >= 0) {
		broadcasts[index] = payload;
	} else {
		broadcasts.push(payload);
	}
	state.current.broadcastsByProduct[productId] = broadcasts;
	return state;
}

const removeBroadcastsAfterDate = (state: OrderShowStateType, { payload }) => {
	const { startingOn, productId } = payload;
	if (!state.current.broadcastsByProduct) state.current.broadcastsByProduct = {};
	const broadcasts = state.current.broadcastsByProduct[productId] || [];
	remove(broadcasts, b => {
		return moment(b.startingOn).isSameOrAfter(startingOn)
	})
	return state;
}

const concatBroadcasts = (state: OrderShowStateType, { payload }) => {
	const { broadcasts, productId } = payload;
	if (!state.current.broadcastsByProduct) state.current.broadcastsByProduct = {};
	state.current.broadcastsByProduct[productId] = (state.current.broadcastsByProduct[productId] || []).concat(broadcasts);
	return state;
}

const orderShowModel = createImmerReducer(initialState, {
	'orderShow/setLoading': (state: OrderShowStateType, {payload}) => {
		state.loading = payload;
		return state;
	},
	'orderShow/setSubmitting': (state: OrderShowStateType, { payload }) => {
		state.submitting = payload;
		return state;
	},
	'orderShow/setCurrent': (state: OrderShowStateType, { payload }) => {
		state.current = {
			broadcastsByProduct: state?.current?.broadcastsByProduct || {},
			...payload
		};
		return state;
	},
	'orderShow/setTurnoverLoading': (state: OrderShowStateType, { payload }) => {
		state.turnoverLoading = payload;
		return state;
	},
	'orderShow/concatBroadbasts': concatBroadcasts,
	'orderShow/removeBroadcastsAfterDate': removeBroadcastsAfterDate,
	'orderShow/changeTurnover': changeTurnover,
	'orderShow/changeOrder': (state: OrderShowStateType, { payload }) => {
		state.current = {
			...state.current,
			...payload,
		}
		return state;
	},
	'orderShow/addBroadcast': addBroadcast,
	'orderShow/removeBroadcast': removeBroadcast,
	'orderShow/setPayments': (state: OrderShowStateType, { payload }) => {
		state.current.payments = payload;
		return state;
	},
	'orderShow/updatePayments': (state: OrderShowStateType, { payload }) => {
		state.current.payments = payload;
		return state;
	},
	'orderShow/productSelector/setVisibility': (state: OrderShowStateType, { payload }) => {
		state.productSelector.visible = payload;
		return state;
	},
	'orderShow/setProducts': (state: OrderShowStateType, { payload }) => {
		state.current.productIds = payload.productIds;
		state.current.orderProducts = payload.orderProducts;
		return state;
	},
	'orderShow/setEditing': (state: OrderShowStateType, { payload }) => {
		state.editing = payload;
		return state;
	},
	'orderShow/updateProduct': (state: OrderShowStateType, { payload }) => {
		const orderProducts = state.current.orderProducts || [];
		const orderProductIndex = orderProducts.findIndex(op => op.productId === payload.productId)
		if (orderProductIndex >= 0) {
			orderProducts[orderProductIndex] = {
				...orderProducts[orderProductIndex],
				...payload
			};
		}
		state.current.orderProducts = orderProducts;
		return state;
	},
	'orderShow/productSelector/setSubmitting': (state: OrderShowStateType, { payload }) => {
		state.productSelector.submitting = payload;
		return state;
	},
	'orderShow/broadcastSelector/setLoading': (state: OrderShowStateType, { payload }) => {
		state.broadcastSelector.loading = payload;
		return state;
	},
	'orderShow/broadcastSelector/setOrderProductSubmitting': (state: OrderShowStateType, { payload }) => {
		state.broadcastSelector.orderProductSubmitting = payload;
		return state;
	},
	'orderShow/broadcastSelector/setYear': (state: OrderShowStateType, { payload }) => {
		state.broadcastSelector.year = payload;
		return state;
	},
	'orderShow/setBroadcasts': setOrderBroadcasts,
  'orderShow/removeAttachment': (state: OrderShowStateType, { payload }) => {
    if (state.current.files) {
      const fileIndex = state.current.files.findIndex(f => f.id === payload);
      if(fileIndex >= 0) {
        state.current.files.splice(fileIndex, 1)
      }
    }
    return state;
  }
})

export default orderShowModel;
