import reduce from "lodash/reduce";
import queryString from "query-string";
import {
  SET_FILTERS,
  SET_PAGINATION,
  UI_REPORTING_SET_DATES,
  UI_REPORTING_SET_GROUP_BY,
  UI_REPORTING_SET_LOADING,
  UI_REPORTING_SET_PRICE_TYPE,
  UI_REPORTING_SET_CATEGORIES,
  UI_REPORTING_SET_COMMERCIAL
} from "../../uis/reporting";

import requestQuery from "../../../utils/graphql";
import { getBroadcastsReport } from "../../../services/broadcasts";
import { REPORTING_SET } from "../../reporting";

export const setLoading = payload => ({
  type: UI_REPORTING_SET_LOADING,
  payload
});

export const updateDates = payload => ({
  type: UI_REPORTING_SET_DATES,
  payload
});

export const updateCategories = payload => ({
  type: UI_REPORTING_SET_CATEGORIES,
  payload
});

export const updateCommercial = commercial => ({
  type: UI_REPORTING_SET_COMMERCIAL,
  payload: commercial
});


export const setPagination = payload => ({
  type: SET_PAGINATION,
  payload
});

export const setFilters = payload => ({
  type: SET_FILTERS,
  payload
});

export const setGroupBy = payload => ({
  type: UI_REPORTING_SET_GROUP_BY,
  payload
});

export const setPriceType = payload => ({
  type: UI_REPORTING_SET_PRICE_TYPE,
  payload
});

const filtersToAttributes = filters => {
  const attributes = {};
  const { client, product } = filters;
  if (product) attributes.productIds = product;
  if (client && client.length > 0) {
    const [clientTitleValue] = client;
    attributes.clientTitle = clientTitleValue;
  }
  return attributes;
};

const reduceByStartingOn = (result, value) => ({
  ...result,
  [value.startingOn]: value.price
});

export const fetchReporting = () => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { reporting },
    router: {
      location: { pathname }
    }
  } = state;
  dispatch(setLoading(true));
  const filtersAttributes = filtersToAttributes(
    reporting.get("filters").toJS()
  );
  return requestQuery(getBroadcastsReport, {
    startingOn: reporting.get("startingOn"),
    endingOn: reporting.get("endingOn"),
    interval: pathname.includes("week") ? "week" : "month",
    page: reporting.get("currentPage"),
    perPage: reporting.get("pageSize"),
    groupBy: reporting.get("groupBy"),
    priceType: reporting.get("priceType"),
    search: {
      ...filtersAttributes,
      userName: reporting.get('commercial'),
      categories: reporting.get("categories")
    }
  })
    .then(data => data.broadcastsReport || [])
    .then(({ data, totalRecord, total }) => {
      const normalizedData = data.map(group => ({
        ...group,
        intervals: reduce(group.intervals, reduceByStartingOn, {})
      }));

      const normalizedTotal = reduce(total.intervals, reduceByStartingOn, {});

      dispatch(
        setPagination({
          total: totalRecord
        })
      );

      dispatch({
        type: REPORTING_SET,
        payload: {
          list: normalizedData,
          total: {
            price: total.price,
            intervals: normalizedTotal
          }
        }
      });
      dispatch(setLoading(false));
    });
};

export const updateCategoriesAndFetch = categories => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { reporting }
  } = state;
  dispatch(updateCategories(categories));
  dispatch(
    setFilters({
      ...reporting.get("filters").toJS(),
      product: []
    })
  );
  dispatch(
    setPagination({
      currentPage: 1
    })
  );

  return dispatch(fetchReporting());
};

export const updateCommercialAndFetch = commercial => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { reporting }
  } = state;
  dispatch(updateCommercial(commercial))
  dispatch(
    setFilters({
      ...reporting.get("filters").toJS(),
      product: []
    })
  );
  dispatch(
    setPagination({
      currentPage: 1
    })
  );
  return dispatch(fetchReporting());
}

export const updateTableAndFetch = (
  { current, total, pageSize },
  filters
) => dispatch => {
  dispatch(
    setPagination({
      total,
      currentPage: current,
      pageSize
    })
  );
  dispatch(setFilters(filters));
  return dispatch(fetchReporting());
};

export const updateGroupByAndFetch = groupBy => dispatch => {
  dispatch(setGroupBy(groupBy));
  dispatch(setFilters({}));
  dispatch(
    setPagination({
      currentPage: 1
    })
  );
  return dispatch(fetchReporting());
};

export const updatePriceTypeAndFetch = priceType => dispatch => {
  dispatch(setPriceType(priceType));
  return dispatch(fetchReporting());
};

export const updateDatesRangeAndFetch = dates => (dispatch, getState) => {
  const state = getState();
  const {
    router: {
      location: { pathname }
    }
  } = state;
  const [mStartingOn, mEndingOn] = dates;
  const view = pathname.includes("week") ? "week" : "month";
  const startingOn = mStartingOn.startOf(view).format("YYYY-MM-DD");
  const endingOn = mEndingOn.endOf(view).format("YYYY-MM-DD");
  dispatch(
    updateDates({
      startingOn,
      endingOn
    })
  );
  dispatch(
    setPagination({
      currentPage: 1
    })
  );
  return dispatch(fetchReporting());
};

export const exportReporting = () => (dispatch, getState) => {
  const {
    ui: { reporting }
  } = getState();
  const { startingOn, endingOn, categories } = reporting.toJS();
  const stringifiedParams = queryString.stringify({
    starting_on: startingOn,
    ending_on: endingOn,
    categories
  });
  window.open(
    `/reporting/export.csv?${stringifiedParams}&token=182e927be0ba8dfb959fd86f92f478633dc8a7d357`
  );
};
