import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Divider, DatePicker } from "antd";
import { connect } from "react-redux";
import moment from "moment";

import { divide, round, reduce, uniq } from "lodash";

import styles from "./index.sass";
import OccupancyRatesTable from "../components/OccupancyRates/Table";
import { fetchProductsOccupancy } from "../models/actions/products";
import createDeepEqualSelector from "../utils/createDeepEqualSelector";
import withProducts from "../utils/providers/withProducts";

import { orm as Product } from "../models/products";
import CategorySelector from "../components/CategorySelector/index";
import {
  changeDatesAndFetch,
  setFilters,
  setLoading,
  setCategories
} from "../models/actions/ui/occupancyRates";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

export const OccupancyRates = (props) => {
  const {
    data,
    onCategoryChange,
    onDateChange,
    categories,
    productFilters,
    onTableChange,
    ui
  } = props;
  const [t] = useTranslation();
  const { startingOn, endingOn, loading, filters } = ui;
  useEffect(() => {
    props.loadData(startingOn, endingOn);
  }, [])
  return (
    <Card>    
      <div className={styles.header}>
        <h1>{t("occupancyRates.title")}</h1>
      </div>
      <Divider style={{marginTop: 10}} />
      <div className={styles.filtersContainer}>
        <CategorySelector
          title={t("categorySelector.label")}
          options={categories}
          values={ui.categories}
          onChange={onCategoryChange}
        />
        <div className={styles.rangePicker}>
          <span>{t("filterByDates")}</span>
          <RangePicker
            onChange={onDateChange}
            defaultValue={[moment(startingOn), moment(endingOn)]}
            format="DD/MM/YYYY"
          />
        </div>
      </div>
      <OccupancyRatesTable
        data={data}
        sticky
        showTotal
        productFilters={productFilters}
        filters={filters}
        loading={loading}
        onChange={onTableChange}
        startingOn={startingOn}
        endingOn={endingOn}
      />
    </Card>
  );
};

OccupancyRates.defaultProps = {
  categories: [],
  data: [],
  ui: {}
};

OccupancyRates.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.string),
  onCategoryChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    loading: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.string),
    endingOn: PropTypes.string,
    startingOn: PropTypes.string
  }),
  onTableChange: PropTypes.func.isRequired,
  productFilters: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.strin
    })
  ).isRequired
};

const composedOccupancyRates = withProducts(OccupancyRates);

export const getProductData = createDeepEqualSelector(
  (product, props) => ({
    product,
    ...props
  }),
  ({ startingOn, endingOn, product, numberOfWeek }) => ({
    ...product,
    total: round(
      divide(
        reduce(
          product.occupancyByWeeks,
          (result, value, key) => {
            if (moment(key).isBetween(startingOn, endingOn, null, "[]"))
              return result + value.occupancyRate;
            return result;
          },
          0
        ),
        numberOfWeek
      ),
      0
    )
  })
);

const calculateTotalRow = createDeepEqualSelector(
  products => products,
  products => {
    let occupancyByWeeks = {};
    let total = 0;
    if (products && products.length > 0) {
      let weeks = [];
      const ss = products.filter(s => s.occupancyByWeeks);
      ss.forEach(product => {
        weeks = [...weeks, ...Object.keys(product.occupancyByWeeks)];
      });
      weeks = uniq(weeks);

      occupancyByWeeks = reduce(
        weeks,
        (result, date) => {
          const sumByWeeks = reduce(
            ss,
            (acc, product) => {
              if (product.occupancyByWeeks[date])
                return acc + product.occupancyByWeeks[date].occupancyRate;
              return acc;
            },
            0
          );

          return {
            ...result,
            [date]: round(divide(sumByWeeks, products.length), 0)
          };
        },
        {}
      );

      total = round(
        divide(
          reduce(products, (result, product) => result + product.total, 0),
          products.length
        )
      );
    }
    return {
      total,
      occupancyByWeeks
    };
  }
);

const propsSelector = createDeepEqualSelector(
  state => state.ui.occupancyRates,
  state => Product.all(state),
  state => Product.categories(state),
  (ux, products, categories) => {
    const ui = ux.toJS();
    let data = products;
    const categoryChoosed = ui.categories;

    if (ui.filters.title && ui.filters.title.length > 0)
      data = data.filter(s => ui.filters.title.includes(s.id));
    if (categoryChoosed.length > 0)
      data = data.filter(s => categoryChoosed.includes(s.category));

    const mStartingOn = moment(ui.startingOn);
    const mEndingOn = moment(ui.endingOn);

    const numberOfWeek = mEndingOn.diff(mStartingOn, "weeks") + 1;
    data = data.filter(p => p.sides).map(product =>
      getProductData(product, {
        startingOn: ui.startingOn,
        endingOn: ui.endingOn,
        numberOfWeek
      })
    );

    data.push({
      id: "footer",
      ...calculateTotalRow(data)
    });

    return {
      data,
      categories,
      ui,
      productFilters: products
        .filter(
          product =>
            (categoryChoosed.length === 0 || categoryChoosed.includes(product.category)) && product.sides
        )
        .map(s => ({ value: s.id, text: s.title }))
    };
  }
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: (startingOn, endingOn, productIds = null) => {
    const { location } = ownProps;
    if (location.state && location.state.categories)
      dispatch(setCategories(location.state.categories));
    dispatch(setLoading(true));
    dispatch(setFilters({}));
    dispatch(fetchProductsOccupancy({ productIds, startingOn, endingOn })).then(
      () => {
        dispatch(setLoading(false));
      }
    );
  },
  onCategoryChange: categories => {
    dispatch(setFilters({}));
    dispatch(setCategories(categories));
  },
  onTableChange: (pagination, filters) => dispatch(setFilters(filters)),
  onDateChange: momentDates => dispatch(changeDatesAndFetch(momentDates))
});

export default connect(
  propsSelector,
  mapDispatchToProps
)(composedOccupancyRates);
