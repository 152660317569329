import React from "react";
import { Select } from 'antd';
import PropTypes from "prop-types";
import styles from "./index.sass";

export const CategorySelector = props => {
  const { onChange, values, options, title } = props;
  return (
    <div className={styles.container}>
      <span style={{ marginRight: 10 }}>{title}</span>
      <Select style={{ minWidth: 200 }} value={values?.length ? values[0] : undefined} onChange={(value) => onChange([value])} allowClear>
        {options.map(o => (
          <Select.Option
            key={`year-${o}`}
            value={o}
          >
            {o}
          </Select.Option>
        ))}
        
      </Select>
    </div>
  );
};

CategorySelector.defaultProps = {
  options: [],
  values: [],
  title: ""
};

CategorySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
};

export default CategorySelector;
