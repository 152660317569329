import React, { Fragment, useState } from "react";
import { Divider, Row, Tabs, Button, Tooltip, Popconfirm, Alert, Col, Modal } from "antd";
import moment from "moment";
import Details from "./Details";
import styles from "./index.scss";
import StateButton from "./StateButton";
import ProductSelector from "../ProductSelector";
import Broadcasts from "./Broadcasts";
import Payments from "./Payments/index";
import BudgetsForm from './BudgetsForm';
import { useTranslation } from "react-i18next";
import memoOnlyForKeys from "../InPlaceForm/utils/memoOnlyForKeys";
import { withProducts } from "../../utils/providers";
import { ExclamationCircleOutlined, LockOutlined, SyncOutlined, UnlockOutlined } from "@ant-design/icons";
import WarningsMessages from './WarningsMessages';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BriefingForm from './Briefing';
import {
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

export const Order = props => {
  const {
    order,
    handler,
    loading,
    warnings,
    editionEnabled,
  } = props;
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState("budget")
  const {
    onSyncOrder,
    onDelete,
    onSave,
    onChangeState,
    onToggleEditingMode,
    onAttachmentDelete,
  } = handler;
  const {
    canBeDeleted,
    state,
    code,
    client,
    startingOn,
    productIds,
    endingOn,
    broadcastsCount,
    errors,
  } = order;

  const deleteButton = editionEnabled ? (
    <Popconfirm title={t("order.deleteTooltip.confirm")} onConfirm={onDelete}>
      <Button
        type="danger"
        className={styles.deleteButton}
        disabled={!canBeDeleted || broadcastsCount > 0}
      >
        {t("delete")}
      </Button>
    </Popconfirm>
  ) : null;

  const haveDeepreach = order?.turnovers?.some(turnover => turnover.productType === "other")

  const showConfirmationModal = (onOk) => {
    Modal.confirm({
      title: t('sentences.errorsOnOrder'),
      width: 500,
      content: (
        <div>
          <WarningsMessages t={t} warnings={warnings} />
          <p style={{ marginTop: 10, marginBottom: 0 }}>
            <b>
              {t('sentences.areYouSureToSaveTheOrder')}
            </b>
          </p>
        </div>
      ),
      okText: t('words.yes'),
      cancelText: t('words.no'),
      onOk,
    })
  }
  return (
    <div>
      <div className={styles.header}>
        <h3>
          {client && [client.title, code, order.title].filter(Boolean).join(' - ')}
          {errors && (
            <Tooltip title={errors.join("\n")}>
              <FontAwesomeIcon
                style={{ marginLeft: 10, color: "#fa541c" }}
                icon={faExclamationTriangle}
              />
            </Tooltip>
          )}
          <StateButton state={state} />
        </h3>
        <div
          style={{ marginLeft: 'auto' }}
        >
          {state === "validated" && (
            <a style={{ display: 'inline-block', marginRight: 15 }} onClick={() => onToggleEditingMode(!editionEnabled)}>
              {editionEnabled ? <UnlockOutlined /> : <LockOutlined />}
              <span style={{ marginLeft: 8, display: 'inline-block' }}>
                {t(`sentences.${editionEnabled ? 'editionEnabled' : 'editionLocked'}`)}
              </span>
            </a>
          )}
          {
            editionEnabled && (
              <Button
                onClick={onSyncOrder}
                style={{ marginRight: 16 }}
                icon={<SyncOutlined />}
              >
                Synchroniser le devis
              </Button>
            )
          }
          {broadcastsCount > 0 && deleteButton ? (
            <Tooltip
              title={t(
                `order.deleteTooltip.${
                  canBeDeleted ? "deleteBroadcastsFirst" : "never"
                }`,
                {
                  date: moment().startOf("week").subtract(4, "weeks").format("DD/MM/YYYY")
                }
              )}
            >
              {deleteButton}
            </Tooltip>
          ) : (
            deleteButton
          )}
        </div>
      </div>
      <Divider className={styles.divider}/>
      <Details readOnly={!editionEnabled} loading={loading} order={order} onSubmit={onSave}  />
      <Tabs activeTab={activeTab} onChange={(key) => setActiveTab(key)} defaultActiveKey="planning" type="card">
        <Tabs.TabPane tab="Budget" key="budget" >
          <div style={{ padding: 15 }}>
            <BudgetsForm
              readOnly={!editionEnabled}
              totalBroadcasts={order.totalBroadcasts}
              turnovers={order.turnovers}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Planning" key="planning">
          <div style={{ padding: 15 }}>
            {editionEnabled && (
              <ProductSelector />
            )}
            {(!startingOn || !endingOn) && (
              <div style={{ marginTop: 30 }}>
                <strong className="text-alert">
                  <ExclamationCircleOutlined style={{ marginRight: 5 }} />
                  Veuillez sélectionner une date de début et de fin pour finaliser le devis.
                </strong>
              </div>
            )}
            {productIds &&
              productIds.length > 0 &&
              startingOn &&
              endingOn && (
              <Fragment>
                <Broadcasts beforeSave={warnings?.length ? showConfirmationModal : null} />
              </Fragment>
            )}
          </div>
        </Tabs.TabPane>
        {productIds &&
          productIds.length > 0 &&
          startingOn &&
          endingOn && (
            <Tabs.TabPane tab="Échéancier" key="payments">
              <Payments />
            </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Brief" key="brief">
          <BriefingForm readOnly={!editionEnabled} loading={loading} order={order} onSubmit={onSave} onAttachmentDelete={onAttachmentDelete} />
        </Tabs.TabPane>
      </Tabs>
      {state === "editing" && (
        <React.Fragment>
          {haveDeepreach && (
            <Row key="deepReachAlert" type="flex" align="end">
              <Col xs={24}>
                <Alert
                  style={{ width: "100%"}}
                  showIcon
                  type="warning"
                  message={t('orderShow.deepreachAlert')}
                />
              </Col>
            </Row>
          )}
          <Row key="validateBroadcastsButton" type="flex" align="end">
            <Button
              disabled={(warnings?.length && state !== "validated" && activeTab !== "planning") || !(startingOn && endingOn)}
              type="primary"
              size="large"
              className={styles.submitButton}
              onClick={() => warnings?.length ? showConfirmationModal(() => onChangeState("validated")) : onChangeState("validated")}
            >
              {t("orderShow.validateDevis")}
            </Button>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

Order.defaultProps = {
  t: () => {},
  broadcastDate: {},
};

export default withProducts(React.memo(Order, memoOnlyForKeys(['order', 'broadcastDate', 'editionEnabled', 'validationWarnings'])));
