import getAuthFromLS, { setAuthInLS } from "../../utils/getAuthFromLS";
import * as actionTypes from "./constants/auth";

function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export const setStorageItem = credentials => {
  const item = getAuthFromLS();
  setAuthInLS({
    ...item,
    ...credentials,
    isAuthenticated: true
  })
};

const resetAuth = () => {
  localStorage.removeItem("auth");
  return {
    isAuthenticated: false
  };
};

const updateAuth = (state = {}, { credentials }) => {
  setStorageItem(credentials);
  return {
    ...state,
    ...credentials,
    isAuthenticated: true
  };
};

const authReducer = createReducer(
  {},
  {
    [actionTypes.AUTH_UPDATE]: updateAuth,
    [actionTypes.AUTH_RESET]: resetAuth
  }
);

export default authReducer;
