import { useQuery, gql } from "@apollo/client";

const QUERY = gql`
  query commercials {
    commercials
  }
`;

const useCommercials = () => {
  const { data, loading } = useQuery(QUERY);
  return {
    commercials: (data?.commercials || []),
    loading
  };
}

export default useCommercials;