import { Map, OrderedSet } from "immutable";
import { denormalize } from "normalizr";

import { createSelector } from "reselect";
import Schemas from "../schema";
import * as actionTypes from "./constants/clients";
import createReducer from "../utils/createReducer";

const initialState = Map({
  list: OrderedSet([]),
  loading: false
});

const setLoading = state => state.set("loading", true);

const setList = (state, { payload }) =>
  state.set("loading", false).set("list", OrderedSet(payload));

const insertToList = (state, { payload }) =>
  state.set("loading", false).updateIn(["list"], list => list.add(payload));

const removeFromList = (state, { payload }) =>
  state.updateIn(["list"], list => list.delete(payload));

const clientsReducer = createReducer(initialState, {
  [actionTypes.FETCH_REQUEST]: setLoading,
  [actionTypes.FETCH_SUCCESS]: setList,
  [actionTypes.INSERT]: insertToList,
  [actionTypes.DELETE_SUCCESS]: removeFromList
});

const all = createSelector(
  state => state.entities,
  state => state.clients.get("list"),
  (entities, ids) => denormalize(ids.toJS(), Schemas.CLIENTS, entities.toJS())
);

export const orm = {
  all
};

export default clientsReducer;
