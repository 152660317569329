import isNil from "lodash/isNil";
import styles from "./legend.sass";
/**
 *
 * @param {number} occupancyRate - Nombre de faces d'un produit
 * @returns {string} class de la couleur a utilisé
 */
const cellColor = occupancyRate => {
  if (!isNil(occupancyRate)) {
    if (occupancyRate >= 0 && occupancyRate <= 50) return styles.occupancyLow;
    if (occupancyRate > 83 && occupancyRate < 100) return styles.high;
    if (occupancyRate === 100) return styles.hundred;
    if (occupancyRate > 100) return styles.veryHigh;
    return styles.normal;
  }
  return styles.noOccupancy;
};

export default cellColor;
