import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { withTranslation } from "react-i18next";

import { compose, mapProps } from "recompose";
import columns from "./colums";

export const ReportingTable = props => {
  const {
    loading,
    data,
    columns,
    numberOfColumn,
    onChange,
    pagination,
    groupBy
  } = props;
  const width = 350 + numberOfColumn * 150;
  return (
    <div>
      <Table
        key={groupBy}
        columns={columns}
        pagination={{
          ...pagination,
          pageSize: pagination.pageSize + 1
        }}
        onChange={onChange}
        loading={loading}
        dataSource={data}
        rowKey={(record, index) => record.id === "footer" ? "footer" : `row-${index}`}
        sticky={true}
        scroll={{
          x: width > 1600 || numberOfColumn < 5 ? width : 1600
        }}
      />
    </div>
  );
};

ReportingTable.defaultProps = {
  loading: false,
  data: [],
  columns: [],
  numberOfColumn: 0,
  groupBy: "client"
  // footer: {},
};

ReportingTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  groupBy: PropTypes.oneOf(["product", "client"]),
  onChange: PropTypes.func.isRequired,
  numberOfColumn: PropTypes.number,
  /* footer: PropTypes.shape({
    total: PropTypes.number,
    weeks: PropTypes.shape({}),
  }), */
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired
};

const tableConfiguration = props => ({
  ...columns({ ...props })
});

export default withTranslation()(
  compose(
    mapProps(props => ({
      ...props,
      ...tableConfiguration(props)
    }))
  )(ReportingTable)
);
