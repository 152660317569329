import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Spin } from "antd";
import PaymentForm from "./Form";

const Loader = () => (
	<div style={{ textAlign: "center" }}>
		<Spin size="large" />
	</div>
);

const PaymentModal = props => {
	const { loading, payment, onCancel, submit, ...rest } = props;
	const [t] = useTranslation();
	if(!payment) return null;
	const { order, dueOn, amount } = payment;

	return (
		<Modal
			title={
				loading
					? null
					: `${order.client.title} - ${order.code} - ${moment(dueOn).format(
						"LL"
					)} - ${amount.toLocaleString()} €`
			}
			visible
			okText={t("save")}
			onCancel={onCancel}
			footer={null}
			{...rest}
		>
			{loading ? (
				<Loader />
			) : (
				<div>
					<div style={{ textAlign: "right" }}>
						<Link to={`/orders/${order.id}`}>{t("openOrder")}</Link>
					</div>
					<PaymentForm onSubmit={submit} onCancel={onCancel} payment={payment} />
				</div>
			)}
		</Modal>
	);
};

PaymentModal.defaultProps = {
	loading: false,
	payment: {},
	onCancel: () => undefined
};

PaymentModal.propTypes = {
	loading: PropTypes.bool,
	payment: PropTypes.shape({}),
	submit: PropTypes.func.isRequired,
	onCancel: PropTypes.func
};

export default PaymentModal;
