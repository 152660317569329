import { Map, Set, fromJS } from "immutable";

// ANTD Table utilities
export const setFilters = (state, { payload }) =>
  state.set("filters", Map(payload));

export const setPagination = (state, { payload }) =>
  state.mergeDeep(fromJS(payload));

export const setCurrentPage = (state, { payload }) =>
  state.set("currentPage", payload);

export const setSort = (state, { payload }) => state.mergeDeep(Map(payload));
//

export const setLoading = (state, { payload }) => state.set("loading", payload);

export const setCategories = (state, { payload }) =>
  state.set("categories", Set(payload));

export const setCommercial = (state, { payload }) => state.set('commercial', payload);

export const setDates = (state, { payload: { startingOn, endingOn } }) =>
  state.set("startingOn", startingOn).set("endingOn", endingOn);
