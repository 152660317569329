import gql from "graphql-tag";
import requestQuery from "../utils/graphql";
import flashMessagesFragment from "./flashMessagesFragment";

export const productInfo = gql`
  fragment ProductInfo on Product {
    id
    title
    category
    city
    sides
    productType
    deletable
  }
`;

export const productsQuery = gql`
  query products {
    products {
      ...ProductInfo
      breakEvenPoint
      goal
    }
  }
  ${productInfo}
`;

export const createProductQuery = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      product {
        ...ProductInfo
        breakEvenPoint
        goal
      }
      flashMessages {
        ...FlashMessageInfo
      }
      errors
    }
  }
  ${productInfo}
  ${flashMessagesFragment}
`;

export const updateProductQuery = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        ...ProductInfo
        breakEvenPoint
        goal
      }
      flashMessages {
        ...FlashMessageInfo
      }
      errors
    }
  }
  ${productInfo}
  ${flashMessagesFragment}
`;

export const deleteProductQuery = gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      product {
        ...ProductInfo
      }
      flashMessages {
        ...FlashMessageInfo
      }
      deleted
    }
  }
  ${productInfo}
  ${flashMessagesFragment}
`;

export const productsOccupancyQuery = gql`
  query productsOccupancy(
    $startingOn: String!
    $endingOn: String!
    $productIds: [ID!]
  ) {
    productsOccupancy(
      productIds: $productIds
      startingOn: $startingOn
      endingOn: $endingOn
    ) {
      sides
      id
      occupancyByWeeks {
        startingOn
        broadcastCount
        occupancyRate
      }
    }
  }
`;

const fetchOccupancy = async function ({ productIds, startingOn, endingOn }) {
  const response = await requestQuery(productsOccupancyQuery, { productIds, startingOn, endingOn }, { fetchPolicy: 'network-only' });
  return response.productsOccupancy;
}

const ProductService = {
  fetchOccupancy
}

export default ProductService;