import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types";
import reduce from "lodash/reduce";
import { denormalize } from "normalizr";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import { Card, DatePicker, Button, Dropdown, Menu, Divider, Radio } from "antd";

import { Link } from "react-router-dom";
import changeDatesAndFetch from "../models/actions/ui/payments/changeDatesAndFetch";
import styles from "./index.sass";
import init from "../models/actions/ui/payments/init";
import Schemas from "../schema";
import PaymentsTable from "../components/PaymentsTable";
import updateTableAndFetch from "../models/actions/ui/payments/updateTableAndFetch";
import { exportPayments, UI_PAYMENTS_RESET } from "../models/uis/payments";
import { setAndFetchCurrent } from "../models/actions/ui/payments/setCurrentPayment";
import updateStateFilter from "../models/actions/ui/payments/updateStateFilter";
import Payment from "../components/Payment";

const { RangePicker } = DatePicker;

const paymentStates = [
  "all",
  "pending_receipt",
  "pending_payment",
  "late",
  "paid"
];

export const StateFilter = props => {
  const { onChange, t, value } = props;
  const menu = (
    <Menu onClick={onChange}>
      {paymentStates
        .filter(k => value !== k)
        .map(k => (
          <Menu.Item key={k}>{t(`payments.statesFilter.${k}`)}</Menu.Item>
        ))}
    </Menu>
  );
  return (
    <div>
      {t("payments.statesFilterTitle")}
      <Dropdown overlay={menu}>
        <Button style={{ marginLeft: "8px" }}>
          {t(`payments.statesFilter.${value}`)}
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

StateFilter.defaultProps = {
  value: "all"
};

StateFilter.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

const Payments = props => {
  const {
    t,
    onDateChange,
    ui,
    pagination,
    data,
    onTableChange,
    onStateFilterChange,
    onCellClick,
    onExport,
    reset
  } = props;
  useEffect(() => {
    props.loadData();
    return () => {
      props.reset();
    }
  }, [])
  const { startingOn, endingOn, filters, loading, stateFilter = "all" } = ui;
  const rows = [
    <Card key="payment-list">
      <Payment />
      <div className={styles.header}>
        <h1>{t("words.dueDate")}</h1>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.filtersContainer}>
        <StateFilter onChange={onStateFilterChange} t={t} value={stateFilter} />
        <div className={styles.rangePicker}>
          <span>{t("filterByDates")}</span>
          <RangePicker
            onChange={onDateChange}
            defaultValue={[moment(startingOn), moment(endingOn)]}
            format="DD/MM/YYYY"
          />
        </div>
      </div>
      <div className={styles.groupByFilter}>
        <span className={styles.groupByFilterLabel}>
          {`${t(
          "program.view"
        )}:`}
        </span>
        <Radio.Group value="table">
          <Link to="/payments">
            <Radio.Button className={styles.monthView} value="table">
              {t("program.viewType.table")}
            </Radio.Button>
          </Link>
          <Link to="/payments/list" onClick={() => reset()}>
            <Radio.Button className={styles.paymentView} value="list">
              {t("program.viewType.list")}
            </Radio.Button>
          </Link>
        </Radio.Group>
      </div>
      <PaymentsTable
        pagination={pagination}
        bordered
        data={data}
        onChange={onTableChange}
        onCellClick={onCellClick}
        startingOn={startingOn}
        endingOn={endingOn}
        filters={filters || {}}
        loading={loading}
      />
      <Button type="default" icon={<DownloadOutlined />} onClick={onExport}>
        {t("exportBroadcasts")}
      </Button>
    </Card>
  ];
  return rows;
};

Payments.defaultProps = {
  pagination: {},
  data: [],
  payment: null
};

Payments.propTypes = {
  t: PropTypes.func.isRequired,
  onStateFilterChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  ui: PropTypes.shape({
    loading: PropTypes.bool,
    stateFilter: PropTypes.string
  }).isRequired,
  pagination: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

const mapStateToProps = createSelector(
  state => state.ui.payments,
  state => state.entities,
  (ux, entities) => {
    const ui = ux.toJS();
    const {
      pagination: { currentPage, total, pageSize },
      ...otherUi
    } = ui;
    const data = denormalize(ui.list, Schemas.ORDERS_ROWS, entities.toJS());
    const totalRow = reduce(
      data,
      (result, order) => {
        const r = { ...result };
        if (order?.payments) {
          order.payments.forEach(payment => {
            if (!r[payment.dueOn]) r[payment.dueOn] = 0;
            r[payment.dueOn] += payment.amount || 0;
          });
        }
        return r;
      },
      {}
    );
    data.push({ id: "footer", payments: totalRow });
    return {
      pagination: {
        current: currentPage,
        total,
        pageSize
      },
      ui: otherUi,
      data
    };
  }
);

const mapDispatchToProps = dispatch => ({
  loadData: () => dispatch(init()),
  reset: () =>
    dispatch({
      type: UI_PAYMENTS_RESET
    }),
  onStateFilterChange: ({ key }) => dispatch(updateStateFilter(key)),
  onDateChange: momentDates => dispatch(changeDatesAndFetch(momentDates)),
  onTableChange: (pagination, filters) =>
    dispatch(updateTableAndFetch(pagination, filters)),
  onCellClick: id => dispatch(setAndFetchCurrent(id)),
  onExport: () => dispatch(exportPayments())
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Payments);
