import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { OrderShowStateType } from "../../../models/orderShow"
import {
  compose,
  onlyUpdateForKeys,
  withState,
  withHandlers,
  lifecycle
} from "recompose";
import { Row, Col, Checkbox, Button, Popover, Typography, Divider } from "antd";
import styles from "../index.scss";
import CreateMultiplePaymentsForm from "./CreateMultiplePaymentsForm";
import PaymentForm from "./PaymentForm";
import PaymentsList from "./PaymentsList";
import { toFloat } from "../../../utils/functions";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';

export const payments = props => {
  const {
    state,
    handleCreateMultiple,
    nextPayments,
    payments,
    handleChange,
    handleUniqueReceipt,
    handleDelete,
    handleAdd,
    handlePaymentsSubmit,
    nextUniqueReceipt,
    uniqueReceipt,
    totalBudget,
    editMode
  } = props;
  const [t] = useTranslation();
  const amountSum = _.round(
    _.sum(
      nextPayments
        .filter(p => !p._destroy)
        .map(p => toFloat(p.amount && p.amount.value ? p.amount.value : 0))
    ),
    2
  );
  const disabledDates = nextPayments
    .filter(p => p.dueOn && p.dueOn.value)
    .map(p => p.dueOn.value.format("MM-YYYY"));
  return (
    <Row>
      <Col xs={24}>
        {state === "editing" && <p>{t("dueDate.validateFirst")}</p>}
        {state !== "editing" && payments.length === 0 && (
          <>
          {editMode ? (
            <CreateMultiplePaymentsForm readOnly={!editMode} submit={handleCreateMultiple} />
          ) : (
            <span>
              Basculez en mode édition pour créer l'échéancier
            </span>
          )}
          </>
        )}
        {state !== "editing" && payments.length > 0 && !editMode && (
          <div>
            {uniqueReceipt && <div>{t("payment.uniqueReceipt")}</div>}
            <PaymentsList payments={payments} />
          </div>
        )}
        {state !== "editing" && payments.length > 0 && editMode && (
          <div>
            <Checkbox
              style={{ marginBottom: "10px" }}
              checked={nextUniqueReceipt}
              onChange={e => handleUniqueReceipt(e.target.checked)}
            >
              {t("payment.uniqueReceipt")}
            </Checkbox>
            {nextPayments.map((payment, i) => (
              <PaymentForm
                showLabels={i === 0}
                showReceiptField={i === 0 || !nextUniqueReceipt}
                index={i}
                onChange={handleChange}
                onDelete={handleDelete}
                disabledDate={currentDate =>
                  disabledDates.includes(currentDate.format("MM-YYYY"))
                }
                key={payment.id || `new-${i}`}
                payment={payment}
              />
            ))}
            <div style={{ marginTop: "10px" }}>
              <a onClick={handleAdd}>{t("payment.add")}</a>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className={
                amountSum === totalBudget
                  ? styles["text-success"]
                  : styles["text-alert"]
              }
            >
              <Popover
                content={`${amountSum.toLocaleString()} / ${totalBudget.toLocaleString()}`}
              >
                {`${t("total")}: ${amountSum.toLocaleString()} €`}
              </Popover>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                onClick={handlePaymentsSubmit}
                disabled={amountSum !== totalBudget}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

payments.defaultProps = {
  payments: [],
  nextPayments: [],
  nextUniqueReceipt: false,
  uniqueReceipt: false,
  totalBudget: 0,
  editMode: false,
};

payments.propTypes = {
  t: PropTypes.func.isRequired,
  nextPayments: PropTypes.arrayOf(PropTypes.shape({})),
  payments: PropTypes.arrayOf(PropTypes.shape({})),
  handleCreateMultiple: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  uniqueReceipt: PropTypes.bool,
  handleAdd: PropTypes.func.isRequired,
  handlePaymentsSubmit: PropTypes.func.isRequired,
  handleUniqueReceipt: PropTypes.func.isRequired,
  nextUniqueReceipt: PropTypes.bool,
  totalBudget: PropTypes.number,
  state: PropTypes.oneOf(["editing", "validated"]).isRequired
};

const handleDelete = ({ nextPayments, setNextPayments }) => (
  index,
  payload
) => {
  const payments = [...nextPayments];
  if (payments[index].id) {
    payments[index] = { ...payments[index], _destroy: payload };
  } else {
    _.pullAt(payments, [index]);
  }
  setNextPayments(payments);
};

const handleAdd = ({
  nextPayments,
  setNextPayments,
  nextUniqueReceipt
}) => () => {
  setNextPayments([
    ...nextPayments,
    {
      state: {
        value:
          nextUniqueReceipt &&
          nextPayments.length > 0 &&
          nextPayments[0].state.value !== "pending_receipt"
            ? "pending_payment"
            : "pending_receipt"
      },
      ...(nextUniqueReceipt && nextPayments.length > 0
        ? {
            receipt: {
              value: nextPayments[0].receipt.value
            }
          }
        : {})
    }
  ]);
};

const handleChange = ({ nextPayments, setNextPayments, nextUniqueReceipt }) => (
  values,
  index
) => {
  let payments = [...nextPayments];
  payments[index] = { ...payments[index], ...values };
  if (nextUniqueReceipt && payments.length > 0) {
    if (
      payments[0].state &&
      payments[0].state.value &&
      payments[0].state.value !== "paid"
    ) {
      payments = payments.map((payment, id) => {
        if (id === 0 || (payment.state && payment.state.value === "paid"))
          return payment;
        return {
          ...payment,
          state: {
            value: payments[0].state.value
          }
        };
      });
    }

    if (payments[0].receipt && payments[0].receipt.value) {
      payments = payments.map((payment, id) => {
        if (id === 0) return payment;
        return {
          ...payment,
          receipt: {
            value: payments[0].receipt.value
          }
        };
      });
    }
  }
  setNextPayments(payments);
};

const handleUniqueReceipt = ({ setUniqueReceipt }) => checked =>
  setUniqueReceipt(checked);

const handlePaymentsSubmit = ({
  onSubmit,
  nextPayments,
  nextUniqueReceipt
}) => () => {
  onSubmit({ payments: nextPayments, uniqueReceipt: nextUniqueReceipt });
};

export const paymentToFormState = payment => {
  const p = { ...payment };
  ["amount", "notes", "receipt", "state"].forEach(field => {
    p[field] = { value: payment[field] };
  });
  p.dueOn = { value: payment.dueOn ? moment(payment.dueOn) : null };
  p.paidOn = { value: payment.paidOn ? moment(payment.paidOn) : null };
  return p;
};

const composedPayments =  compose(
  withState("nextPayments", "setNextPayments", ({ payments }) =>
    payments ? payments.map(paymentToFormState) : []
  ),
  withState(
    "nextUniqueReceipt",
    "setUniqueReceipt",
    ({ uniqueReceipt }) => uniqueReceipt
  ),
  withHandlers({
    handleDelete,
    handleChange,
    handleAdd,
    handleUniqueReceipt,
    handlePaymentsSubmit
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { setNextPayments, payments } = nextProps;
      if (!_.isEqual(payments, this.props.payments))
        setNextPayments(payments ? payments.map(paymentToFormState) : []);
    }
  }),
  onlyUpdateForKeys([
    "nextUniqueReceipt",
    "editMode",
    "totalBudget",
    "nextPayments",
    "tReady",
    "state",
    "id"
  ])
)(payments);

const mapStateToProps = (state) => {
  const order = state.orderShow.current;
  const totalBudget = _.sum((order?.turnovers || []).map(t => t.turnover || 0));
  return {
    ...order,
    totalBudget,
    editMode: state.orderShow.editing
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleCreateMultiple: values =>
    dispatch({ type: 'orderShow/createPayments', payload: values }),
  onSubmit: payload => dispatch({ type: 'orderShow/savePayments', payload })
})

export default connect(mapStateToProps, mapDispatchToProps)(composedPayments)