import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { compose, pure } from "recompose";
import { Row, Col, Button } from "antd";
import { withTranslation } from "react-i18next";
import styles from "./index.sass";
import YearSelector from "../YearSelector";
import MonthColumns from "./MonthColumns";

const buildCheckboxes = props => {
  const { year, broadcastsByYears, onChange, values } = props;
  let checkboxes = [];
  if (broadcastsByYears[year]) {
    checkboxes = Array(12)
      .fill()
      .map((_, i) => {
        const month = i < 9 ? `0${(i + 1).toString()}` : (i + 1).toString();
        let monthBroadcasts = [];
        if (broadcastsByYears[year][month])
          monthBroadcasts = broadcastsByYears[year][month];
        return (
          <MonthColumns
            key={`${year}-${month}`}
            onChange={onChange}
            enabledOptions={monthBroadcasts}
            values={values}
            year={year}
            month={month}
          />
        );
      })
      .filter(e => e);
  }
  return checkboxes;
};

export const SpotsDateSelector = props => {
  const {
    className,
    year,
    years,
    onYearChange,
    values,
    onChange,
    broadcastsByYears,
    mode,
    editing,
    onSubmit,
    disableSubmit,
    t
  } = props;
  return (
    <Fragment>
      <Row key="container" className={className}>
        {mode === "edit" ? (
          <Fragment>
            <Col key="yearsSelector" xs={24}>
              <YearSelector
                options={years}
                value={year}
                onChange={onYearChange}
              />
            </Col>
            <Col key="checkboxes" xs={24} className={styles.checkContainer}>
              {buildCheckboxes({
                values,
                onChange,
                broadcastsByYears,
                year
              })}
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            {values.map(value => (
              <div key={`value${value}`}>
                <span className={styles.monthName}>
                  S
                  {moment(value).week()}
                </span>
                &nbsp;
                <span className={styles.checkboxDate}>
                  {moment(value).format("DD/MM/YYYY")}
                </span>
              </div>
            ))}
          </Fragment>
        )}
      </Row>
      {editing && (
        <Row type="flex" align="end">
          <Button
            type="primary"
            disabled={disableSubmit}
            className={styles.submitButton}
            onClick={onSubmit}
          >
            {t("save")}
          </Button>
        </Row>
      )}
    </Fragment>
  );
};

SpotsDateSelector.defaultProps = {
  className: "",
  year: moment().year(),
  years: [],
  onYearChange: () => {},
  mode: "show",
  broadcastsByYears: {},
  values: [],
  onSubmit: () => undefined,
  editing: false,
  t: () => undefined,
  disableSubmit: false
};

SpotsDateSelector.propTypes = {
  year: PropTypes.number,
  years: PropTypes.arrayOf(PropTypes.number),
  onYearChange: PropTypes.func,
  className: PropTypes.string,
  mode: PropTypes.oneOf(["edit", "show"]),
  broadcastsByYears: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func,
  disableSubmit: PropTypes.bool,
  editing: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default compose(pure)(withTranslation()(SpotsDateSelector));
