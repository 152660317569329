import { normalize } from "normalizr";
import first from "lodash/first";
import setLoading from "./setLoading";
import Payment from "../../../../services/payments";
import {
  UI_PAYMENTS_SET_LIST,
  UI_PAYMENTS_SET_PAGINATION,
} from "../../../uis/payments";
import Schemas from "../../../../schema";

const filtersToAttributes = filters => {
  const { client, orderCode, receiptCode } = filters;
  const attributes = {};
  if (client && client.length > 0) attributes.clientTitle = first(client);
  if (receiptCode && receiptCode.length > 0)
    attributes.receiptCode = first(receiptCode);
  if (orderCode && orderCode.length > 0)
    attributes.orderCode = first(orderCode);
  return attributes;
};

const fetchPaymentsReporting = () => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { payments }
  } = state;
  dispatch(setLoading(true));
  const filtersAttributes = filtersToAttributes(payments.get("filters").toJS());
  Payment.getReporting({
    startingOn: payments.get("startingOn"),
    endingOn: payments.get("endingOn"),
    page: payments.getIn(["pagination", "currentPage"], 1),
    perPage: payments.getIn(["pagination", "pageSize"], 30),
    search: {
      ...filtersAttributes,
      state: payments.get("stateFilter", "all")
    }
  }).then(({ ordersRow, perPage: pageSize, total, currentPage }) => {
    dispatch({
      type: UI_PAYMENTS_SET_PAGINATION,
      payload: {
        pagination: {
          total,
          pageSize,
          currentPage
        }
      }
    });

    const { result, entities } = normalize(ordersRow, Schemas.ORDERS_ROWS);
    dispatch({
      type: UI_PAYMENTS_SET_LIST,
      entities,
      payload: result
    });
  });
};

export const fetchListPaymentsReporting = () => (dispatch, getState) => {
  const state = getState();
  const {
    ui: { payments }
  } = state;
  dispatch(setLoading(true));
  const filtersAttributes = filtersToAttributes(payments.get("filters").toJS());
  Payment.getListReportiong({
    startingOn: payments.get("startingOn"),
    endingOn: payments.get("endingOn"),
    page: payments.getIn(["pagination", "currentPage"], 1),
    perPage: payments.getIn(["pagination", "pageSize"], 30),
    search: {
      ...filtersAttributes,
      state: payments.get("stateFilter", "all")
    }
  }).then(({ payments, perPage: pageSize, total, currentPage }) => {
    dispatch({
      type: UI_PAYMENTS_SET_PAGINATION,
      payload: {
        pagination: {
          total,
          pageSize,
          currentPage
        }
      }
    });
    const { result, entities } = normalize(payments, Schemas.PAYMENTS_ROW);
    dispatch({
      type: UI_PAYMENTS_SET_LIST,
      entities,
      payload: result
    });
  });
};

export default fetchPaymentsReporting;
