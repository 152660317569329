import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from 'redux-saga'

import thunk from "redux-thunk";

import rootReducer, { rootSaga } from "./models";
import getAuthFromLS from "./utils/getAuthFromLS";

export const sagaMiddleware = createSagaMiddleware()

const configureStore = history => {
  const initialState = {
    auth: getAuthFromLS()
  };
  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools({ trace: true })(applyMiddleware(
      sagaMiddleware,
      thunk,
      routerMiddleware(history),
    ))
  );
  sagaMiddleware.run(rootSaga)
  return store;
};

export default configureStore;
