import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { createSelector } from "reselect";
import { compose } from "recompose";
import PaymentModal from "./Modal";
import { unsetCurrentPayment } from "../../models/actions/ui/payments/setCurrentPayment";
import Schemas from "../../schema";
import updatePayment from "../../models/actions/ui/payments/updatePayment";

const Payment = props => {
	const { payment, onClose, ...rest } = props;
	return <PaymentModal payment={payment} onCancel={onClose} {...rest} />;
};

Payment.defaultProps = {
	payment: {}
};

Payment.propTypes = {
	payment: PropTypes.shape({}),
	onClose: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired
};

const currentPayment = createSelector(
	state => state.entities,
	state => state.ui.payments.getIn(["current", "id"], null),
	(entities, id) => denormalize(id, Schemas.PAYMENT, entities.toJS())
);

const mapStateToProps = createSelector(
	state => state.ui.payments,
	currentPayment,
	(ux, payment) => ({
		loading: ux.getIn(["current", "loading"], false),
		payment
	})
);

const mapDispatchToProps = dispatch => ({
	onClose: () => dispatch(unsetCurrentPayment(null)),
	submit: values => dispatch(updatePayment(values))
});

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Payment);
