import * as actionTypes from "../constants/user";

export const setUser = user => ({
  type: actionTypes.SET,
  payload: user
});

export default {
  setUser
};
