import React from "react";
import { connect } from "react-redux";
import { Button } from 'antd';
import AntForm from '@9troisquarts/ant-form.ant-form';
import '@ant-design/compatible/assets/index.css';
import { saveClient } from "../../../../models/actions/clients";
import { ClientType } from '../../../../types';

type IProps = {
  loading: Boolean;
  onCancel: () => void;
  client: ClientType;
}

export const ClientForm: React.FC<IProps> = props => {
  const {
    client,
    onSubmit,
    errors,
    loading = false,
    onCancel = undefined
  } = props;
  return (
    <AntForm
      errors={errors}
      layout="vertical"
      object={client || {}}
      loading={loading}
      schema={
        [{
          name: 'id',
          style: { display: 'none' },
        }, {
          label: 'Nom',
          name: 'title',
          input: {
            type: 'string'
          }
        }]
      }
      onSubmit={onSubmit}
      extraActions={[
        <Button onClick={onCancel} type="default">
          Annuler
        </Button>
      ]}
      actionsWrapperProps={{
        className: "form-actions"
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.ui.clientForm.submitting,
  errors: state.ui.clientForm.errors,
})

const mapDispatchToProps = dispatch => ({
  onSubmit: (client) => dispatch(saveClient(client)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientForm);
