import { List } from "immutable";
import createReducer from "../utils/createReducer";

export const SET = "USERS_SET";

const set = (state, { payload }) => List(payload);

const initialState = List([]);

const usersReducer = createReducer(initialState, {
  [SET]: set
});

export default usersReducer;
