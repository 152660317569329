import {
  faTable,
  faEdit,
  faCog,
  faCalendarAlt,
  faClock,
  faEuroSign,
  faCheck
} from "@fortawesome/free-solid-svg-icons";

// const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  return reg.test(path);
}

const menuData = [
  {
    name: "Bons de commande",
    icon: faEdit,
    path: "orders"
  },
  {
    name: "Planning",
    icon: faClock,
    path: "planning"
  },
  {
    name: "Taux d'occupation",
    icon: faTable,
    path: "occupancy_rates"
  },
  {
    name: "Reporting",
    icon: faEuroSign,
    path: "reporting",
    children: [
      {
        icon: faTable,
        name: "Mensuel",
        path: "month"
      },
      {
        icon: faTable,
        name: "Hebdomadaire",
        path: "week"
      }
    ]
  },
  {
    name: "Échéances",
    icon: faTable,
    path: "payments"
  },
  {
    name: "Checklist des diffusions",
    path: "broadcasts_checklist",
    icon: faCheck
  },
  {
    name: "Administration",
    icon: faCog,
    path: "administration"
  }
];

function formatter(data, parentPath = "/", parentAuthority) {
  return data.map(item => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority
    };
    if (item.children) {
      result.children = formatter(
        item.children,
        `${parentPath}${item.path}/`,
        item.authority
      );
    }
    return result;
  });
}

export const getMenuData = () => formatter(menuData);
