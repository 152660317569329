import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Popconfirm, Modal } from "antd";
import styles from "./index.scss";

import { fetchClients, deleteClient } from "../../../../models/actions/clients";
import { orm as Clients } from "../../../../models/clients";
import ClientForm from "../ClientForm";
import { useTranslation } from "react-i18next";

const columns = ({ editing, onEdit, onDelete }) => [
  {
    title: "Nom",
    dataIndex: "title",
    key: "title"
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        {editing === record.id ? (
          <span className={styles.action}>Editer</span>
        ) : (
          <a className={styles.action} onClick={() => onEdit(record)}>
            Editer
          </a>
        )}
        &nbsp; - &nbsp;
        <Popconfirm
          title="Êtes-vous sûr de vouloir supprimer le client ?"
          onConfirm={() => onDelete(record.id)}
        >
          <a className={styles.action}>Supprimer</a>
        </Popconfirm>
      </span>
    )
  }
];
export const ClientList = props => {
  const { clients, loading, currentClient, onEditVisibilityChange } = props;
  const [t] = useTranslation();

  const onCancel = () => onEditVisibilityChange(false);
  useEffect(() => {
    props.fetch();
  }, []);
  return [
    <Table
      key="list"
      pagination={{
        pageSize: 30,
        total: clients.size
      }}
      rowKey="id"
      dataSource={clients}
      columns={columns(props)}
      loading={loading}
    />,
    <div key="clientForm">
      {currentClient && (
        <Modal onCancel={onCancel} title={t(`clients.${currentClient.id ? 'edit' : 'new'}`)} visible footer={null}>
          <ClientForm
            client={currentClient}
            onCancel={onCancel}
          />
        </Modal>
      )}
    </div>
  ];
};

ClientList.defaultProps = {
  loading: false,
  currentClient: null,
  clients: [],
  onEditVisibilityChange: () => undefined
};

ClientList.propTypes = {
  currentClient: PropTypes.shape({}),
  loading: PropTypes.bool,
  onEditVisibilityChange: PropTypes.func,
  clients: PropTypes.arrayOf(PropTypes.shape({}))
};

const mapStateToProps = state => {
  const { clients, ui } = state;
  return {
    loading: clients.get("loading"),
    clients: Clients.all(state),
    currentClient: ui.clientForm.current,
  };
};

const mapDispatchToProps = dispatch => ({
  fetch() {
    dispatch(fetchClients());
  },
  onDelete(id) {
    dispatch(deleteClient(id));
  },
  onEdit(record) {
    dispatch({
      type: "clientForm/setCurrent",
      payload: record
    });
  },
  onEditVisibilityChange(visible) {
    if (!visible) dispatch({ type: "clientForm/reset" });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientList);
