import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const { Option } = Select;

const clientOptions = clients =>
  clients.map(client => (
    <Option key={`client-option-${client.id}`} value={client.id}>
      {client.title}
    </Option>
  ));

const filterOption = (input, option) =>
  option.props.children.toLowerCase().startsWith(input.toLowerCase());

// eslint-disable-next-line react/prefer-stateless-function
class ClientSelect extends React.Component {
  render() {
    const { clients, ...rest } = this.props;
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        {...rest}
      >
        {clientOptions(clients)}
      </Select>
    );
  }
}

ClientSelect.defaultProps = {
  clients: []
};

ClientSelect.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ClientSelect;
