import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { orm as Products } from "../../models/products";
import { saveProduct } from "../../models/actions/products";
import { useTranslation } from "react-i18next";
import { AntForm, AntSchema } from "@9troisquarts/ant-form.ant-form";


const productTypes = ['dooh', 'studio', 'other', 'option', 'waze', 'ads']

export const ProductForm = props => {
  const {
    onSubmit,
    onCancel,
    errors = {},
    product,
    categoryOptions,
    loading = false
  } = props;
  const [t] = useTranslation();
  const [categorySearch, setCategorySearch] = useState(undefined);
  

  const schema: AntSchema = [
    {
      name: 'id',
      style: { display: 'none' },
    },
    {
      label: t('products.title'),
      name: 'title',
      required: true,
      input: {
        type: 'string'
      }
    },
    {
      label: t("products.productType"),
      name: 'productType',
      required: true,
      input: {
        type: 'select',
        options: productTypes.map(c => ({ value: c, label: t(`productTypes.${c}`) })),
      }
    },
    {
      label: t("products.category"),
      name: 'category',
      required: true,
      input: {
        type: 'select',
        options: [...categoryOptions, categorySearch].filter(e => e && e.length > 0).map(c => ({ value: c, label: c })),
        inputProps: {
          showSearch: true,
          onSearch: (value) => setCategorySearch(value)
        }
      }
    },
    {
      label: t('products.sides'),
      name: 'sides',
      input: {
        type: 'number',
      }
    },
    [
      {
        label: t('products.breakEvenPoint'),
        name: 'breakEvenPoint',
        input: {
          type: 'number'
        },
        colProps: {
          xs: 24,
          md: 8
        }
      },
      {
        label: t('products.goal'),
        name: 'goal',
        input: {
          type: 'number'
        },
        colProps: {
          xs: 24,
          md: 8
        }
      }
    ]
  ];

  return (
    <AntForm
      object={product}
      errors={errors || {}}
      onSubmit={onSubmit}
      layout="vertical"
      extraActions={[
        <Button onClick={onCancel} type="default">
          Annuler
        </Button>
      ]}
      loading={loading}
      schema={schema}
      actionsWrapperProps={{
        className: "form-actions"
      }}
    />
  );
};

const mapStateToProps = state => {
  const { ui } = state;
  return {
    loading: ui.productForm.loading,
    product: ui.productForm.current,
    errors: ui.productForm.errors,
    categoryOptions: Products.categories(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: (product) => dispatch(saveProduct(product)),
  onCancel: () => dispatch({ type: 'productForm/reset' })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductForm);
