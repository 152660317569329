import React from "react";
import { Row, Col } from "antd";
import styles from "./legend.sass";
import { useTranslation } from "react-i18next";

const Legend = () => {
  const [t] = useTranslation();
  return (
    <Row className={styles.legend}>
      <Col xs={24} className={styles.legendContainer}>
        <div className={`${styles.occupancyLow} ${styles.firstLegend}`} />
        &nbsp;
        {"0 à 50%"}
        <div className={styles.normal} />
        &nbsp;
        {"de >50% à 83%"}
        <div className={styles.high} />
        &nbsp;
        {"de >83% à 100%"}
        <div className={styles.hundred} />
        &nbsp;
        {"= 100%"}
        <div className={styles.veryHigh} />
        &nbsp;
        {"> 100%"}
      </Col>
    </Row>
  )
};

export default Legend;
