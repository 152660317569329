import moment from "moment";
import { normalize } from "normalizr";
import qs from "query-string";
import every from "lodash/every";
import get from "lodash/get";
import {
  UI_BROADCASTS_CHECKLIST_SET_CATEGORIES,
  UI_BROADCASTS_CHECKLIST_SET_LOADING,
  UI_BROADCASTS_CHECKLIST_SET_WEEK,
  UI_BROADCASTS_CHECKLIST_SET_LIST,
  UI_BROADCASTS_CHECKLIST_SET_ACTIVE_KEY,
  orm,
  UI_BROADCASTS_CHECKLIST_SET_VIEW
} from "../../uis/broadcastsChecklist";
import requestQuery, { requestMutation } from "../../../utils/graphql";
import {
  getBroadcasts,
  toggleBroadcastVerified
} from "../../../services/broadcastsChecklist";
import { SAVE_ENTITY, SET_ENTITIES } from "../../constants/entities";
import Schemas from "../../../schema";

export const setLoading = payload => ({
  type: UI_BROADCASTS_CHECKLIST_SET_LOADING,
  payload
});

export const updateCategories = payload => ({
  type: UI_BROADCASTS_CHECKLIST_SET_CATEGORIES,
  payload
});

export const updateWeek = payload => ({
  type: UI_BROADCASTS_CHECKLIST_SET_WEEK,
  payload
});

export const updateActiveKey = payload => ({
  type: UI_BROADCASTS_CHECKLIST_SET_ACTIVE_KEY,
  payload
});

export const updateView = payload => ({
  type: UI_BROADCASTS_CHECKLIST_SET_VIEW,
  payload
});

export const fetchBroadcasts = () => (dispatch, getState) => {
  const state = getState();
  const { broadcastsChecklist } = state.ui;
  const mStartingOn = moment(broadcastsChecklist.get("week"));
  const categories = broadcastsChecklist.get("categories");
  dispatch(setLoading(true));
  requestQuery(getBroadcasts, {
    search: {
      startingOn: mStartingOn.format("YYYY-MM-DD"),
      endingOn: mStartingOn.endOf("week").format("YYYY-MM-DD"),
      categories
    }
  })
    .then(data => (data ? data.broadcasts : []))
    .then(broadcasts => {
      const normalizedData = normalize(broadcasts, Schemas.BROADCASTS);
      dispatch({
        type: SET_ENTITIES,
        entities: normalizedData.entities
      });
      dispatch({
        type: UI_BROADCASTS_CHECKLIST_SET_LIST,
        payload: normalizedData.result
      });
      dispatch(setLoading(false));
    });
};

export const updateCategoriesAndFetch = categories => dispatch => {
  dispatch(updateCategories(categories));
  return dispatch(fetchBroadcasts());
};

export const updateWeekAndFetch = startingOn => dispatch => {
  dispatch(updateWeek(startingOn));
  return dispatch(fetchBroadcasts());
};

export const updateActiveKeyIfAllVerified = (groupId, nextGroupId) => (
  dispatch,
  getState
) => {
  const groupedBroadcasts = orm.groupByView(getState());
  if (
    groupedBroadcasts[groupId] &&
    every(groupedBroadcasts[groupId], { verified: true })
  )
    dispatch(updateActiveKey(nextGroupId));
};

export const redirectToFilteredPrograms = broadcast => (
  _dispatch,
  getState
) => {
  const state = getState();
  const { broadcastsChecklist } = state.ui;
  const mStartingOn = moment(broadcastsChecklist.get("week"));
  const params = {
    startingOn: mStartingOn.format("YYYY-MM-DD"),
    endingOn: mStartingOn
      .clone()
      .startOf("week")
      .add(6, "weeks")
      .format("YYYY-MM-DD"),
    productIds: [broadcast.product.id],
    client: broadcast.order.client.title
  };
  window.open(`/planning?${qs.stringify(params)}`);
};

export const toggleBroadcast = (id, checked, nextGroupId) => (
  dispatch,
  getState
) => {
  const view = getState().ui.broadcastsChecklist.get("view");
  dispatch({
    id,
    type: SAVE_ENTITY,
    entityType: "broadcasts",
    payload: {
      verified: checked
    }
  });
  requestMutation(toggleBroadcastVerified, { input: { id, verified: checked }})
    .then(
      data =>
        data.toggleBroadcastVerified
          ? data.toggleBroadcastVerified.broadcast
          : {}
    )
    .then(broadcast => {
      dispatch({
        type: SAVE_ENTITY,
        id: broadcast.id,
        entityType: "broadcasts",
        payload: broadcast
      });
      if (nextGroupId) {
        dispatch(
          updateActiveKeyIfAllVerified(
            get(broadcast, `${view}.id`),
            nextGroupId
          )
        );
      }
    });
};
