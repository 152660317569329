import { normalize } from "normalizr";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import omit from 'lodash/omit';
import Schemas from "../../schema";
import { requestQuery, requestMutation } from "../../utils/graphql";
import * as types from "../constants/products";
import { call, put } from 'redux-saga/effects'
import moment from 'moment';
import ProductService, {
  productsQuery,
  createProductQuery,
  deleteProductQuery,
  updateProductQuery,
  productsOccupancyQuery
} from "../../services/products";
import { SAVE_ENTITY, SET_ENTITIES } from "../constants/entities";

const fetchRequest = () => ({
  type: types.FETCH_REQUEST
});

export const deleteProduct = id => dispatch => {
  requestMutation(deleteProductQuery, { input: {id} })
    .then(data => (data.deleteProduct ? data.deleteProduct : {}))
    .then(data => {
      if (data.deleted) {
        dispatch({
          type: types.DELETE_SUCCESS,
          payload: id
        });
      }
    });
};

export const saveProduct = attributes => (dispatch) => {
  dispatch({ type: "productForm/setLoading", payload: true });
  const query = isNil(attributes.id)
  ? createProductQuery
  : updateProductQuery;

  requestMutation(query, { input: { id: attributes.id, attributes: omit(attributes, ['id']) }})
    .then(data => {
      if (data.updateProduct) return data.updateProduct;
      if (data.createProduct) return data.createProduct;
      return {};
    })
    .then(data => {
      const { product } = data;
      if (product) {
        const normalizedData = normalize(product, Schemas.PRODUCT);
        dispatch({
          type: SET_ENTITIES,
          entities: normalizedData.entities
        });
        if (isNil(attributes.id)) {
          dispatch({
            type: types.INSERT,
            payload: normalizedData.result
          });
        }
        dispatch({ type: "productForm/reset" });
      }
    }).catch(e => {
      if(e.errors) {
        dispatch({
          type: 'productForm/setCurrent',
          payload: attributes
        });
        dispatch({ type: 'productForm/setErrors', payload: e.errors });
        dispatch({
          type: "productForm/setLoading",
          payload: false
        });
      }
    });
};

export const fetchProducts = () => dispatch => {
  dispatch(fetchRequest());
  return requestQuery(productsQuery)
    .then(data => (data.products ? data.products : []))
    .then(products => {
      const normalizedData = normalize(products, Schemas.PRODUCTS);
      dispatch({
        type: SET_ENTITIES,
        entities: normalizedData.entities
      });
      dispatch({
        type: types.FETCH_SUCCESS,
        payload: normalizedData.result
      });
    });
};

const fetchIsNeeded = state =>
  state.products.get("list").size === 0 && !state.products.get("loading");

export const fetchProductsIfNeeded = () => (dispatch, getState) => {
  if (fetchIsNeeded(getState())) {
    dispatch(fetchProducts());
  }
};


export const fetchCurrentOrderOccupancyRate = () => (dispatch, getState) => {
  const order = getState().orderShow.current;
  const { endingOn, productIds } = order;
  dispatch(fetchProductsOccupancy({
    productIds,
    startingOn: moment()
      .startOf("week")
      .subtract(4, "weeks")
      .format("YYYY-MM-DD"),
    endingOn: endingOn
  }))
}

export const fetchProductsOccupancy = ({
  productIds,
  startingOn,
  endingOn
}) => dispatch => {
  return requestQuery(productsOccupancyQuery, { productIds, startingOn, endingOn })
    .then(({ productsOccupancy }) => productsOccupancy || [])
    .then(productsOccupancy => {
      const products = productsOccupancy.map(product => ({
        ...product,
        occupancyByWeeks: reduce(
          product.occupancyByWeeks,
          (acc, value) => {
            acc[value.startingOn] = value;
            return acc;
          },
          {}
        )
      }));
      const normalizedData = normalize(products, Schemas.PRODUCTS);
      dispatch({
        type: SET_ENTITIES,
        entities: normalizedData.entities
      });
    });
}
export function* sagafetchProductsOccupancy({
  payload: {
    productIds,
    startingOn,
    endingOn
  }
}) {
  const productsOccupancy = yield call(ProductService.fetchOccupancy, { productIds, startingOn, endingOn });
  const products = productsOccupancy.map(product => ({
    ...product,
    occupancyByWeeks: reduce(
      product.occupancyByWeeks,
      (acc, value) => {
        acc[value.startingOn] = value;
        return acc;
      },
      {}
    )
  }));
  const normalizedData = normalize(products, Schemas.PRODUCTS);
  yield put({
    type: SET_ENTITIES,
    entities: normalizedData.entities
  });
}

export const updateProductOccupancyRate = (
  productId,
  startingOn,
  occupancyRate
) => dispatch => {
  dispatch({
    type: SAVE_ENTITY,
    entityType: "products",
    id: productId,
    payload: {
      occupancyByWeeks: {
        [startingOn]: {
          occupancyRate
        }
      }
    }
  });
};


export function* sagaUpdateProductOccupancyRate({productId, startingOn, occupancyRate}) {
  yield put({
    type: SAVE_ENTITY,
    entityType: "products",
    id: productId,
    payload: {
      occupancyByWeeks: {
        [startingOn]: {
          occupancyRate
        }
      }
    }
  })
}