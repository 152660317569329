import { OrderedSet, Map } from "immutable";
import createReducer from "../utils/createReducer";

const initialState = Map({
  list: OrderedSet([]),
  total: Map({})
});

const set = (state, { payload }) =>
  state.set("list", OrderedSet(payload.list)).set("total", Map(payload.total));

export const REPORTING_SET = "reporting/set";

const reportingReducer = createReducer(initialState, {
  [REPORTING_SET]: set
});

export default reportingReducer;
