import React, { useEffect } from "react";
import { Card, Row, Col } from "antd";

import styles from "./index.sass";
import { useTranslation } from "react-i18next";
import { useQuery, gql } from "@apollo/client";
import moment from "moment";

const paymentsStatsQuery = gql`
  query paymentsStats {
    paymentsStats {
      late {
        count
        amount
      }
      pendingReceipt {
        count
        amount
      }
      averageTime
    }
  }
`;

const PaymentsStats = () => {
  const { data, loading } = useQuery(paymentsStatsQuery)
  const { late, pendingReceipt, averageTime } = data?.paymentsStats || {};
  const [t] = useTranslation();

  return (
    <Card loading={loading}>
      <h2>{t("words.dueDate")}</h2>
      <Row className={styles.statsContainer}>
        <Col xs={24}>
          <strong>{t("paymentsStats.lateTitle")}</strong>
          {late && (
            <span>
              {`${t("words.dueDates", { count: late.count })}`}
              {late.amount && ` / ${late.amount.toLocaleString()} €`}
            </span>
          )}
        </Col>
        <Col xs={24}>
          <strong>{t("paymentsStats.pendingReceiptTitle")}</strong>
          {pendingReceipt && (
            <span>
              {`${t("words.dueDates", { count: pendingReceipt.count })}`}
              {pendingReceipt.amount &&
                ` / ${pendingReceipt.amount.toLocaleString()} €`}
            </span>
          )}
        </Col>
        <Col xs={24}>
          <strong>{t("paymentsStats.averageTimeTitle")} {moment().subtract(1, 'year').format('YYYY')}-{moment().format('YYYY')}</strong>
          {averageTime && (
            <span>
              {t("words.xDays", { count: averageTime.toLocaleString() })}
            </span>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PaymentsStats
