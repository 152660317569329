import { Map, fromJS } from "immutable";
import createReducer from "../utils/createReducer";
import * as types from "./constants/entities";
import * as orders from "./entities/orders";
import { UI_PAYMENTS_SET_LIST } from "./uis/payments";

const setEntities = (state, { entities }) => state.mergeDeep(fromJS(entities));
const saveEntity = (state, { payload, id, entityType }) =>
  state.mergeDeepIn([entityType, id], fromJS(payload));

const setEntity = (state, { payload, id, entityType }) =>
  state.mergeIn([entityType, id], fromJS(payload));

const mergeEntities = (state, { entities }) => state.merge(fromJS(entities));

const initialState = Map({});

const entitiesReducer = createReducer(initialState, {
  [types.SET_ENTITIES]: setEntities,
  [types.SAVE_ENTITY]: saveEntity,
  [types.MERGE_ENTITIES]: mergeEntities,
  [types.SET_ENTITY]: setEntity,
  [UI_PAYMENTS_SET_LIST]: mergeEntities,
  [types.ORDERS_TOGGLE_SPOT]: orders.toggleSpot,
  [types.ORDERS_SET_PAYMENTS]: orders.setPayments,
});

export default entitiesReducer;
