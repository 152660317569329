// @ts-nocheck

const getAuthFromLS = () =>
  localStorage.getItem('auth')
    ? JSON.parse(localStorage.getItem('auth'))
    : null;

export const authenticationHeaders = () => {
  const item = localStorage.getItem('auth');
  if (item) {
    const auth = JSON.parse(item);
    return {
      'access-token': auth['access-token'],
      client: auth.client,
      uid: auth.uid,
    };
  }
  return {};
};

export const setAuthInLS = auth => {
  const storedAuth = getAuthFromLS();
  return localStorage.setItem('auth', JSON.stringify({expiry: storedAuth?.expiry, ...auth}));
}
export default getAuthFromLS;