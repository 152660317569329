import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styles from './index.sass';

type IProps = {
  caret?: boolean;
  options?: Array<{ value: string | number; label: string }>;
  children?: React.ReactNode;
  onChange: (values: { name: string; value: string }) => void;
  readOnly?: boolean;
};

const Select: React.FC<IProps> = props => {
  const {
    options = [],
    name,
    children,
    readOnly = false,
    onChange,
    caret = false,
  } = props;

  const onClick = ({ key }) => onChange({ [name]: key });

  const menu = (
    <Menu onClick={onClick}>
      {options.map(({ value, label }) => (
        <Menu.Item key={value}>{label}</Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown
      {...(readOnly ? { visible: false } : {})}
      overlay={menu}
      trigger={['click']}
      className={styles.dropdown}
    >
      <span>
        {children}
        {caret && <DownOutlined />}
      </span>
    </Dropdown>
  );
};

export default Select;
