import moment from "moment";
import forIn from "lodash/forIn";

export const toFloat = (v = 0) => parseFloat(v.toString().replace(",", "."));

export const castAttributesFromModel = (model, attributes = {}) => {
  const formattedAttributes = { ...attributes };
  forIn(model, (value, key) => {
    if (attributes[key]) {
      switch (value) {
        case "Float":
          formattedAttributes[key] = toFloat(attributes[key]);
          break;
        case "Integer":
          formattedAttributes[key] = parseInt(attributes[key], 10);
          break;
        case "Date":
          if (moment.isMoment(attributes[key]))
            formattedAttributes[key] = attributes[key].format("YYYY-MM-DD");
          else formattedAttributes[key] = attributes[key];
          break;
        default:
          formattedAttributes[key] = attributes[key];
          break;
      }
    }
  });
  return formattedAttributes;
};
