import { schema } from "normalizr";

export const client = new schema.Entity("clients");
export const product = new schema.Entity("products");
export const payment = new schema.Entity("payments");
export const order = new schema.Entity("orders");
export const broadcast = new schema.Entity("broadcasts");
export const paymentRow = new schema.Entity("payments",
{
},
  {
    idAttribute: item => item.id
  }
);
export const ordersRow = new schema.Entity(
  "orderRows",
  {
    client,
    order,
    payments: [payment]
  },
  {
    idAttribute: item => item.order.id
  }
);

export const rootSchema = {
  clients: [client]
};

export const Schemas = {
  BROADCAST: broadcast,
  BROADCASTS: [broadcast],
  CLIENTS: [client],
  CLIENT: client,
  PRODUCTS: [product],
  PRODUCT: product,
  PAYMENT: payment,
  ORDERS_ROWS: [ordersRow],
  PAYMENTS_ROW: [paymentRow]
};

export default Schemas;
