import { notification } from "antd/lib/index";

/**
 * Show flash messages and throw an error when status of response is not 200
 * @param response - Fetch response
 * @returns {*} - Return the response or throw an error
 */
export default function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = response.statusText;
  notification.error({
    message: `${response.status}: ${response.url}`,
    description: errortext
  });
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
}
