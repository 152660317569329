/* eslint react/jsx-indent: 0 */

import React from "react";
import moment from "moment";
import round from "lodash/round";
import last from "lodash/last";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip, Typography } from "antd";

import styles from "./index.sass";
import FilterDropdown from "../FilterDropdown";

const cx = classNames.bind(styles);

const Cell = (date, viewType = "spot", t) => (text, record) => {
  const {
    id,
    broadcastsStartingOn,
    broadcastsCompleteWeek,
    orderState,
    price
  } = record;
  if (id === "footer") {
    return <strong>{record[date] ? record[date] : 0}</strong>;
  }
  const broadcastIndex = broadcastsStartingOn.indexOf(date);
  const active = broadcastIndex > -1;
  const incompleteWeek = active && broadcastsCompleteWeek[broadcastIndex] && broadcastsCompleteWeek[broadcastIndex] == "false"
  return {
    props: {
      className: cx({
        orange: active && orderState === "editing" && !incompleteWeek,
        orange: active && orderState === "editing" && incompleteWeek,
        green: active && orderState === "validated" && !incompleteWeek,
        lightGreen: active && orderState === "validated" && incompleteWeek
      })
    },
    children: (
      <span className={styles.cell}>
        {active &&
          price && <strong>{`${round(price, 0).toLocaleString()} €`}</strong>}
      </span>
    )
  };
};

const columns = props => {
  const {
    startingOn,
    endingOn,
    t,
    filters,
    handleWeekFilter,
    productFilters,
    weekFilter,
    viewType
  } = props;
  const mEndingOn = moment(endingOn);
  const mStartingOn = moment(startingOn);
  const columns = [
    {
      title: t("products.humanTitle"),
      key: "productIds",
      fixed: "left",
      width: 150,
      filteredValue: filters.productIds || null,
      filterMultiple: true,
      filters: productFilters,
      render: (text, { id, productTitle }) =>
        id === "footer" ? (
          {
            props: {
              colSpan: 2
            },
            children: (
              <span>
                <strong>{t("program.table.footer.total")}</strong>
                <br />
                <span>{t("program.table.footer.allPages")}</span>
              </span>
            )
          }
        ) : (
          <strong>{productTitle}</strong>
        )
    },
    {
      title: t("words.client"),
      key: "search",
      fixed: "left",
      width: 400,
      filteredValue: filters.search || null,
      filterDropdown: FilterDropdown,
      render: (text, { id, clientTitle, orderClientCommercialName, orderCode, orderId, orderTitle, comment }) =>
        id === "footer" ? (
          {
            props: {
              colSpan: 0
            }
          }
        ) : (
          <>
            <div style={{ lineHeight: '16px' }}>
                <strong>{[clientTitle, orderClientCommercialName].filter(Boolean).join(' - ')}</strong>
            </div>
            <small className={styles.orderTitle}>
              <a target="_blank" href={`/orders/${orderId}`}>{orderCode} {orderTitle}</a>
            </small>
              {comment && (
                <Tooltip title={comment}>
                <Typography.Paragraph ellipsis={{ rows: 1 }} className={styles.orderDescription}>
                    {comment}
                </Typography.Paragraph>
              </Tooltip>

            )}
          </>
        )
    },
  ];

  const currentDate = mStartingOn.startOf("week");
  let numberOfWeek = 0;

  while (currentDate.isSameOrBefore(mEndingOn)) {
    numberOfWeek += 1;
    const formattedDate = currentDate.format("YYYY-MM-DD");
    const active = weekFilter === formattedDate;
    const onPeriodFilterChange = date => () => handleWeekFilter(date, !active);
    columns.push({
      title: (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="toggle"
                onClick={onPeriodFilterChange(formattedDate)}
              >
                {t(
                  active
                    ? "program.disableFilterOnPeriod"
                    : "program.filterOnPeriod",
                  { week: currentDate.week() }
                )}
              </Menu.Item>
            </Menu>
          }
        >
          <span className={active ? styles.activeHeader : ""}>
            <strong>{`S${currentDate.week()}`}</strong>
            <br />
            {currentDate.format("DD/MM")}
            <br />
            <DownOutlined />
          </span>
        </Dropdown>
      ),
      align: "center",
      width: 122,
      render: Cell(formattedDate, viewType, t)
    });
    currentDate.add(7, "days");
  }

  const lastColumn = {
    ...last(columns),
    width: null
  };

  columns[columns.length - 1] = lastColumn;

  return {
    columns,
    numberOfWeek
  };
};

export default columns;
