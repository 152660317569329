import React from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";
import { withTranslation } from "react-i18next";

const colors = {
  editing: "orange",
  validated: "green"
};

const PaymentsState = ({ state, t }) => (
  <Tag color={colors[state]}>{t(`order.paymentsState.${state}`)}</Tag>
);

PaymentsState.propTypes = {
  state: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(PaymentsState);
