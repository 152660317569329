import reduce from "lodash/reduce";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

const footer = (props, t) => {
  const {
    products,
    startingOn,
    year,
    endingOn,
    years,
    broadcastsCount
  } = props;
  const mEndingOn = moment(endingOn);
  const mStartingOn = moment(startingOn);

  const total = broadcastsCount;
  let currentDate = mStartingOn.startOf("week");
  // set starting on at selected year if between starting/ending on
  if (years.length > 1 && years.indexOf(year) > 0) {
    currentDate = moment()
      .set("year", year)
      .startOf("year")
      .startOf("week");
    if (currentDate.year() < year) currentDate.add(7, "days");
  }

  const countByStartingOn = {};
  while (currentDate.isSameOrBefore(mEndingOn) && currentDate.year() === year) {
    const formattedDate = currentDate.format("YYYY-MM-DD");
    countByStartingOn[formattedDate] = reduce(
      products,
      (acc, { broadcasts }) => {
        if (
          broadcasts &&
          broadcasts.length > 0 &&
          broadcasts.find(b => b.startingOn === formattedDate)
        )
          return acc + 1;
        return acc;
      },
      0
    );
    currentDate.add(7, "days");
  }

  return {
    id: "footer",
    title: t("broadcastSelector.footer.title"),
    total,
    ...countByStartingOn
  }
};

export default footer;
