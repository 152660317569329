import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Card } from "antd";
import moment from 'moment';
import columns from "./columns";
import Report from "./Report";
import { sortBy } from 'lodash'
import styles from "../index.sass";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import getReportData from "./getReportData";
import getReportQuery from './reportQuery';

const params = {
  startingOn: moment()
    .startOf("month")
    .format("YYYY-MM-DD"),  endingOn: moment()
    .startOf("month")
    .add(3, "month")
    .endOf("month")
    .format("YYYY-MM-DD"),
  interval: "month",
  groupBy: "product",
  priceType: "margin",
  pagination: false
};


const ReportingWidget = () => {
  const [t] = useTranslation();
  const { data, loading } = useQuery(getReportQuery, { variables: params })
  const report = data ? getReportData(data.broadcastsReport) : {};
  
  return (
    <Card loading={loading} className={styles.card}>
      <Link className={styles.externalLink} to="/reporting/month">
        <FontAwesomeIcon icon={faExternalLinkAlt} />
      </Link>
      <h2>{t("dashboard.monthlyReport")}</h2>
      <Report {...report} data={sortBy(report.data || [], 'productType')} columns={columns(t, "month", "MMMM", { showProductType: true })} />
    </Card>
  );
};

export default ReportingWidget;
