import React, { useState } from 'react';
import { FileType } from '@/types';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFileAudio,
  faFileVideo,
  faFileWord,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Space, Image, Tooltip, Popconfirm, Spin } from 'antd';
import truncate from 'lodash/truncate';
import memoOnlyForKeys from '../../utils/memoOnlyForKeys';
import styles from './index.sass';

type IProps = {
  files: FileType[];
  className?: string;
  loading?: boolean;
  size?: 'default' | 'fullWidth';
  style?: React.CSSProperties;
  direction?: 'horizontal' | 'vertical';
  onRemove?: (id: string | number) => void;
};

type FilePreviewProps = {
  file: FileType;
  onRemove?: (id: string | number) => void;
};

const iconForType = (type: string) => {
  let icon = faFile;
  if (type.includes('sheet')) icon = faFileExcel;
  else if (type.includes('pdf')) icon = faFilePdf;
  else if (type.includes('video')) icon = faFileVideo;
  else if (type.includes('audio')) icon = faFileAudio;
  else if (type.includes('word')) icon = faFileWord;
  return <FontAwesomeIcon icon={icon} />;
};

const LoadingFilePreview = () => (
  <div className={styles.fileWrapper}>
    <div className={styles.thumbnail}>
      <div className={styles.loaderWrapper}>
        <Spin />
      </div>
    </div>
    <span className={styles.title}>&nbsp;</span>
  </div>
);

const FilePreview: React.FC<FilePreviewProps> = props => {
  const {
    file: { filename, contentType, id, url },
    onRemove,
  } = props;
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  let thumbnailContent = null;

  if (contentType.includes('image/')) {
    thumbnailContent = (
      <Image
        preview={{
          visible: previewVisible,
          onVisibleChange: visible => setPreviewVisible(visible),
        }}
        width="100%"
        height="100%"
        style={{
          height: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
        }}
        src={url}
      />
    );
  } else {
    thumbnailContent = (
      <span className={styles.icon}>{iconForType(contentType)}</span>
    );
  }

  return (
    <div className={styles.fileWrapper}>
      <div className={styles.thumbnail}>
        <div className={styles.actions}>
          <Space>
            <Tooltip title="Télécharger">
              <a onClick={() => window.open(url)}>
                <DownloadOutlined />
              </a>
            </Tooltip>
            {onRemove && (
              <a className="text-red">
                <Popconfirm
                  title="Ëtes-vous sûr ?"
                  okText="Oui"
                  cancelText="Non"
                  onConfirm={() => onRemove(id)}
                >
                  <Tooltip title="Supprimer">
                    <DeleteOutlined />
                  </Tooltip>
                </Popconfirm>
              </a>
            )}
          </Space>
        </div>
        {thumbnailContent}
      </div>
      <span className={styles.title}>{truncate(filename, { length: 15 })}</span>
    </div>
  );
};

const MemoizedFilePreview = React.memo(FilePreview, memoOnlyForKeys(['file']));

const FileList: React.FC<IProps> = props => {
  const {
    files,
    size = 'default',
    loading = false,
    className = '',
    direction = 'horizontal',
    style = {},
    onRemove,
  } = props;

  if (!files || files.length === 0) return null;
  return (
    <div style={style} className={`${className} ${styles.container}`}>
      <Space className={styles[`${size}-size`]} direction={direction} wrap>
        {files.map(file => (
          <MemoizedFilePreview key={file.id} file={file} onRemove={onRemove} />
        ))}
        {loading && <LoadingFilePreview />}
      </Space>
    </div>
  );
};

export default React.memo(FileList, memoOnlyForKeys(['files', 'loading']));
