import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, InputNumber, Select, Button } from "antd";
import moment from "moment";

import styles from "../index.scss";
import handleSubmit from "../../../utils/forms/handleSubmit";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const beginningDateOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i =>
  moment().add(i, "months")
);

const CreateMultiplePaymentsForm = props => {
  const { form, handleSubmit, submitting } = props;
  const [t] = useTranslation();
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={handleSubmit}>
      <Row gutter={16} type="flex">
        <Col>
          <Form.Item colon={false} label={t("payment.createMultiple.number")}>
            {getFieldDecorator("number", {
              rules: [{ required: true, message: t("errors.required") }]
            })(<InputNumber />)}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            colon={false}
            label={t("payment.createMultiple.beginningDate")}
          >
            {getFieldDecorator("beginningDate", {
              initialValue: moment().format("YYYY-MM-DD"),
              rules: [{ required: true, message: t("errors.required") }]
            })(
              <Select>
                {beginningDateOptions.map(option => (
                  <Option
                    value={option.format("YYYY-MM-DD")}
                    key={option.format("YYYY-MM-DD")}
                  >
                    {option.format("MMM YYYY")}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item className={styles.submit}>
            <Button type="primary" htmlType="submit" loading={submitting}>
              {t("payment.createMultiple.submit")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CreateMultiplePaymentsForm.defaultProps = {
  submitting: false
};

CreateMultiplePaymentsForm.propTypes = {
  t: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

export default Form.create()(
  compose(
    withHandlers({
      handleSubmit
    })
  )(CreateMultiplePaymentsForm)
);
