import { combineReducers } from "redux";
import { all } from 'redux-saga/effects';
import authReducer from "../components/authentication/authReducer";
import userReducer from "./user";
import clientsReducer from "./clients";
import globalReducer from "./global";
import uiReducer from "./ui";
import entitiesReducer from "./entities";
import productsReducer, { productSaga } from "./products";
import usersReducer from "./users";
import programsReducer from "./programs";
import reportingReducer from "./reporting";
import orderShowModel, { orderSaga } from './orderShow';
import { connectRouter } from 'connected-react-router'

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  user: userReducer,
  users: usersReducer,
  clients: clientsReducer,
  products: productsReducer,
  orderShow: orderShowModel,
  programs: programsReducer,
  reporting: reportingReducer,
  global: globalReducer,
  ui: uiReducer,
  entities: entitiesReducer,
});

export function* rootSaga() {
  yield all([
    orderSaga(),
    productSaga(),
  ]);
}

export default rootReducer;
