import React from "react";
import PropTypes from "prop-types";
import { Tag, Tooltip } from "antd";
import styles from "./index.scss";
import { useTranslation } from "react-i18next";

const colors = {
  editing: "orange",
  validated: "green"
};

const StateButton = props => {
  const { state, className, tooltip } = props;
  const [t] = useTranslation();
  const tag = (
    <Tag color={colors[state]} className={className} style={{ marginLeft: 15 }}>
      {t(`order.states.${state}.title`)}
    </Tag>
  );
  if (
    t(`order.states.${state}.tooltip`) === `order.states.${state}.tooltip` ||
    !tooltip
  )
    return tag;
  return (
    <Tooltip placement="top" title={t(`order.states.${state}.tooltip`)}>
      {tag}
    </Tooltip>
  );
};

StateButton.defaultProps = {
  state: "editing",
  className: styles.stateButton,
  tooltip: true
};

StateButton.propTypes = {
  state: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.bool
};

export default StateButton;
