import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { withTranslation } from "react-i18next";
import columns from "./columns";

export const ProgramTable = props => {
  const { loading, data, onChange, pagination } = props;
  const width = 550 + numberOfWeek * 122;
  const {
    columns: cls,
    numberOfWeek,
  } = columns({ ...props });
  return (
    <div>
      <Table
        bordered
        columns={cls}
        pagination={{
          ...pagination
        }}
        sticky={true}
        onChange={onChange}
        loading={loading}
        dataSource={data}
        rowKey={record =>
          record.id === "footer"
            ? "footer"
            : `${record.productId}-${record.orderId}`
        }
        scroll={{ x: width > 1600 ? width : 1600 }}
      />
    </div>
  );
};

ProgramTable.defaultProps = {
  loading: false,
  data: [],
  columns: [],
  numberOfWeek: 0
};

ProgramTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  numberOfWeek: PropTypes.number,
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired
};

export default withTranslation()(ProgramTable);