import gql from "graphql-tag";
import flashMessagesFragment from "./flashMessagesFragment";

export const broadcastInfo = gql`
  fragment BroadcastInfo on Broadcast {
    productId
    startingOn
    weekOccupancyPercent
    completeWeek
  }
`;

export const getOrderBroadcastsQuery = gql`
  query broadcasts($search: BroadcastsSearchAttributes) {
    broadcasts(search: $search) {
      ...BroadcastInfo
    }
  }
  ${broadcastInfo}
`;

export const createBroadcastQuery = gql`
  mutation createBroadcast($input: CreateBroadcastInput!) {
    createBroadcast(input: $input) {
      broadcast {
        id
        completeWeek
        weekOccupancyPercent
      }
      occupancyRate
      errors
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${flashMessagesFragment}
`;

export const deleteBroadcastQuery = gql`
  mutation deleteBroadcast($input: DeleteBroadcastInput!) {
    deleteBroadcast(input: $input) {
      occupancyRate
      deleted
      flashMessages {
        ...FlashMessageInfo
      }
    }
  }
  ${flashMessagesFragment}
`;

export const createMultipleBroadcasts = gql`
  mutation createMultipleBroadcasts($input: CreateMultipleBroadcastsInput!) {
    createMultipleBroadcasts(
      input: $input
    ) {
      broadcasts {
        productId
        completeWeek
        startingOn
        weekOccupancyPercent
      }
    }
  }
`;

export const getBroadcastsReport = gql`
  query broadcastsReport(
    $startingOn: String!
    $endingOn: String!
    $interval: String
    $groupBy: String
    $priceType: String
    $search: SearchProductsProgramsAttributes
    $page: Int
    $perPage: Int
  ) {
    broadcastsReport(
      startingOn: $startingOn
      endingOn: $endingOn
      interval: $interval
      groupBy: $groupBy
      priceType: $priceType
      search: $search
      perPage: $perPage
      page: $page
    ) {
      totalRecord
      total {
        price
        intervals {
          startingOn
          price
        }
      }
      data {
        total
        client {
          title
        }
        product {
          title
          breakEvenPoint
          goal
        }
        intervals {
          startingOn
          price
        }
      }
    }
  }
`;

export const deleteMultipleBroadcastsQuery = gql`
  mutation deleteMultipleBroadcasts($input: DeleteMultipleBroadcastsInput!) {
    deleteMultipleBroadcasts(input: $input) {
      broadcasts {
        productId
        startingOn
        completeWeek
      }
    }
  }
`;
