import queryString from "query-string";
import gql from "graphql-tag";
import omit from "lodash/omit";
import { requestMutation, requestQuery } from "../utils/graphql";
import flashMessagesFragment from "./flashMessagesFragment";
import castAttributesFromModel from "../utils/castAttributesFromModel";
import { paymentDefinition } from "./orders";


export const paymentFragment = gql`
  fragment PaymentInfo on Payment {
    id
    state
    amount
    dueOn
    receipt
    isLate
    paidOn
  }
`;

const createMultiplePaymentsQuery = gql`
  mutation createMultiplePayments(
    $input: CreateMultiplePaymentsInput!
  ) {
    createMultiplePayments(
      input: $input
    ) {
      order {
        id
        payments {
          ...PaymentInfo
        }
      }
    }
  }
  ${paymentFragment}
`;

const paymentReportingQuery = gql`
  query searchOrdersPayments(
    $startingOn: String!
    $endingOn: String!
    $perPage: Int
    $page: Int
    $search: SearchProductsProgramsAttributes
  ) {
    searchOrdersPayments(
      startingOn: $startingOn
      endingOn: $endingOn
      perPage: $perPage
      page: $page
      search: $search
    ) {
      currentPage
      perPage
      total
      ordersRow {
        order {
          code
          id
          clientCommercialName
        }
        client {
          id
          title
        }
        payments {
          ...PaymentInfo
          notes
        }
      }
    }
  }
  ${paymentFragment}
`;

const paymentsListReportingQuery = gql`
  query listPayments(
    $startingOn: String!
    $endingOn: String!
    $perPage: Int
    $page: Int
    $search: SearchProductsProgramsAttributes
  ) {
    listPayments(
      startingOn: $startingOn
      endingOn: $endingOn
      perPage: $perPage
      page: $page
      search: $search
    ) {
      currentPage
      perPage
      total
      payments {
        id
        receipt
        amount
        state
        dueOn
        paidOn
        isLate
        receiptCanBeModified
        order {
          id
          code
          clientCommercialName
          client {
            id
            title
          }
        }
      }
    }
  }
`;

const paymentQuery = gql`
  query payment($id: ID!) {
    payment(id: $id) {
      ...PaymentInfo
      receiptCanBeModified
      notes
      order {
        id
        code
        client {
          id
          title
        }
      }
    }
  }
  ${paymentFragment}
`;

const updatePaymentQuery = gql`
  mutation updatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      errors
      flashMessages {
        ...FlashMessageInfo
      }
      payment {
        ...PaymentInfo
        notes
      }
    }
  }
  ${paymentFragment}
  ${flashMessagesFragment}
`;

const createMultiple = async function(orderId, number, beginningDate) {
  const response = await requestMutation(createMultiplePaymentsQuery, {
    input: {
      orderId,
      beginningDate,
      number
    }
  })
  return response.createMultiplePayments;
}

export const PaymentService = {
  createMultiple,
};

class Payment {
  constructor(payment) {
    this.payment = payment;
    this.save = this.save.bind(this);
    this.update = this.update.bind(this);
  }

  save() {
    return this.update();
  }

  update() {
    const attributes = castAttributesFromModel(paymentDefinition, this.payment)
    return requestMutation(updatePaymentQuery, { 
      input: {
        id: this.payment.id,
        attributes
      }
    }).then(data => (data.updatePayment ? data.updatePayment : {}));
  }

  static find(id) {
    return requestQuery(paymentQuery, { id }).then(data =>
      data.payment ? data.payment : null
    );
  }

  static getReporting(params = {}) {
    return requestQuery(paymentReportingQuery, params).then(data =>
      data.searchOrdersPayments ? data.searchOrdersPayments : []
    );
  }

  static getListReportiong(params = {}) {
    return requestQuery(paymentsListReportingQuery, params).then(data =>
      data.listPayments ? data.listPayments : []
    );
  }

  static export(params) {
    const { startingOn, endingOn } = params;
    const stringifiedParams = queryString.stringify({
      starting_on: startingOn,
      ending_on: endingOn
    });
    window.open(
      `/payments/export.csv?${stringifiedParams}&token=182e927be0ba8dfb959fd86f92f478633dc8a7d357`
    );
  }
}

export default Payment;
