import React from "react";
import moment from "moment/moment";
import round from "lodash/round";
import isNil from "lodash/isNil";
import every from "lodash/every";
import last from "lodash/last";
import FilterDropdown from "../FilterDropdown";
import styles from "./index.sass";

const productExtraClass = (value, { breakEvenPoint, goal }) => {
  if (every([breakEvenPoint, goal, value], value => !isNil(value))) {
    if (value < breakEvenPoint) return styles.underBreak;
    if (value > goal) return styles.overGoal;
  }
  return "";
};
const Cell = (date, groupBy, view) => (text, record) => {
  const { id, intervals, product } = record;
  let extraClassName = "";
  let children = "";
  if (intervals && !isNil(intervals[date])) {
    children = `${round(intervals[date], 0).toLocaleString()} €`;
    if (view === "month" && groupBy === "product" && product)
      extraClassName = productExtraClass(round(intervals[date], 0), product);
  }
  if (id === "footer") {
    return <strong>{children}</strong>;
  }
  return <span className={extraClassName}>{children}</span>;
};

const columns = props => {
  const {
    view,
    groupBy,
    t,
    startingOn,
    endingOn,
    filters,
    productFilters
  } = props;
  const mEndingOn = moment(endingOn);
  const mStartingOn = moment(startingOn);

  let numberOfColumn = 0;

  const totalNumberOfColumn = mEndingOn.diff(mStartingOn, view) + 1;

  const columns = [
    {
      title: t(`words.${groupBy}s`),
      key: groupBy,
      ...(totalNumberOfColumn > 5 && {
        fixed: "left"
      }),
      filteredValue: filters[groupBy] || null,
      ...(groupBy === "client" && {
        filterDropdown: FilterDropdown
      }),
      ...(groupBy === "product" && {
        filterMultiple: true,
        filters: productFilters
      }),
      width: 200,
      render: (text, record) =>
        record.id === "footer" ? (
          <strong>{t("reporting.table.footer.total")}</strong>
        ) : (
          <strong>{record[groupBy]?.title || 'NA'}</strong>
        )
    },
    {
      title: t("total"),
      key: "total",
      ...(totalNumberOfColumn > 5 && {
        fixed: "left"
      }),
      width: 150,
      align: "center",
      render: (text, record) =>
        record.id === "footer" ? (
          <strong>
            {record.price && round(record.price, 0).toLocaleString()}
            &nbsp;€
          </strong>
        ) : (
          <strong>
            {record.total && round(record.total, 0).toLocaleString()}
            &nbsp;€
          </strong>
        )
    }
  ];

  const currentDate = mStartingOn.startOf(view);

  while (currentDate.isSameOrBefore(mEndingOn)) {
    numberOfColumn += 1;
    const formattedDate = currentDate.format("YYYY-MM-DD");
    columns.push({
      title:
        view === "week" ? (
          <span>
            <strong>{`S${currentDate.week()}`}</strong>
            <br />
            {currentDate.format("DD/MM")}
          </span>
        ) : (
          <span>{currentDate.format("MM/YYYY")}</span>
        ),
      width: 150,
      align: "right",
      render: Cell(formattedDate, groupBy, view)
    });
    currentDate.add(1, view);
  }

  if (view !== "month") {
    const lastColumn = {
      ...last(columns),
      width: null
    };
    columns[columns.length - 1] = lastColumn;
  }

  return {
    columns,
    numberOfColumn
  };
};

export default columns;
