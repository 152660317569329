import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import isNil from "lodash/isNil";
import { CheckOutlined, DeleteOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Form, InputNumber, Menu, Modal, Tooltip } from "antd";
import styles from "../OccupancyRates/legend.sass";
import cellColor from "../OccupancyRates/cellColor";
import yearsBetween from "./yearsBetween";
import InPlaceForm from "../InPlaceForm";
import nl2br from "../InPlaceForm/utils/nl2br";

const checkboxColumn = ({ date, onChange, locked }) => (text, record) => {
  const { id, occupancyByWeeks, broadcasts } = record;
  const broadcast = broadcasts?.find(b => b.startingOn === date);
  const checked = !isNil(broadcast)
  const isDooh = record.productType === "dooh";
  if (id === "footer") {
    return <strong>{record[date]}</strong>;
  }
  if (locked) {
    return {
      props: {
        className: checked ? `${broadcast.completeWeek ? styles.lockedCell : styles.halfLocked}` : ""
      },
      children: !broadcast || broadcast.weekOccupancyPercent === 0 ? null : `${broadcast.weekOccupancyPercent.toLocaleString()}%`
    };
  }
  const occupancyRate =
    occupancyByWeeks && !isNil(occupancyByWeeks[date])
      ? occupancyByWeeks[date].occupancyRate
      : 0;

  const onClick = () => {
    if ((isDooh && occupancyRate >= 117 && !checked) || (broadcast && !broadcast.completeWeek))
      return;
    let nextState = 100;
    if (checked && broadcast) {
      nextState = broadcast.completeWeek ? 50 : 0;
    }
    onChange(record.id, date, nextState)
  }

  const onPressEnter = (e) => {
    onChange(record.id, date, parseFloat(e.target.value))
  }

  const onFocus = (e) => {
    e.target.select();
  }

  return {
    props: {
      className: isDooh ? cellColor(occupancyRate) : null,
      onClick,
      key: `s${date}-${id}`
    },
    children: (
      checked && !broadcast.completeWeek ? (
        <InputNumber
          controls={false}
          style={{ width: 75 }}
          controls={false}
          formatter={value => `${value}%`}
          value={broadcast.weekOccupancyPercent}
          onPressEnter={onPressEnter}
          onBlur={onPressEnter}
          onFocus={onFocus}
        />
      ) : (
        <Checkbox disabled={isDooh && occupancyRate >= 117 && !checked} checked={checked} />
      )
    )
  };
};

const WeekHeader = ({
  t,
  currentWeek,
  date,
  locked,
  onSelectAll,
  onPartialSelectAll,
  onUnselectAll
}) => {
  const [partialModalIsOpen, setPartialModalIsOpen] = useState<boolean>(false);
  const [partialPercent, setPartialPercent] = useState<number>(50);
  const span = (
    <span>
      <strong>{`S${currentWeek}`}</strong>
      <br />
      {date}
      <br />
      {!locked && <DownOutlined />}
    </span>
  );
  if (locked) return span;
  
  const handlePartialSelect = () => {
    setPartialPercent(50);
    setPartialModalIsOpen(true);
  }

  const onValidatePartial = () => {
    setPartialModalIsOpen(false);
    setPartialPercent(50);
    onPartialSelectAll(partialPercent);
  }

  const onCancelPartial = () => {
    setPartialModalIsOpen(false);
    setPartialPercent(50);
  }

  return (
    <>
      {partialModalIsOpen && (
        <Modal
          visible
          title="Sélection partiel"
          okText="Valider"
          onOk={onValidatePartial}
          onCancel={onCancelPartial}
        >
          <div style={{ marginBottom: 5 }}>
            Pourcentage d'occupation
          </div>
          <InputNumber
            controls={false}
            onChange={setPartialPercent}
            value={partialPercent}
            formatter={value => `${value}%`}
          />
        </Modal>
      )}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="selectAll" onClick={onSelectAll}>
              <CheckOutlined />
              &nbsp;
              {t("selectAll")}
            </Menu.Item>
            <Menu.Item key="partialSelectAll" onClick={handlePartialSelect}>
              <CheckOutlined />
              &nbsp;
              {t("sentences.partialSelectAll")}
            </Menu.Item>
            <Menu.Item key="unselectAll" onClick={onUnselectAll}>
              <DeleteOutlined />
              &nbsp;
              {t("unSelectAll")}
            </Menu.Item>
          </Menu>
        }
      >
        {span}
      </Dropdown>
    </>
  );
};

const titleCell = ({t, mode, onProductSelectAll, onProductPartialSelectAll, onProductUnselectAll}) => (text, { id }) => {
  const [partialModalIsOpen, setPartialModalIsOpen] = useState<boolean>(false);
  const [partialPercent, setPartialPercent] = useState<number>(50);
  
  const handlePartialSelect = () => {
    setPartialPercent(50);
    setPartialModalIsOpen(true);
  }

  const onValidatePartial = () => {
    setPartialModalIsOpen(false);
    setPartialPercent(50);
    onProductPartialSelectAll(id, partialPercent);
  }

  const onCancelPartial = () => {
    setPartialModalIsOpen(false);
    setPartialPercent(50);
  }

  const menu = (
    <Menu>
      <Menu.Item key="selectAll" onClick={() => onProductSelectAll(id)}>
        <CheckOutlined />
        &nbsp;
        {t("selectAll")}
      </Menu.Item>
      <Menu.Item key="partialSelectAll" onClick={handlePartialSelect}>
        <span>-</span>
        &nbsp;
        {t("sentences.partialSelectAll")}
      </Menu.Item>
      <Menu.Item
        key="unselectAll"
        onClick={() => onProductUnselectAll(id)}
      >
        <DeleteOutlined />
        &nbsp;
        {t("unSelectAll")}
      </Menu.Item>
    </Menu>
  );
  return id === "footer" || mode === "show" ? (
    <span />
  ) : (
    <>
      {partialModalIsOpen && (
        <Modal
          visible
          title="Sélection partiel"
          okText="Valider"
          onOk={onValidatePartial}
          onCancel={onCancelPartial}
        >
          <div style={{ marginBottom: 5 }}>
            Pourcentage d'occupation
          </div>
          <InputNumber
            controls={false}
            onChange={setPartialPercent}
            value={partialPercent}
            formatter={value => `${value}%`}
          />
        </Modal>
      )}
      <Dropdown overlay={menu}>
        <DownOutlined />
      </Dropdown>
    </>
  );
}

WeekHeader.defaultProps = {
  currentWeek: 0,
  t: () => {},
  onSelectAll: () => {},
  onPartialSelectAll: () => {},
  onUnselectAll: () => {},
  locked: false
};

WeekHeader.propTypes = {
  currentWeek: PropTypes.number,
  t: PropTypes.func,
  onSelectAll: PropTypes.func,
  onUnselectAll: PropTypes.func,
  date: PropTypes.string.isRequired,
  locked: PropTypes.bool
};

const FooterTotal = ({ max, value, t }) => {
  let colorClass = "";
  if (value !== max) colorClass = styles.alertTotal;
  else if (value === max) colorClass = styles.successTotal;
  const span = <strong className={colorClass}>{value}</strong>;
  if (value > max) {
    return (
      <Tooltip title={t("broadcastSelector.exceedTotalBroadcasts")}>
        {span}
      </Tooltip>
    );
  }
  return span;
};


FooterTotal.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired
};

/**
 * Generate columns of broadcast selector
 * @param props
 * @returns {{columns: *[], numberOfWeek: number}} return table columns and number of weeks columns
 */
const columns = (props, t) => {
  const {
    startingOn,
    endingOn,
    year,
    onChange,
    onProductSelectAll,
    onProductPartialSelectAll,
    onProductUnselectAll,
    onDateUnselectAll,
    onDateSelectAll,
    onDatePartialSelectAll,
    onSubmit,
    mode,
    orderProductSubmitting: loading
  } = props;
  const mEndingOn = moment(endingOn);
  const mStartingOn = moment(startingOn);
  const years = yearsBetween(startingOn, endingOn);

  const totalNumberOfWeek = mEndingOn.diff(mStartingOn, "weeks");

  const columns = [
    {
      title: (
        <div style={{ width: 250 }} />
      ),
      key: "title",
      dataIndex: "title",
      ...(totalNumberOfWeek > 5 && {
        fixed: "left"
      }),
      width: 250,
      render: (_t, orderProduct) => {
        if (orderProduct.id === "footer")
          return null;
        else
          return (
            <span style={{ display: 'block', width: 250 }}>
              {`${t(`productTypes.${orderProduct.productType}`)} / ${orderProduct.title}`}
              {orderProduct.productType === "dooh" && (
                <>
                  &nbsp;
                  <strong>({orderProduct.broadcasts ? orderProduct.broadcasts.length : 0})</strong>
                </>
              )}
              {orderProduct.productType !== "dooh" && (!orderProduct.broadcasts || orderProduct.broadcasts.length === 0) && (
                <Tooltip title={t('order.productsWithoutBroadcast')}>
                  <ExclamationCircleOutlined style={{ marginLeft: 7, color: 'red' }} />
                </Tooltip>
              )}
            </span>
          )
      },
    },
    {
      title: "",
      key: "selectAll",
      ...(totalNumberOfWeek > 10 && {
        fixed: "left"
      }),
      align: "center",
      width: 50,
      render: titleCell({t, mode, onProductPartialSelectAll, onProductSelectAll, onProductUnselectAll})
    },
    {
      title: "Commentaire",
      key: "comment",
      dataIndex: "comment",
      width: 300,
      render: (_t, product) => {
        if (product.id === "footer")
          return null;
        else
          return (
            <InPlaceForm
              readOnly={mode === "show"}
              onChange={values => onSubmit({ ...values, productId: product.id })}
              loading={loading}
              object={{ comment: product.comment }}
              schema={[
                {
                  name: 'comment',
                  className: "text",
                  render: () => (<span style={{ fontSize: 14, lineHeight: "16px" }}>{product.comment ? nl2br(product.comment) : '-'}</span>),
                  input: {
                    type: 'text',
                  },
                  colProps: {
                    xs: 24,
                    md: 24
                  }
                }
              ]}
            />
          )
      }
    },
  ];

  let currentDate = mStartingOn.startOf("week");
  // set starting on at selected year if between starting/ending on
  if (years.length > 1 && years.indexOf(year) > 0) {
    currentDate = moment()
      .set("year", year)
      .startOf("year")
      .startOf("week");
    if (currentDate.year() < year) currentDate.add(7, "days");
  }

  let numberOfWeek = 0;

  // Now - 4 weeks - threshold for allow changes
  const minDate = moment()
    .startOf("week")
    .subtract(162, "weeks");

  // End is the tomorrow
  while (currentDate.isSameOrBefore(mEndingOn) && currentDate.year() === year) {
    const currentWeek = currentDate.week();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    const dateisLocked = mode === "show" || currentDate.isBefore(minDate);
    numberOfWeek += 1;
    columns.push({
      title: (
        <WeekHeader
          t={t}
          locked={dateisLocked}
          currentWeek={currentWeek}
          date={currentDate.format("DD/MM")}
          onSelectAll={() => onDateSelectAll(formattedDate)}
          onPartialSelectAll={(weekOccupancyPercent) => onDatePartialSelectAll(formattedDate, weekOccupancyPercent)}
          onUnselectAll={() => onDateUnselectAll(formattedDate)}
        />
      ),
      align: "center",
      render: checkboxColumn({
        date: currentDate.format("YYYY-MM-DD"),
        locked: dateisLocked,
        onChange
      })
    });
    currentDate.add(7, "days");
  }
  return {
    columns,
    numberOfWeek
  };
};

columns.defaultProps = {
  years: [],
  onProductSelectAll: () => {},
  totalBroadcasts: 0,
  mode: "show"
};

export default columns;
