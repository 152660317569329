import { UI_PAYMENTS_SET_STATE_FILTER } from "../../../uis/payments";
import fetchPaymentsReporting, { fetchListPaymentsReporting } from "./fetchPaymentsReporting";

const updateStateFilter = value => dispatch => {
  dispatch({
    type: UI_PAYMENTS_SET_STATE_FILTER,
    payload: value
  });
  return dispatch(fetchPaymentsReporting());
};

export const updateListStateFilter = value => dispatch => {
  dispatch({
    type: UI_PAYMENTS_SET_STATE_FILTER,
    payload: value
  });
  return dispatch(fetchListPaymentsReporting());
};

export default updateStateFilter;
