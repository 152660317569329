import {
  UI_PAYMENTS_SET_PAGINATION,
  UI_PAYMENTS_SET_FILTERS,
  UI_PAYMENTS_SET_SORT,
} from "../../../uis/payments";
import fetchPaymentsReporting, {fetchListPaymentsReporting} from "./fetchPaymentsReporting";

const updateTableAndFetch = (
  { current, total, pageSize },
  filters
) => dispatch => {
  dispatch({
    type: UI_PAYMENTS_SET_PAGINATION,
    payload: {
      pagination: {
        total,
        pageSize: pageSize,
        currentPage: current
      }
    }
  });
  dispatch({
    type: UI_PAYMENTS_SET_FILTERS,
    payload: filters
  });
  return dispatch(fetchPaymentsReporting());
};

export const updateTableAndFetchList = (
  { current, total, pageSize },
  filters, sorter
) => dispatch => {
  if (sorter)
    dispatch({
      type: UI_PAYMENTS_SET_SORT,
      payload: {
        sort: sorter.order ? {
          key: sorter.columnKey,
          order: sorter.order
        } : undefined
      }
    })
  dispatch({
    type: UI_PAYMENTS_SET_PAGINATION,
    payload: {
      pagination: {
        total,
        pageSize,
        currentPage: current
      }
    }
  });
  dispatch({
    type: UI_PAYMENTS_SET_FILTERS,
    payload: filters
  });
  return dispatch(fetchListPaymentsReporting());
};

export default updateTableAndFetch;
