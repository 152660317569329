import moment from "moment/moment";

const yearsBetween = (startingOn, endingOn) => {
  let i = 1;
  const mStartingOn = moment(startingOn).startOf("year");
  const mEndingOn = moment(endingOn)
    .startOf("week")
    .endOf("year");
  const numberOfYears = mEndingOn.diff(mStartingOn, "years");
  const years = [mStartingOn.year()];
  while (i <= numberOfYears) {
    const atNewYear = mStartingOn.add(1, "years");
    years.push(atNewYear.year());
    i += 1;
  }
  return years;
};

export default yearsBetween;
