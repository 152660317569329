import React from "react";
import PropTypes from "prop-types";
import { LogoutOutlined } from '@ant-design/icons';
import { Layout, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import styles from "./index.scss";
import { getMenuData } from "../../common/menu";
import SiderMenu from "../SiderMenu";

import GlobalHeader from "../GlobalHeader";
import { signOut } from "../authentication/actions/auth";
import { setUser } from "../../models/actions/user";

const { Header, Sider, Content } = Layout;

const UserDropdown = onClick => (
  <Menu className={styles.menu} selectedKeys={[]} onClick={onClick}>
    <Menu.Item key="logout">
      <LogoutOutlined />
      Déconnexion
    </Menu.Item>
  </Menu>
);

const BasicLayout = props => {
  const {
    onMenuClick,
    currentUser,
    collapsed,
    history,
    onCollapse,
    children
  } = props;
  return (
    <Layout>
      <Sider trigger={null} width={collapsed ? 80 : 300}>
        <SiderMenu
          menuData={getMenuData()}
          collapsed={collapsed}
          location={history.location}
          onCollapse={onCollapse}
        />
      </Sider>
      <Layout>
        <Header className={styles.header}>
          <GlobalHeader
            onMenuClick={onMenuClick}
            collapsed={collapsed}
            onCollapse={onCollapse}
            currentUser={currentUser}
          >
            <div className={styles.right}>
              {currentUser ? (
                <Dropdown overlay={UserDropdown(onMenuClick)}>
                  <span>{currentUser.email}</span>
                </Dropdown>
              ) : (
                <Link to="/user/login">Connexion</Link>
              )}
            </div>
          </GlobalHeader>
        </Header>
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

BasicLayout.defaultProps = {
  collapsed: false
};

BasicLayout.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  collapsed: PropTypes.bool,
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({
  collapsed: state.global.collapsed,
  currentUser: state.user
});

const mapDispatchToProps = dispatch => ({
  onCollapse: () => {
    dispatch({
      type: "global/toggleCollapsed"
    });
  },
  onMenuClick: ({ key }) => {
    if (key === "logout") {
      dispatch(signOut());
      dispatch(setUser({}));
    }
  }
});

const AppLayout = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BasicLayout)
);

export default AppLayout;
