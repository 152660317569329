import { faTrello } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { OrderType } from "../../../types";
import InPlaceForm from "../../InPlaceForm";
import { AntForm, AntSchema } from "@9troisquarts/ant-form.ant-form";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import FilesList from "../../FilesList";
import { Button } from "antd";

type BriefingProps = {
  order: OrderType;
  loading?: boolean;
  onAttachmentDelete: (id) => void;
  onCreateTrelloCardClick: () => void;
}

const BriefingForm: React.FC<BriefingProps> = props => {
  const [t] = useTranslation();
  const errors = {};
  const {
    order,
    loading = false,
    onSubmit,
    readOnly,
    onCreateTrelloCardClick,
    onAttachmentDelete
   } = props;

  const siteActivitiesList = [
    'Agence immobilière',
    'Ameublement',
    'Assurance',
    'Autres',
    'Autopromo',
    'Banque',
    'Bien être / esthétique',
    'Bricolage & jardinage',
    'Caviste',
    'Divers - news - infos locales',
    'Chocolaterie - Confiserie',
    'Concessionnaire',
    'Cuisiniste',
    'Divertissement',
    'Education',
    'Evénementiel & salon',
    'Garagiste',
    'Grande Surface Alimentaire & épicerie',
    'Literie',
    'Moto',
    'Plomberie - Chauffage',
    'Promotion Immobilière',
    'Opticien',
    'Restauration rapide','Loisirs',
    'Restauration traditionnelle',
    'Salle de sport',
    'Services à la personne',
    'Stores & menuiseries',
    'Téléphonie',
    'Vente d\'articles de sport',
  ]

  const attachments: AntSchema = [
    {
      name: 'attachments',
      style: {
        marginBottom: 0,
      },
      input: {
        type: 'upload',
        inputProps: {
          addComponent:
            <a className="underline">
              <FontAwesomeIcon icon={faFile} style={{ marginRight: 5 }} />
                    +&nbsp;
                    {t('samples.addAnAttachment' )}
            </a>
            || (
            <a>
              +&nbsp;
              Lien
            </a>
          ),
          showUploadList: false,
          listType: 'text',
          multiple: true,
        },
      },
    },
  ]

  return (
    <div>
      <InPlaceForm
        readOnly={readOnly}
        object={order}
        errors={errors || {}}
        onChange={onSubmit}
        loading={loading}
        schema={[
          <strong className="uppercase">Contact créa</strong>,
          [
            {
              label: t('order.contactCrea.lastname'),
              name: 'creaLastname',
              input: {
                type: 'string',
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
            {
              label: t('order.contactCrea.firstname'),
              name: 'creaFirstname',
              input: {
                type: 'string'
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
            {
              label: t('order.contactCrea.email'),
              name: 'creaEmail',
              input: {
                type: 'string'
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
            {
              label: t('order.contactCrea.phone'),
              name: 'creaPhone',
              input: {
                type: 'string'
              },
              colProps: {
                xs: 24,
                md: 6
              }
            }
          ],
          <strong className="uppercase">Brief</strong>,
          [
            {
              label: t('order.contactCrea.siteCount'),
              name: 'siteCount',
              input: {
                type: 'string'
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
            {
              label: t('order.contactCrea.siteCities'),
              name: 'siteCities',
              input: {
                type: 'string'
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
            {
              label: t('order.contactCrea.uniqueSalePlace'),
              name: 'uniqueSalePlace',
              input: {
                type: 'select',
                options: [{ value: 'Oui', label: 'Oui'}, { value: 'Non', label: 'Non'}]
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
            {
              label: t('order.contactCrea.siteActivities'),
              name: 'siteActivities',
              input: {
                type: 'select',
                options: siteActivitiesList.map((v: string) => ({
                  value: v,
                  label: v
                }))
              },
              colProps: {
                xs: 24,
                md: 6
              },
              selectProps: {
                listHeight: 6,
              }
            }
          ],
          [
            {
              label: t('order.contactCrea.description'),
              name: 'briefDescription',
              input: {
                type: 'text'
              },
              colProps: {
                xs: 24,
                md: 24
              }
            },
          ],
          [
            {
              label: `${t("order.trelloUrl.label")}`,
              name: 'trelloUrl',
              input: {
                type: 'string'
              },
              render: () => {
                if (order.trelloUrl) {
                  return (
                    <a href={order.trelloUrl} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faTrello} />
                          &nbsp;
                      {t("orderShow.showTrelloCard")}
                    </a>
                  )
                }
                return null;
              },
              colProps: {
                xs: 24,
                md: 6
              }
            },
          ],
        ]}
      />
      <strong className="uppercase">Pièces jointes</strong>
      <FilesList
        files={order.files}
        onRemove={onAttachmentDelete}
      />
      <AntForm
        schema={attachments}
        object={order}
        errors={errors || {}}
        onChange={onSubmit}
      />
      {!readOnly && (
        <div style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={onCreateTrelloCardClick}>
            Créer la carte trello
          </Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.orderShow.submitting
});

const mapDispatchToProps = dispatch => ({
  onCreateTrelloCardClick: () => dispatch({ type: 'orderShow/createTrelloCard' })
})

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BriefingForm));
