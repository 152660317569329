import React from "react";
import moment from "moment";
import round from "lodash/round";
import styles from "../index.sass";

const interationForInterval = {
  year: [-1, 0, 1],
  month: [0, 1, 2, 3]
};

const columns = (t, interval, headerFormat = "YYYY", { showProductType = false }) => [
  {
    title: "Catégorie produit",
    dataIndex: "title",
    key: "category",
    render: (text, record) => {
      if (record.id && record.id === "total") {
        return <strong>{t("total")}</strong>;
      }
      return <strong>
        {[record.productType ? t(`productTypes.${record.productType}`) : null, record.title].filter(Boolean).join(' / ')}
      </strong>;
    }
  },
  ...interationForInterval[interval].map(offset => {
    const d = moment()
      .add(offset, interval)
      .startOf(interval);
    return {
      title: d.format(headerFormat),
      onHeaderCell: () => ({
        className: `${offset === 0 ? styles.activeHeader : ""} ${
          styles.tableHeader
        }`
      }),
      key: `${offset}-${interval}`,
      align: "right",
      render: (text, record) => {
        if (record.intervals && record.intervals[d.format("YYYY-MM-DD")]) {
          const price = `${round(
            record.intervals[d.format("YYYY-MM-DD")] / 1000,
            1
          ).toLocaleString()} K€`;
          if (record.id && record.id === "total") {
            return <strong>{price}</strong>;
          }
          return price;
        }
        return "-";
      }
    };
  })
].filter(Boolean);

export default columns;
