import { replace, push } from "connected-react-router";
import { store } from "../../App";
import { resetAuth } from "../../components/authentication/actions/auth";

/**
 * It redirect to the correct exception page when status is not 200
 * Or signout when status is 401
 * @param e
 */
const errorHandler = (status) => {
  const { dispatch } = store;
  if (status) {
    if (status === 401) {
      dispatch(resetAuth());
      return;
    }
    if (status === 403) {
      dispatch(push("/403"));
      return;
    }
    if (status <= 504 && status >= 500) {
      dispatch(push("/500"));
      return;
    }
    if (status >= 404 && status < 422) {
      dispatch(replace("/404"));
    }
  }
};

export default errorHandler;
