import React from "react";
import { Row, Col } from "antd";
import ReportingWidget from "../components/DashboardWidget/Reports/MonthlyReport";
import YearlyReport from "../components/DashboardWidget/Reports/YearlyReport";
import ProgramWidget from "../components/DashboardWidget/OccupancyRatesWidget";
import PaymentsStats from "../components/DashboardWidget/PaymentsStats";

const Dashboard = () => (
  <Row gutter={[16, 16]}>
    <Col xs={24} md={15}>
      <ReportingWidget />
    </Col>
    <Col xs={24} md={9}>
      <YearlyReport />
    </Col>
    <Col xs={24} md={14}>
      <ProgramWidget />
    </Col>
    <Col xs={24} md={10}>
      <PaymentsStats />
    </Col>
  </Row>
);

export default Dashboard;
