import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, Divider, DatePicker, Typography, Space } from "antd";
import moment from "moment/moment";
import { createSelector } from "reselect";
import styles from "./index.sass";
import CategorySelector from "../components/CategorySelector";
import { orm as Product } from "../models/products";
import withProducts from "../utils/providers/withProducts";
import {
  init,
  reset,
  updateDatesRangeAndFetch,
  updateCategoriesAndFetch,
  updateTableAndFetch,
  updateWeekFilterAndFetch
} from "../models/actions/ui/program";
import ProgramTable from "../components/ProgramTable";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

export const Planning = props => {
  const {
    onCategoryChange,
    onDateChange,
    onTableChange,
    categories,
    ui,
    data,
    pagination,
    productFilters,
    handleWeekFilter
  } = props;

  const [t] = useTranslation()
  
  useEffect(() => {
    props.loadData();
    return () => props.reset();
  }, []);

  const { startingOn, endingOn, loading, filters, weekFilter, viewType } = ui;
  return (
    <Card>
      <Typography.Title level={3}>{t("program.title")}</Typography.Title>
      <Divider style={{ marginTop: 0 }} />
      <div className={styles.filtersContainer}>
        <div>
          <CategorySelector
            title={t("categorySelector.label")}
            options={categories}
            values={ui.categories}
            onChange={onCategoryChange}
          />
        </div>
        <Space className={styles.rangePicker}>
          <span>{t("filterByDates")}</span>
          <RangePicker
            onChange={onDateChange}
            value={[moment(startingOn), moment(endingOn)]}
            format="DD/MM/YYYY"
          />
        </Space>
      </div>
      <ProgramTable
        data={data}
        startingOn={startingOn}
        endingOn={endingOn}
        loading={loading}
        filters={filters}
        pagination={pagination}
        viewType={viewType}
        onChange={onTableChange}
        productFilters={productFilters}
        weekFilter={weekFilter}
        handleWeekFilter={handleWeekFilter}
      />
    </Card>
  );
};

Planning.defaultProps = {
  categories: [],
  ui: {
    categories: [],
    startingOn: null,
    endingOn: null
  },
  data: []
};

Planning.propTypes = {
  t: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  handleWeekFilter: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  productFilters: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  ui: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.string),
    startingOn: PropTypes.string.isRequired,
    endingOn: PropTypes.string.isRequired,
    filters: PropTypes.shape({})
  }),
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired
};

const propsSelector = createSelector(
  state => state.ui.program,
  state => state.programs,
  state => Product.all(state),
  state => Product.categories(state),
  // statsSelector,
  (ux, programs, products, categories) => {
    const ui = ux.toJS();
    const { currentPage, pageSize, total, ...otherUi } = ui;
    const categoryChoosed = otherUi.categories;
    return {
      data: programs.get("list").toJS(),
      productFilters: products
        .filter(
          product =>
            categoryChoosed.length === 0 || categoryChoosed.includes(product.category)
        )
        .map(s => ({ value: s.id, text: s.title })),
      ui: otherUi,
      categories,
      pagination: {
        current: currentPage,
        total,
        pageSize
      }
    };
  }
);

const mapStateToProps = state => propsSelector(state)

const mapDispatchToProps = (dispatch) => ({
  loadData: () => dispatch(init()),
  reset: () => dispatch(reset()),
  onCategoryChange: categories => dispatch(updateCategoriesAndFetch(categories)),
  onTableChange: (pagination, filters) =>
    dispatch(updateTableAndFetch(pagination, filters)),
  handleWeekFilter: value => dispatch(updateWeekFilterAndFetch(value)),
  onDateChange: momentDates => dispatch(updateDatesRangeAndFetch(momentDates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withProducts(Planning));
