import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { orm as Product } from "../../models/products";
import { fetchProductsIfNeeded } from "../../models/actions/products";

const withProducts = WrappedComponent => {
  class ComponentWithProducts extends Component {
    componentWillMount() {
      const { handleInit } = this.props;
      handleInit();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    products: Product.all(state)
  });

  const mapDispatchToProps = dispatch => ({
    handleInit: () => dispatch(fetchProductsIfNeeded())
  });

  ComponentWithProducts.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleInit: PropTypes.func.isRequired
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithProducts);
};

export default withProducts;
