import React from 'react';
import { withTranslation } from "react-i18next";

type WarningType = {
	total?: number;
	count?: number;
	message: string;
	productType: string;
};

type WarningsModalProps = {
	warnings: WarningType[];
};

const WarningMessages: React.FC<WarningsModalProps> = (props) => {
	return props.warnings.map(({ message, count, total, productType }) => (
		<p style={{ marginBottom: 5 }}>
			{props.t(`orderShow.warningMessages.${message}`, { count, total, productType: props.t(`productTypes.${productType}`) })}
		</p>
	))
}

export default WarningMessages;