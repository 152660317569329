/* eslint react/jsx-indent: 0 */

import React from "react";
import { isNil, last, round } from "lodash";
import moment from "moment";
import cellColor from "./cellColor";
import styles from "./legend.sass";

const checkboxColumn = ({ date }) => (text, record) => {
  const { id, occupancyByWeeks, sides } = record;
  if (id === "footer")
    return (
      <strong>
        {`${
          record.occupancyByWeeks && record.occupancyByWeeks[date]
            ? record.occupancyByWeeks[date]
            : 0
        }%`}
      </strong>
    );
  const occupancyRate =
    occupancyByWeeks && !isNil(occupancyByWeeks[date])
      ? occupancyByWeeks[date]
      : {
        occupancyRate: 0,
        broadcastCount: 0
      };
  return {
    props: {
      className: cellColor(occupancyRate.occupancyRate),
      key: `s${date}-${id}`
    },
    children: <span>{round(occupancyRate.broadcastCount || 0, 1).toLocaleString()}&nbsp;/&nbsp;{sides}</span>
  };
};

const columns = (props, t) => {
  const { startingOn, productFilters, endingOn, filters, showTotal } = props;
  const mEndingOn = moment(endingOn);
  const mStartingOn = moment(startingOn);
  const columns = [
    {
      title: t("products.humanTitle"),
      key: "title",
      fixed: "left",
      width: 150,
      filteredValue: filters.title || null,
      filterMultiple: true,
      filters: productFilters,
      render: (text, { id, title }) => (
        <strong>{id === "footer" ? t("total") : title}</strong>
      )
    }
  ];

  if (showTotal)
    columns.push({
      title: (
        <span>
          {`${mStartingOn.startOf("week").format("L")} - ${mEndingOn.format(
            "L"
          )}`}
        </span>
      ),
      key: "total",
      fixed: "left",
      width: 150,
      align: "center",
      render: (text, { id, total }) => (
        <span
          className={`${id === "footer" ? "" : cellColor(total)} ${
            styles.totalNumber
          }`}
        >
          {`${total}%`}
        </span>
      )
    });

  const currentDate = mStartingOn.startOf("week");
  let numberOfWeek = 0;

  while (currentDate.isSameOrBefore(mEndingOn)) {
    const currentWeek = currentDate.week();

    numberOfWeek += 1;
    columns.push({
      title: (
        <span>
          <strong>{`S${currentWeek}`}</strong>
          <br />
          {currentDate.format("DD/MM")}
          <br />
        </span>
      ),
      width: 125,
      align: "center",
      render: checkboxColumn({ date: currentDate.format("YYYY-MM-DD") })
    });
    currentDate.add(7, "days");
  }

  const lastColumn = {
    ...last(columns),
    width: null
  };

  columns[columns.length - 1] = lastColumn;

  return {
    columns,
    numberOfWeek
  };
};

export default columns;
