import { useEffect, useState } from 'react';

const useEditable = ({ editing, loading, onEditChange, readOnly: propsReadOnly }) => {
  const [internalEditing, setEditing] = useState<string | undefined>(undefined);
  const [readOnly, setReadOnly] = useState<boolean>(propsReadOnly || false);

  useEffect(() => {
    if (!loading && internalEditing) setEditing(undefined);
  }, [loading]);

  useEffect(() => {
    setEditing(editing);
  }, [editing]);

  const handleEditChange = ({ name, value }) => {
    if (readOnly) return;
    if (onEditChange) onEditChange({ name, value });
    else setEditing(value ? name : undefined);
  };

  return {
    handleEditChange,
    internalEditing,
    setEditing,
    readOnly,
    setReadOnly,
  }
}

export default useEditable;