import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../../routes/Login";

const AuthenticationRoutes = () => (
  <Switch>
    <Route exact path="/user/login" component={Login} />
  </Switch>
);

export default AuthenticationRoutes;
