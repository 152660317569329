import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Card } from "antd";
import { orm } from "../../models/orms/ordersOrm";
import Order from "../../components/Order";
import createDeepEqualSelector from "../../utils/createDeepEqualSelector";

const validationWarnings = (selectedProductTypes, broadcastsByProductType, totalBroadcasts) => {
  let warnings = [];
  selectedProductTypes.forEach((productType) => {
    if (!broadcastsByProductType[productType]) warnings.push({ productType, message: 'no_broadcasts' });
    if (productType === 'dooh' && broadcastsByProductType[productType]) {
      if (totalBroadcasts !== broadcastsByProductType.dooh) return warnings.push({ productType, message: 'incoherent', count: broadcastsByProductType.dooh || 0, total: totalBroadcasts });
    }
  })
  return warnings;
}

export const OrderShow = props => {
  const { loading, order, handler, editionEnabled, broadcastsByProductType } = props;
  const selectedProductTypes = (order?.turnovers || []).filter(t => t.turnover && t.turnover > 0).map(t => t.productType);
  useEffect(() => {
    props.onInit();
  }, []);
  return (
    <Card loading={loading}>
      {!loading && order && (
        <Order
          loading={loading}
          editionEnabled={editionEnabled}
          warnings={validationWarnings(selectedProductTypes, broadcastsByProductType, order?.totalBroadcasts)}
          order={order}
          handler={handler}
        />
      )}
    </Card>
  );
};

OrderShow.defaultProps = {
  loading: false,
  order: null,
  ui: {}
};

OrderShow.propTypes = {
  loading: PropTypes.bool,
  order: PropTypes.shape({}),
  ui: PropTypes.shape({}),
  handler: PropTypes.shape({
    onEdit: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = createDeepEqualSelector(
  state => state.orderShow.current,
  orm.productsWithBroadcasts,
  (state) => state.orderShow.loading,
  (state) => state.orderShow,
  (order, productsWithBroadcasts, loading, orderShow) => {
    return ({
      loading,
      editionEnabled: order && (order.state !== "validated" || orderShow.editing),
      order: order,
      broadcastsByProductType: productsWithBroadcasts?.byProductType
    })
  }
)

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { id }
    }
  }
) => ({
  onInit: () => dispatch({ type: 'orderShow/fetchOrder', payload: id }),
  handler: {
    onAttachmentDelete: (id) => dispatch({ type: 'orderShow/deleteAttachment', payload: id }),
    onToggleEditingMode: (payload) => dispatch({ type: 'orderShow/setEditing', payload }),
    onDelete: () => dispatch({ type: 'orderShow/deleteOrder', payload: id }),
    onSyncOrder: () => dispatch({ type: 'orderShow/sync' }),
    onSave: (order) => dispatch({ type: 'orderShow/save', payload: order }),
    onChangeState: state => dispatch({ type: 'orderShow/updateState', payload: state }),
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderShow);
