import moment from "moment/moment";
import { UI_PAYMENTS_SET_DATES } from "../../../uis/payments";
import fetchPaymentsReporting, {fetchListPaymentsReporting} from "./fetchPaymentsReporting";

const changeDates = (mStartingOn, mEndingOn, dispatch) => {
  const startingOn = mStartingOn.format("YYYY-MM-DD");
  const endingOn = mEndingOn.format("YYYY-MM-DD");
  dispatch({
    type: UI_PAYMENTS_SET_DATES,
    payload: {
      startingOn,
      endingOn
    }
  });
}

const changeDatesAndFetch = momentDates => (dispatch, getState) => {
  const state = getState().ui.payments.toJS();

  const previousStartingOn = moment(state.startingOn);
  const previousEndingOn = moment(state.endingOn);

  const [mStartingOn, mEndingOn] = momentDates;
  changeDates(mStartingOn,mEndingOn,dispatch);
  if (
    previousEndingOn.isBefore(mEndingOn) ||
    previousStartingOn.isAfter(mStartingOn)
  )
    dispatch(fetchPaymentsReporting());
};

export const changeDatesAndFetchPayments = momentDates => (dispatch, getState) => {
  const state = getState().ui.payments.toJS();

  const previousStartingOn = moment(state.startingOn);
  const previousEndingOn = moment(state.endingOn);

  const [mStartingOn, mEndingOn] = momentDates;
  changeDates(mStartingOn,mEndingOn,dispatch);
  if (
    previousEndingOn.isBefore(mEndingOn) ||
    previousStartingOn.isAfter(mStartingOn)
  )
    dispatch(fetchListPaymentsReporting());
};

export default changeDatesAndFetch;
