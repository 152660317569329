import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { orm as Client } from "../../models/clients";
import { fetchIfNeeded } from "../../models/actions/clients";

const withClients = WrappedComponent => {
  class ComponentWithClients extends Component {
    componentWillMount() {
      const { handleInit } = this.props;
      handleInit();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    clients: Client.all(state)
  });

  const mapDispatchToProps = dispatch => ({
    handleInit: () => dispatch(fetchIfNeeded())
  });

  ComponentWithClients.propTypes = {
    clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    handleInit: PropTypes.func.isRequired
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithClients);
};

export default withClients;
