import isEmpty from "lodash/isEmpty";
import getAuthFromLS from "../../utils/getAuthFromLS";
import { validateCredentials } from "./actions/auth";
import { getFromLocation } from "./credentialsUtils";

const credentialsAreInLocation = () => !isEmpty(getFromLocation());

const verifyCredentials = store => {
  const { dispatch } = store;
  let credentials = {};
  if (credentialsAreInLocation()) {
    credentials = getFromLocation();
  } else if (!isEmpty(getAuthFromLS())) {
    credentials = getAuthFromLS();
  }
  if (!isEmpty(credentials)) dispatch(validateCredentials(credentials));
};

export default verifyCredentials;
