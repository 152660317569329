/**
 * It check if errors is present and transform her in the appropriate props for <Form.Item>
 * @param errors - Array of string
 * @returns {{}} - Props help and validateStatus
 */
const fieldErrorsToItemProps = errors => {
  const props = {};
  if (errors && errors.length > 0) {
    props.validateStatus = "error";
    const [help] = errors;
    props.help = help;
  }
  return props;
};

export default fieldErrorsToItemProps;
