import React from "react";
import PropTypes from "prop-types";
import { compose, mapProps } from "recompose";
import { Table } from "antd";
import columns from "./columns";
import { withTranslation } from "react-i18next";

export const PaymentsTable = props => {
  const {
    loading,
    data,
    columns,
    numberOfColumn,
    onChange,
    pagination
  } = props;
  const width = 400 + numberOfColumn * 150;
  return (
    <div>
      <Table
        bordered
        sticky
        columns={columns}
        pagination={pagination}
        onChange={onChange}
        loading={loading}
        dataSource={[
          ...Array.from({ length: (pagination.current - 1) * 30 }),
          ...data
        ]}
        rowKey={record =>
          record.id === "footer"
            ? "footer"
            : `${record.client.id}-${record.order.id}`
        }
        scroll={{ x: width > 1000 ? width : 1000 }}
      />
    </div>
  );
};

PaymentsTable.defaultProps = {
  onChange: () => undefined,
  data: [],
  columns: [],
  loading: false,
  numberOfColumn: 0
};

PaymentsTable.propTypes = {
  onChange: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  numberOfColumn: PropTypes.number,
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    total: PropTypes.number
  }).isRequired,
  loading: PropTypes.bool
};

const tableConfiguration = props => ({
  ...columns({ ...props })
});

export default compose(
  withTranslation(),
  mapProps(props => ({
    ...props,
    ...tableConfiguration(props)
  }))
)(PaymentsTable);
