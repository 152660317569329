import React from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";

import styles from "./index.sass";

const YearSelector = ({ options, value, onChange }) => (
  <span className={styles.yearSelectorContainer}>
    {options.map(o => (
      <div
        key={`year-${o}`}
        className={`${styles.yearSelectorButton} ${
          value === o ? styles.yearActive : ""
        }`}
        onClick={() => onChange(o)}
      >
        {o}
      </div>
    ))}
  </span>
);

YearSelector.defaultProps = {
  value: moment().year(),
  options: []
};

YearSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default YearSelector;
