import { normalize } from "normalizr";
import { UI_PAYMENTS_SET_CURRENT } from "../../../uis/payments";
import Payment from "../../../../services/payments";
import Schemas from "../../../../schema";
import { SET_ENTITIES } from "../../../constants/entities";

/**
 * Set current payment and loading state
 * @param {number} id - id of the payment to update
 * @param {boolean} loading - state of payment loading
 */
export const setCurrentPayment = (id, loading = true) => ({
  type: UI_PAYMENTS_SET_CURRENT,
  payload: {
    id,
    loading
  }
});

/**
 * Unset current payment
 */
export const unsetCurrentPayment = () => ({
  type: UI_PAYMENTS_SET_CURRENT,
  payload: {}
});

export const setAndFetchCurrent = id => dispatch => {
  dispatch(setCurrentPayment(id));
  Payment.find(id).then(payment => {
    if (payment) {
      const { entities, result } = normalize(payment, Schemas.PAYMENT);
      dispatch({
        type: SET_ENTITIES,
        entities
      });
      dispatch(setCurrentPayment(result, false));
    }
  });
};

export default {
  setCurrentPayment,
  unsetCurrentPayment,
  setAndFetchCurrent
};
