import React from 'react';
import _ from 'lodash';
import { Input, Spin, Form, DatePicker } from 'antd';
import moment, { isMoment } from 'moment';
import { indicator } from './index';
import styles from './index.sass';

const FormItem = Form.Item;

const { TextArea } = Input;

const inputWidget = {
  string: Input,
  float: Input,
  integer: Input,
  text: TextArea,
  date: DatePicker,
};

type IProps = {
  size?: 'small' | 'large' | 'default';
  inline?: boolean;
  layout?: 'horizontal' | 'inline' | 'vertical';
  editable?: boolean;
  loading?: boolean;
  type?: string;
  rules?: Array<any>;
  value: string | number;
  error?: any;
  addonAfter?: any;
  input: React.ReactNode;
  options: Array<any>;
  onCancel?: (values: any) => void;
  onSubmit: (values: any) => void;
};

const extraPropsForType = (props, onSubmit) => ({
  text: {
    autoSize: {
      minRows: 2,
    },
  },
  string: {
    addonAfter: props.addonAfter,
  },
  integer: {
    addonAfter: props.addonAfter,
  },
  float: {
    addonAfter: props.addonAfter,
  },
  date: {
    format: 'L',
  },
});

const transformValue = (type, value) => {
  if (_.isNil(value)) return null;
  switch (type) {
    case 'integer':
      return parseInt(value, 10);
    case 'float':
      return parseFloat(value.toString().replace(/\,/g, '.'));
    case 'date':
      return isMoment(value) ? value.format('YYYY-MM-DD') : value;
    default:
      return value;
  }
};

const FormInput: React.FC<IProps> = props => {
  const {
    type = 'string',
    size = 'small',
    input = null,
    rules = [],
    onCancel = () => undefined,
    value,
    onSubmit,
    layout = 'vertical',
    inline = false,
    editable = true,
    error,
    loading = false,
  } = props;
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then(({ input }) => {
      onSubmit({ input: transformValue(type, input) });
    });
  }
  const handleBlur = handleSubmit;
  const handleKeyDown = e => {
    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      onCancel();
    }
    if (e.keyCode === 13 && (e.shiftKey || type !== 'text')) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const Component = inputWidget[type] || Input;
  const sharedProps = {
    size,
    onKeyDown: handleKeyDown,
    onBlur: handleBlur,
    hover: editable,
  };

  const extraProps = extraPropsForType(props, onSubmit)[type] || {};
  let item = null;

  let v;
  if (value) {
    if (_.isString(value)) {
      v = _.trim(value);
    }
    if (type === 'date') {
      v = isMoment(value) ? value : moment(value);
    }
    if (!v || _.isArray(value)) v = value;
  }

  item = (
    <FormItem
      name="input"
      help={error && error.length ? error[0] : null}
      validateStatus={error && error.length ? 'error' : ''}
      rules={rules}
      initialValue={v}
      className={styles.formItem}
    >
      {input || <Component autoFocus {...extraProps} {...sharedProps} />}
    </FormItem>
  );

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout={layout}
      className={inline ? styles.form : ''}
    >
      {item}
      {loading && (
        <div
          className={`${styles.loaderContainer} ${
            type === 'text' ? styles.absolute : ''
          }`}
        >
          <Spin className={styles.loader} indicator={indicator} />
        </div>
      )}
    </Form>
  );
};

export default FormInput;
