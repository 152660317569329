import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import Cache from "i18next-localstorage-cache";

i18n
  .use(XHR)
  .use(Cache)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}.json?version=3.2"
    },
    interpolation: {
      escapeValue: false // React already does escaping
    },
    wait: true,
    lng: "fr", // 'en' | 'es'
    fallbackLng: "fr"
  });

export default i18n;
