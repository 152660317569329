import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment, { isMoment } from 'moment';
import 'moment/locale/fr';
moment.locale('fr')

const InlineDatePicker = props => {
  const {
    value,
    readOnly,
    ...other
  } = props;
  const v = isMoment(value) || !value ? value : moment(value);
  const onChange = (date) => props.onChange({ [props.name]: date });
  
  if(readOnly) return v.format('L')

  return (
    <DatePicker
      {...other}
      name="createdOn"
      suffixIcon={false}
      locale="fr"
      value={v}
      format="L"
      clearIcon={false}
      onChange={onChange}
    />
  )
}

export default InlineDatePicker;