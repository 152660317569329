import produce from 'immer';

/**
 * It create a smart Reducer
 * @param initialState
 * @param handlers {ACTION_TYPE: Function(state, action)}
 * @returns {reducer} - return the state after change
 */
const createReducer = (initialState, handlers) =>
  function reducer(state = initialState, action) {
    if (handlers[action.type]) {
      return handlers[action.type](state, action);
    }
    return state;
  };

export const createImmerReducer = (initialState, handlers) => function reducer(state = initialState, action, options = {}) {
  return produce(state, draft => {
    const {
      reset = false,
    } = options;
    if (reset && action.type === RESET_STATE) draft = initialState;
    if (handlers[action.type]) draft = handlers[action.type](draft, action);
    return draft;
  });
};

export function combineReducersWithRoot(rootReducer, reducers) {
  return (state, action) => {
    // Ensure the root state object is a new object; otherwise
    // React may not re-render.
    const newState = { ...rootReducer(state, action) };
    Object.keys(reducers).forEach(domain => {
      const obj = state ? state[domain] : undefined;
      newState[domain] = reducers[domain](obj, action);
    });
    return newState;
  };
}

export default createReducer;
