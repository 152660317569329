import gql from "graphql-tag";
import { requestMutation } from "../utils/graphql";

const DELETE_MUTATION = gql`
  mutation deleteAttachment($input: DeleteAttachmentMutationInput!) {
    deleteAttachment(input: $input) {
      persisted
    }
  }
`

const destroyAttachment = async function(id) {
  const response = await requestMutation(DELETE_MUTATION, { input: { attachmentId: id }});
  return response.deleteAttachment;
}

const AttachmentService = {
  destroy: destroyAttachment
}

export default AttachmentService;
